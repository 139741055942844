import 'crypto-browserify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { disableInspect } from './utils/helper';

// This function initializes gtag.js and registers it with the global window object
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'G-L5ZGDV23WE'); // Replace with your GA4 Measurement ID
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
      {/* disable inspect feature  */}
      {/* {disableInspect()} */}
      <App />
    </Router>,
  // </React.StrictMode>,

);

reportWebVitals();