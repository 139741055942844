import { getCDSPixelId, getIpDetails, getUserDetails } from "./helper";

// export const cdsPixelID = "af3f2768-d522-4e3c-870f-8a334befa671"; // dev id
export const cdsPixelID = "eaf889b7-220b-4c9d-8db0-6b58e338ab99"; // incoreect prod id

// export const cdsPixelID = 'af3f2768-d522-4e3c-870f-8a334befa671';

// export const GetUserIdentifier = async () => {
//     const HfSession = await getCDSPixelId();
//     const CdpCookie = [
//         {
//             "user_identifier_type": "COKE_COOKIE",
//             "user_identifier_sub_type": "CDS_PIXEL_COOKIE",
//             "user_identifier": `${HfSession}`,
//         }
//     ];

//     return CdpCookie;
// }

export const getGeoData = () => {
    const ipDetails = getIpDetails();
    let ipAddress = '', stateCode = '', city = '', zip = '';
    if(ipDetails !== null){
        try {
            // ip addresss
            if (ipDetails.hasOwnProperty('query')) {
              ipAddress = ipDetails['query'];
              // Your code that uses 'ipAddress' goes here
              console.log('ipAddress',ipAddress);
            } else {
              console.error("'query' property not found in 'ipDetails'");
            }
    
            // stateCode
            if(ipDetails.hasOwnProperty('region')){
                stateCode = ipDetails['region'];
            }
            else{
                console.error("'region' property not found in 'ipDetails'");
            }

            // city
            if(ipDetails.hasOwnProperty('city')){
                city = ipDetails['city'];
            }
            else{
                console.error("'city' property not found in 'ipDetails'");
            }

            // zip
            if(ipDetails.hasOwnProperty('zip')){
                zip = ipDetails['zip'];
            }
            else{
                console.error("'zip' property not found in 'ipDetails'");
            }
    
        } catch (error) {
            console.error("Error parsing 'ipDetails' JSON:", error);
        }

    } else {
        console.error("'ipDetails' key not found in localStorage");
    }

    const data = {
        ip : ipAddress,
        stateCode :  stateCode,
        city : city,
        Pincode : zip
    }
    return data;
}

export const BuyNowCDP = async (eventSubType,partnerName) => {
    // const CdpCookie = await GetUserIdentifier();
    const data = {
        'event_type' : 'Click',
        'event_sub_type' : `${eventSubType}`,
        'partner_name' : `${partnerName}`,
        // 'user_identifiers' : CdpCookie,
        'brand_name' : 'Thums Up'
    };
    console.log('cdp buynow',cdsPixelID,data);
    window.cds_pixel(cdsPixelID,data);
}

// export const SubmitUniqueCodeCDP = (dataCaptured) => {
//     const CdpCookie = GetUserIdentifier();
//     const userDetails = getUserDetails();
//     let phoneNumber = '';
//     if(userDetails != null){
//         if(userDetails.hasOwnProperty('MobileNo')){
//             phoneNumber = userDetails['MobileNo'];
//         }
//     }
//     const data = {
//         'event_type' : 'Click',
//         'event_sub_type' : 'Submit_Transaction_Code_Web',
//         'mobile_no' : `${dataCaptured.mobileNo}`,
//         'user_identifiers' : CdpCookie,
//         'brand_name' : 'Thums Up',
//         'phone_with_countrycode' : `+91${phoneNumber}`,
//         // 'unique_code' : `${dataCaptured.uniqueCode}` // check key name
//     };
//    // need to confirm field names from sonu sir
//     console.log('cdp unqiuecode',cdsPixelID,data);
//     window.cds_pixel(cdsPixelID,data);
// }

export const ClickButtonCDP = async (eventSubType) => {
    // const CdpCookie = await GetUserIdentifier();
    const data = {
        'event_type' : 'Click',
        'event_sub_type' : `${eventSubType}`,
        // 'user_identifiers' : CdpCookie,
        'brand_name' : 'Thums Up'
    };
    console.log('cdp clickbutton',cdsPixelID,data);
    window.cds_pixel(cdsPixelID,data);
}

export const onLoadCDP = async (eventSubType) => {
    // const CdpCookie = await GetUserIdentifier();
    const data = {
        'event_type' : 'On load',
        'event_sub_type' : `${eventSubType}`,
        // 'user_identifiers' : CdpCookie,
        'brand_name' : 'Thums Up'
    }
    console.log('cdp onload',cdsPixelID,data);
    window.cds_pixel(cdsPixelID,data);
}

export const stumCamCDP = async (eventSubType) => {
    // const CdpCookie = GetUserIdentifier();
    const data = {
        'event_type' : 'Click',
        'event_sub_type' : `${eventSubType}`,
        // 'user_identifiers' : CdpCookie,
        'brand_name' : 'Thums Up'
    }
    console.log('cdp stumpcamp',cdsPixelID,data);
    window.cds_pixel(cdsPixelID,data);
}

export const fieldCDP = async (eventSubType,fieldType,fieldValue) => {
    let capturedField;
    let field = fieldType.toLowerCase();
    let data;

    const preField = {
        'event_type' : 'Click',
        'event_sub_type' : `${eventSubType}`,
        'brand_name' : 'Thums Up'
    }

    if(field == 'name') {
        // data = {        
        //     'event_type' : 'Click',
        //     'event_sub_type' : `${eventSubType}`,
        //     'brand_name' : 'Thums Up',    
        //     'first_name' : `${fieldValue}`   
        // };
        return;
    }
    else if(field == 'email') {
        // data = {
        //     'event_type' : 'Click',
        //     'event_sub_type' : `${eventSubType}`,
        //     'brand_name' : 'Thums Up', 
        //     'email' : `${fieldValue}`
        // };
        return;
    }
    else if(field == 'gender') {
        let genderdesc = '';
        if(fieldValue.toLowerCase() == 'male'){
            genderdesc = 'm';
        }else if(fieldValue.toLowerCase() == 'female'){
            genderdesc = 'f';
        }else if(fieldValue.toLowerCase() == 'other'){
            genderdesc = 'o';
        }
        data = {
            'event_type' : 'Click',
            'event_sub_type' : `${eventSubType}`,
            'brand_name' : 'Thums Up', 
            'gender' : `${genderdesc}`                     
        };
    }
    else if(field == 'state') {
        data = {
            'event_type' : 'Click',
            'event_sub_type' : `${eventSubType}`,
            'brand_name' : 'Thums Up', 
            'address_state' : `${fieldValue}`
        };
    }
    else if(field === 'city') {
        data = {
            'event_type' : 'Click',
            'event_sub_type' : `${eventSubType}`,
            'brand_name' : 'Thums Up', 
            'address_city' : `${fieldValue}`,
        };
    }
    else if(field === 'pincode') {
        data = {
            'event_type' : 'Click',
            'event_sub_type' : `${eventSubType}`,
            'brand_name' : 'Thums Up', 
            'address_postalcode' : `${fieldValue}`
        };
    }
    else if(field === 'age')  {
        data = {
            // 'age' : `${fieldValue}`,
            'event_type' : 'Click',
            'event_sub_type' : `${eventSubType}`,
            'brand_name' : 'Thums Up', 
        };
    } // neede to confirm key

    // const dataCdp = {...preField,data};
    console.log('cdp field',cdsPixelID,data);
    window.cds_pixel(cdsPixelID,data);
}

export const HomeCDP = async () => {
    // const CdpCookie = await GetUserIdentifier();
    const data = {
        'event_type' : 'Page_view',
        'event_sub_type' : 'Homepage_load',
        // 'user_identifiers' : CdpCookie,
        'brand_name' : 'Thums Up'
    }
    const res = getGeoData();

    const geoData = {
        'geo_country_code' : 'IN',
        'geo_state_province_code' : res['stateCode'],
        'geo_city_name' : res['city'],
        'geo_postal_code' : res['Pincode'],
        'ip_address' : res['ip']
    }

    const dataCdp = {...data,...geoData};
    console.log('cdp Home',dataCdp);
    window.cds_pixel(cdsPixelID,dataCdp);
}

