import CryptoJS from 'crypto-js';
import { SECRET } from '../config/constant';
import Cookies from 'js-cookie';

export const setToken = (token) => {
  console.log('%token', token);
  // Cookies.set('token', token, { expires: 7 })
  localStorage.setItem('token', token);
};

// export const setUserDetails = (dummy_data) => {
//   localStorage.setItem('user_details', JSON.stringify(dummy_data));
// }

// export const getUserDetails = () => {
//   if (typeof localStorage !== 'undefined') {
//     let userDetails = localStorage.getItem('user_details');
//     if (userDetails != 'undefined') {
//       return JSON.parse(userDetails)
//     } else {
//       return null
//     }
//   }
// }

export const encryptUserId = (userId) => {
  if (userId) {
    const encryptedUserId = CryptoJS.AES.encrypt(userId, SECRET);
    return encryptedUserId.toString();
  } else {
    return null;
  }
};

export const setUserDetails = (dummy_data) => {
  let env = localStorage.getItem('server');
  let dataString = JSON.stringify(dummy_data);
  // Encrypt data
  let encryptedData = CryptoJS.AES.encrypt(dataString, SECRET);
  console.log(dataString, '%%%');
  // Store encrypted data in localStorage
  if (env === 'PROD') {
    localStorage.setItem('user_details_prod', encryptedData.toString());
  } else {
    localStorage.setItem('user_details_dev', encryptedData.toString());
  }
};

export const getUserDetails = () => {
  if (typeof localStorage !== 'undefined') {
    let env = localStorage.getItem('server');
    if (env === 'PROD') {
      let userDetails = localStorage.getItem('user_details_prod');
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(userDetails, SECRET);
        // Convert the decrypted bytes to a readable string (assuming it was originally a JSON string)
        const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        // Parse the decrypted string back to a JSON object
        const decryptedJSON = JSON.parse(decryptedString);
        // console.log('%%%', decryptedJSON)
        if (decryptedJSON) {
          return decryptedJSON;
        } else {
          return null;
        }
      } catch (error) { }
    } else {
      let userDetails = localStorage.getItem('user_details_dev');
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(userDetails, SECRET);
        // Convert the decrypted bytes to a readable string (assuming it was originally a JSON string)
        const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        // Parse the decrypted string back to a JSON object
        const decryptedJSON = JSON.parse(decryptedString);
        // console.log('%%%', decryptedJSON)
        if (decryptedJSON) {
          return decryptedJSON;
        } else {
          return null;
        }
      } catch (error) { }
    }
  }
};

export const getIpDetails = () => {
  const ipData = localStorage.getItem('ipDetails')
    ? JSON.parse(localStorage.getItem('ipDetails'))
    : null;
  return ipData;
};

const year = new Date().getFullYear();
export const currentYear = year.toString();

//Check user is able to claim or not
export const ableToclaim = (victoryCoin) => {
  return Object.values(victoryCoin).some((value) => value === 0);
};

//Check user is able to reddem merch or not
export const ableToRedeemMerch = (victoryCoin) => {
  return Object.values(victoryCoin).some((value) => value >= 5);
};

export const allFourCoinCollected = (VictoryCoinDetail) => {
  const coinsGreaterThanZeroExcept1983 = Object.entries(
    VictoryCoinDetail
  ).every(([coin, value]) => coin === 'Coin_1983' || value > 0);
  return coinsGreaterThanZeroExcept1983;
};

export const allFiveCoinCollected = (VictoryCoinDetail) => {
  const collected = Object.entries(VictoryCoinDetail).every(
    ([coin, value]) => value > 0
  );
  return collected;
};

export const handleShare = async (title, text, image, url) => {
  try {
    if (navigator.share) {
      const response = await fetch(image);
      const blob = await response.blob();
      const filesArray = [new File([blob], 'image.png', { type: 'image/png' })];
      const shareData = {
        title: title,
        text: text,
        files: filesArray,
      };

      if (url) {
        shareData.url = url;
      }
      await navigator.share(shareData);
      // Handle successful share
    } else {
      // Web Share API not supported
      // Display a tooltip or fallback share options
    }
  } catch (error) {
    console.error('Error sharing:', error);
    // Handle error while sharing
  }
};

export const handleKeyPressMobileNumber = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, '').slice(0, 10);

  // Check if the filtered value is '00000000' and handle accordingly
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error('Invalid mobile number');
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressName = (event) => {
  const inputValue = event.target.value;
  const filteredValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressotp = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 6 digits
  const filteredValue = currentValue.replace(/\D/g, '').slice(0, 6);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressAddress = (event) => {
  const regex = /^[a-zA-Z0-9\s,-]+$/;
  const inputValue = event.target.value;
  // Remove any characters that do not match the regex
  const filteredValue = inputValue.replace(
    new RegExp(`[^${regex.source}]`, 'g'),
    ''
  );
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressAge = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 3 digits
  const filteredValue = currentValue.replace(/\D/g, '').slice(0, 3);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressRestrictNumberAndSpecialCharacter = (event) => {
  const inputValue = event.target.value;
  const filteredValue = inputValue.replace(/[^a-zA-Z]/g, '');
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleShareText = async (text) => {
  try {
    if (navigator.share) {
      const shareData = {
        text: text,
      };
      await navigator.share(shareData);
      // Handle successful share
    } else {
      // Web Share API not supported
      // Display a tooltip or fallback share options
    }
  } catch (error) {
    console.error('Error sharing:', error);
    // Handle error while sharing
  }
};

export const roundedValue = (decimalValue) => {
  if (decimalValue) {
    let roundOff = Math.round(decimalValue);
    return roundOff;
  } else {
    return '';
  }
};

export const handleInputLimitedLength = (event, length) => {
  const inputValue = event.target.value;
  const maxLength = length;

  // Limit the input value to the maximum length
  const limitedValue = inputValue.slice(0, maxLength);

  // Update the input value with the limited value
  event.target.value = limitedValue;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return '';
  }
};

// getting claim form details from cache if user once filled the claim form

export const getPreFilledClaimFormDetailsCache = () => {
  if (localStorage) {
    let claimFormDetails = localStorage.getItem('claimFormDetails');

    if (claimFormDetails) {
      return JSON.parse(claimFormDetails);
    } else {
      return null;
    }
  }
};

export const setClaimFormDetailsCache = (data) => {
  if (localStorage) {
    localStorage.setItem('claimFormDetails', JSON.stringify(data));
  }
};

export const disableInspect = () => {
  if (typeof window !== 'undefined') {
    // Disable inspect element
    document.onkeydown = (event) => {
      console.log('event', event.ctrlKey, event.shiftKey, event.keyCode);
      if (event.keyCode === 123 || event.key === 'F12') {
        return false;
      } else if (
        event.ctrlKey &&
        event.shiftKey &&
        (event.keyCode === 73 ||
          event.keyCode === 67 ||
          event.key === 'I' ||
          event.key === 'C')
      ) {
        return false;
      }
    };
    // Disable right click
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }
};

export const getCDSPixelId = async () => {
  //const fetchPixelId = await window.cookieStore.get('__cds_pixel_id');
  const fetchPixelId = document.cookie
    .split('; ')
    .find((row) => row.startsWith('__cds_pixel_id='))
    .split('=')[1];
  //const hfSessionKey = fetchPixelId['value'];
  return fetchPixelId;
};

export const CounterFloodLight = (sendToId) => {
  console.log(sendToId);
  window.gtag('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: `${sendToId}`,
  });
};

export const MetaPixel = (trigger) => {
  window.fbq('track', trigger);
};

// export const TTDPixel = (pixelId) => {
//   console.log("test")
//   setTimeout(() => {
//     console.log("test1")
//     window.ttd_dom_ready( function() {
//       if (typeof window.TTDUniversalPixelApi === 'function') {
//           var universalPixelApi = new window.TTDUniversalPixelApi();
//           universalPixelApi.init("2xop800", [`${pixelId}`], "https://insight.adsrvr.org/track/up");
//       }
//   });
//   }, 1000);
// }

// ttd_dom_ready.js (Assuming this script is loaded before the script containing TTDPixel function)
window.ttd_dom_ready = function (callback) {
  // Check if the document has loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    callback();
  }
};

// Your main script file (e.g., main.js)
export const TTDPixel = (pixelId) => {
  function initTTDPixel() {
    if (typeof window.TTDUniversalPixelApi === 'function') {
      var universalPixelApi = new window.TTDUniversalPixelApi();
      universalPixelApi.init(
        '2xop800',
        [`${pixelId}`],
        'https://insight.adsrvr.org/track/up'
      );
    }
  }

  if (typeof window.ttd_dom_ready === 'function') {
    window.ttd_dom_ready(initTTDPixel);
  } else {
    // Handle the case when ttd_dom_ready is not available or not defined.
    // You might want to wait or find an alternative way to ensure the required function is available.
  }
};

export const getClassByname = (language) => {
  let className = 'en_global';
  if (language === 'hi') {
    //for hindi
    className = 'hi_global';
  } else if (language === 'bn') {
    //for bangali
    className = 'bn_global';
  } else if (language === 'te') {
    // for tamil
    className = 'tl_global';
  } else if (language === 'or') {
    // for oriya
    className = 'or_global';
  } else if (language === 'kn') {
    // for kannad
    className = 'kn_global';
  } else if (language === 'gu') {
    // for gujrati
    className = 'gg_global';
  } else if (language === 'mr') {
    // for marathi
    className = 'mr_global';
  } else if (language === 'pa') {
    // for panjabi
    className = 'pa_global';
  }
  return className;
};

const isStringInJSONFormat = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

const getMessage = (message) => {
  const cookieValue = Cookies.get('language');
  const currentLanguage = cookieValue ? cookieValue : 'en';
  const isJSONFormat = isStringInJSONFormat(message);
  if (isJSONFormat) {
    let parsed_value = message && JSON.parse(message);
    console.log('errorRes', parsed_value[currentLanguage]);
    if (parsed_value) {
      return parsed_value[currentLanguage];
    } else {
      return '';
    }
  } else {
    console.log('eeror in parse json');
    return '';
  }
};

export const handleErrorMessages = (error, setFieldError) => {
  let errorRes = error.response && error.response.data;
  let errorarray =
    errorRes.Data && Array.isArray(errorRes.Data) && errorRes.Data.length
      ? errorRes.Data
      : '';
  if (errorarray) {
    errorarray.map((el) => {
      let message = getMessage(el.Message);
      setFieldError(el.PropertyName, message ? message : el.Message);
      return null;
    });
  } else {
    let message = getMessage(errorRes.Message);
    setFieldError('Message', message ? message : errorRes.Message);
  }
};

export const getErrorMessage = (msg) => {
  let message = getMessage(msg);
  return message;
};

export const filterCity = (city) => {
  let filterItem = [
    'Bangalore Urban',
    'Bangalore Rural',
    'Central Delhi',
    'East Delhi',
    'North Delhi',
    'North East Delhi',
    'North West Delhi',
    'South Delhi',
    'South West Delhi',
    'West Delhi',
    'New Delhi',
    'Alipur',
    'Bawana',
    'Deoli',
    'Karol Bagh',
    'Najafgarh',
    'Nangloi Jat',
    'Narela',
    'Pitampura',
    'Rohini',
  ];
  if (city && city.length) {
    let filterRecords = city.filter((el) => !filterItem.includes(el.value));
    return filterRecords;
  }
  return [];
};

export const getCountryNameBasedOnCode = (code) => {
  const country = [
    { code: 'AF', label: 'Afghanistan' },
    { code: 'AU', label: 'Australia' },
    { code: 'BD', label: 'Bangladesh' },
    { code: 'GB', label: 'England' },
    { code: 'IN', label: 'India' },
    { code: 'NL', label: 'Netherlands' },
    { code: 'NZ', label: 'New Zealand' },
    { code: 'PK', label: 'Pakistan' },
    { code: 'ZA', label: 'South Africa' },
    { code: 'LK', label: 'Sri Lanka' },
  ];
  const selectedCountry = country.find((item) => item.code === code);

  if (selectedCountry) {
    return selectedCountry.label;
  } else {
    return ''; // Return a default value if the code is not found
  }
};

export const getGender = (value, t) => {
  if (value) {
    let genderList = {
      male: t.M,
      female: t.F,
      other: t.O,
    };
    return genderList[value];
  } else {
    return '';
  }
};

export const TwitterPixel = (pixelId) => {
  window.twq('event', `${pixelId}`, {});
};

/**
 * @method downloadFileByAnchor
 * @description download image through anchor tag
 */
export const downloadFileByAnchor = (
  URL,
  NAME = "file_name",
  FILE_EXTENSION = "jpeg"
) => {
  console.log("canvas.toDataURL(): ", URL);

  if (!URL || !NAME || !FILE_EXTENSION) {
    return;
  }

  // Create a download link for the canvas
  const downloadLink = document.createElement("a");
  downloadLink.href = URL;
  downloadLink.download = `${NAME}.${FILE_EXTENSION}`; // The name of the downloaded file
  document.body.appendChild(downloadLink);
  downloadLink.click(); // Simulate a click on the anchor tag to trigger the download
  document.body.removeChild(downloadLink); // Clean up by removing the anchor tag from the document
};

// export function base64ToBlob(base64, type) {
//   const dataStart = base64.indexOf(',') + 1; // Find the start of the Base64 data.
//   const cleanedBase64 = base64.slice(dataStart);
//   return new Blob([Uint8Array.from(atob(cleanedBase64), c => c.charCodeAt(0))], { type });
// }

export function base64ToBlob(base64) {
  const base64StringWithoutPrefix = base64.replace(/^data:image\/\w+;base64,/, '');
  var binaryString = window.atob(base64StringWithoutPrefix);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);

  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes]);
}

export const extractHrefFromHTML = (htmlString) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  const anchorElement = tempElement.querySelector('a');
  return anchorElement ? anchorElement.getAttribute('href') : null;
};
