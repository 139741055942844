import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import LanguageContext from "../utils/LanguageContext";
import translations from "../utils/translation";

const ComingSoon = (props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <>
      <div className="cs_container">
        <div className="content">
          <h1>Coming Soon</h1>
          <p>
            This promotion starts on 15th July 2023, come back later to
            participate.
          </p>
        </div>
        <img src="assets/images/thumsup.png" id="thumsup-image" alt={''}/>
        <img src="assets/images/player-left-img.png" id="player-left" alt={''}/>
        <img src="assets/images/player-right-img-flip.png" id="player-right" alt={''}/>
      </div>
      <div className="cs_seperator"></div>
      <footer className="footer">
        <div className="container-fluid">
          <p>
           {`${t.the_coco_cola_company}`}
          </p>

          <p>
            &reg; {`${t.trade_mark_owner}: ${t.coco_cola}`}. ** OFFER VALID FROM
            15TH JULY, 2023 TO 15TH SEPTEMBER, 2023. WORLD CUP MATCH TICKET
            AVAILABLE PER ORGANIZER’S DISCRETION. NOT APPLICABLE IN THE STATE OF
            TN.
          </p>
        </div>
      </footer>
    </>
  );
};

export default ComingSoon;
