import React, { useState, useEffect, memo, useContext } from "react";
import { Button } from "react-bootstrap";
import {
    getUserDetails, allFourCoinCollected, allFiveCoinCollected
    , CounterFloodLight,
    MetaPixel,
    TwitterPixel
} from '../../utils/helper'
import { getWalletDetailsById, saveTicketWinner } from "../../actions/Auth";
import InfoModal from '../model/InfoModal';
import { useNavigate, useLocation } from 'react-router-dom';
import MyWallet from '../common/MyWallet';
//import MyWallet from '../common/NewMyWallet';
import ClaimTicket from '../model/ClaimTicket'
import { handleShare } from "../../utils/helper";
import { ClickButtonCDP, onLoadCDP } from "../../utils/cdsHelper";
import CommonContext from '../contextApi';
import LanguageContext from "../../utils/LanguageContext";
import { claimTicketSuccess, redeemErrorModel, claimfailError } from "../../utils/modelHelper";
import { getErrorMessage } from "../../utils/helper";


const VictoryCoinModelJourney = (props) => {

    const { language } = useContext(LanguageContext);
    const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
    const { t, data, handleSuccesModelClose, setShowMywalletEvent } = props
    const navigate = useNavigate();
    const location = useLocation();
    let user_details = getUserDetails()
    const [popup, setPopupInfo] = useState({
        title: "",
        content: ""
    });
    const [walletDetails, setWalletDetails] = useState('')
    const [showMywallet, setShowMywallet] = useState(false)
    const [showClaimTicket, setShowClaimTicket] = useState(false);

    useEffect(() => {
        console.log('data&&&&', data)
        if (data.error) {
            if (data.errorCode === 404 || data.errorCode === 400) {
                inCorrectCodeModel()
                //userWinTicketModel()
                //fourCoinCollectionComplete()
                //saveTicketWinnerApi()
            } else if (data.errorCode === 706) {
                usedCodeModel()
                //userWinTicketModel()
            }
            else if (data.errorCode === 406) {
                dailyScanLimitOverModel()
            }
        } else if (data.coin_type) {
            getWalletDetails(data.coin_type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    const navigateBack = () => {
        const currentPathname = location.pathname;
        console.log('currentPathname', currentPathname)
        navigate(`${currentPathname}`, { replace: true });
    }

    /**
       * @method getWalletDetails
       * @description get wallet details api calling
       */
    const getWalletDetails = async (coin_type) => {
        let requestData = {
            Id: user_details && user_details.Id
        }
        try {
            const response = await getWalletDetailsById(requestData);
            if (response.status === 200) {
                let data = response && response.data && response.data.Data
                setWalletDetails(data)
                codeRedeemModel(coin_type, data)
            }
        } catch (error) {
            console.error('Error updating profile name:', error);
            // Handle the error as needed
        }
    }

    // handle all type of api error
    const apiErrorModel = (message) => {
        setPopupInfo({
            title: (<h2>{''}</h2>),
            content: (<h4>{message}<br /></h4>),
            image: '',
            className: 'incorrect-noball no_ball',
        });
    }

    /**
      * @method getWalletDetails
      * @description get wallet details api calling
      */
    const saveTicketWinnerApi = async () => {
        let requestData = {
            UserId: user_details && user_details.Id
        }
        try {
            const response = await saveTicketWinner(requestData);
            if (response.status === 200) {
                userWinTicketModel();
            }
        } catch (error) {
            let errorRes = error.response && error.response.data
            let message = getErrorMessage(errorRes.Message)
            apiErrorModel(message)
        }
    }

    /**
    * @method redeemMerchModel
    * @description daily quata is over model
    */
    const redeemMerchModel = () => {
        setPopupInfo(redeemErrorModel(t))
    };


    //open model when user enter incorrect code
    const inCorrectCodeModel = () => {
        setPopupInfo({
            title: (<h2>{t.no_ball}</h2>),
            content: (<h4>{t.valid_code}</h4>),
            image: '',
            className: 'incorrect-noball no_ball',
        });
    }

    //model will show ones daily unique code scan limit is over max limit is 10
    const dailyScanLimitOverModel = () => {
        setPopupInfo({
            title: (<h2>{t.no_ball}</h2>),
            content: (<h4>{t.scan_limit_over}</h4>),
            image: '',
            className: 'incorrect-noball no_ball',
        });
    }

    //open model when user enter already used code
    const usedCodeModel = () => {
        setPopupInfo({
            title: (<h2>{t.no_ball}</h2>),
            content: (<h4>{t.already_use_code}<br /><span>{t.please_enter_new}</span></h4>),
            image: '',
            className: 'incorrect-noball no_ball',
        });
    }

    // render code reddem model for coins wins single
    const codeRedeemModel = (coin_type, walletInfo) => {
        let coin = 'assets/images/2017-coin.png'
        if (coin_type === 2007) {
            coin = 'assets/images/2017-coin.png'
        } else if (coin_type === 2011) {
            coin = 'assets/images/2011-coin.png'
        } else if (coin_type === 2013) {
            coin = 'assets/images/2013-coin.png'
        } else if (coin_type === 2023) {
            coin = 'assets/images/2023-coin.png'
        }
        console.log(coin_type, '&&&code', coin_type === 2023, walletInfo.Silver)
        if (coin_type === 2023 && walletInfo && walletInfo.Silver < 1) {
            // when user collects all victory coins  and not claim tickets
            victoryCoinCollectionComplete(walletInfo)
        } else {
            //ones user wins any one victory coin 2017, 2011, 2013
            oneVictoryCoinCollectionComplete(coin)
        }
    }

    const oneVictoryCoinCollectionComplete = (coin) => {
        console.log('coin', coin)
        setPopupInfo({
            title: (<h2>{t.big_one_victory_coin}</h2>),
            content: (<h4>{t.you_win1_victory_coin}</h4>),
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            image: (<img src={coin} alt={'image'} height={'160px'} width={'160px'} />),
            button: (
                <Button variant="danger" className="center-button" onClick={() => {
                    setShowMywallet(true)
                    setShowMywalletEvent()
                    setPopupInfo({
                        title: "", // Reset the title
                        content: "" // Reset the content
                    });
                    navigate('/', { replace: true });
                    triggerActionAfterLogin('');
                    CounterFloodLight('DC-12665261/thums0/thums02k+unique');
                    MetaPixel('see_collection');
                    TwitterPixel('tw-ofua3-ofvci');
                }}>
                    {`${t.see_collection}`}
                </Button>
            ),
            className: 'profile-success',
        });
    }

    /**
     * @method victoryCoinCollectionComplete
     * @description victory coin collection complete model
     */
    const victoryCoinCollectionComplete = (walletInfo) => {
        let victoryCoin = walletInfo && walletInfo.VictoryCoinDetail
        let allFourCompleted = allFourCoinCollected(victoryCoin)
        let allFive = allFiveCoinCollected(victoryCoin)
        console.log(allFive, 'allFourCompleted', allFourCompleted)
        if (allFive) {
            console.log('case1')
            // userWinTicketModel();
            saveTicketWinnerApi();
            onLoadCDP('Win_ICCTicket');
        } else if (allFourCompleted) {
            console.log('case2')
            fourCoinCollectionComplete()
        }
    }

    /**
      * @method userWinTicketModel
      * @description model will show ones user win all the victory coins 
      */
    const userWinTicketModel = () => {
        setPopupInfo({
            title: (<h2>{t.toofan_uthega}</h2>),
            content: (<div>
                <h3 className='mb-0'>
                    {t.congratulations}
                </h3>
                <h3>
                    {t.victory_coin_collection}<br />
                    {t.claim_your_world_cup}
                </h3>
            </div>),
            image: <img src={"assets/images/silver-coin-win.png"} alt={"ticket"} />,
            button: (
                <Button variant="danger" className="center-button" onClick={() => {
                    setPopupInfo({
                        title: "", // Reset the title
                        content: "" // Reset the content
                    });
                    navigate('/', { replace: true });
                    triggerActionAfterLogin('')
                    setShowClaimTicket(true)
                }}>
                    {`${t.claim_now}`}
                </Button>
            ),
            className: 'wallet-success'
        });
    }

    // when user wins four match coins except 1983
    const fourCoinCollectionComplete = () => {
        setPopupInfo({
            topHeading: (<h2>{t.victory_coin_heading}</h2>),
            title: (<div>
                <h3 className='mb-0 victory'>
                    {t.only_one_coin_left}<br />{t.from_match_ticket}
                </h3>
                <h4 className="victories">
                    {t.vote_complete_collection}
                </h4>
            </div>),
            image: (<img src={'assets/images/four-collection-complete.png'} alt={'ticket'} />),
            button: (
                <Button variant="danger" className="center-button" onClick={() => {
                    navigateBack()
                    closePopup()
                }}>
                    {t.vote_and_win}
                </Button>
            ),
            className: 'redeem-success redeem-hi'
        });
    }

    /**
   * @method toofaniWinTicket
   * @description toofani win ticket model congratulations
   */
    const toofaniWinTicket = () => {
        setPopupInfo(claimTicketSuccess(t, language));
    }

    const closePopup = () => {
        setPopupInfo({
            title: "", // Reset the title
            content: "" // Reset the content
        });
        handleSuccesModelClose()
        triggerActionAfterLogin('')
        navigateBack()
    };

    /**
     * @method claimfailError
     * @description model will open if you claim all the tickets
     */
    const claimFail = (content1, content2) => {
        setPopupInfo(claimfailError(content1, content2));
    };


    return (
        <>
            {popup.title && (
                <InfoModal
                    title={popup.title} // Set the title for the pop-up
                    content={popup.content} // Set the content for the pop-up
                    onClose={closePopup} // Close the pop-up
                    button={popup.button}
                    image={popup.image}
                    // bgImage={popup.bgImage}
                    className={popup.className}
                    topHeading={popup.topHeading}
                />
            )}
            {<MyWallet
                t={t}
                setShowLoginModal={showMywallet}
                show={showMywallet}
                handleClose={() => setShowMywallet(false)}
            />}
            {showClaimTicket &&
                <ClaimTicket
                    source={'ticket'}
                    show={showClaimTicket}
                    handleClose={() => {
                        setShowMywallet(false)
                        setShowClaimTicket(false)
                    }
                    }
                    t={t}
                    toofaniWinTicket={toofaniWinTicket}
                    redeemMerchModel={redeemMerchModel}
                    claimFail={claimFail}
                    handleCross={() => {
                        setShowClaimTicket(false)
                    }}
                />}

        </>
    );
};


export default memo(VictoryCoinModelJourney)
