import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  getUserDetails,
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  TwitterPixel,
} from '../../utils/helper';
import CommonContext from '../contextApi';
import LanguageContext from '../../utils/LanguageContext';

const TicketModel = ({
  show,
  onClose,
  t,
  showUniqueCodeModel,
  setShowLoginModal,
}) => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  let user_details = getUserDetails();
  const { triggerActionAfterLogin } = useContext(CommonContext);

  return (
    <Modal
      size="lg"
      className={`tu-common-modal collect-coins gold-coin`}
      show={show}
      onHide={onClose}
      centered>
      <Modal.Header className="text-center">
        <img
          className="cross-button"
          src="assets/images/close.png"
          alt="Close"
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        />
      </Modal.Header>
      <Modal.Body className="text-center py-14 mb-6">
        {/* <p
          className="display-1"
          dangerouslySetInnerHTML={{ __html: t.ticket_promo_ended }}></p> */}
        <img className='gold-coin' src={'assets/images/5-gold-coin.png'} alt={'gold-coin'} />
        <h2 className='mt-2' dangerouslySetInnerHTML={{
            __html: t.collect_victory_coins
          }}></h2>
        <img className='img-fluid px-5 px-md-auto' src={`assets/images/steps_${language}.png`} alt={'steps'} />
        <Row className='victory-wrapper'>
          <Col>
            <img className="thumps-img-pop" src={'assets/images/thumps-up-bottle1.png'} alt={'thumps-bottle'} />
          </Col>
          <Col>
            <img className="img-fluid" src={`assets/images/submit-star_${language}.png`} alt={'submit-star'} />
          </Col>
          <Col>
            <img className="img-fluid" src={'assets/images/red-coin-gold.png'} alt={'coin'} style={{ width: "100px" }} />
          </Col>
          <Col>
            <img className="img-fluid ticket-2x-img" src={'assets/images/victory-coin.svg'} alt={'ticket'} />
          </Col>
        </Row>
        <Row className='victory-wrapper caption align-items-start'>
          <Col className='px-md-3 px-1'> <h5 className='display-3 ' dangerouslySetInnerHTML={{
            __html: t.find_unique_code
          }}></h5></Col>
          <Col className='px-md-3 px-1'>
            <h5 className='display-3 '
              dangerouslySetInnerHTML={{
                __html: t.enter_code
              }}
            ></h5></Col>
          <Col className='px-md-3 px-1'> <h5 className='display-3 ' dangerouslySetInnerHTML={{
            __html: t.get_a_victory_coin
          }}></h5></Col>
          <Col className='px-md-3 px-1'> <h5 className='display-3 ' dangerouslySetInnerHTML={{
            __html: t.collect_victory
          }}></h5></Col>
        </Row>
        <Row className='victory-wrapper'>
          <Col className='d-block'>
            <Button className='btn btn-sm btn-primary mt-2' onClick={() => {
              onClose()
              if (user_details) {
                showUniqueCodeModel()
              } else {
                setShowLoginModal()
                triggerActionAfterLogin({ type: 'unique_code', isLoggedIn: false })
              }
            }
            }>
              {t.collect_now}
            </Button>
            <h4 className='mt-2 mb-2'>{t.cast_vote}</h4>

            <button className="btn btn-sm btn-primary" onClick={() => {
              onClose()
              navigate('/#vote_now')
              // if(user_details){
              //     navigate('/#vote_now')
              // }else {
              //     setShowLoginModal()
              //     triggerActionAfterLogin({ type: 'vote_now', isLoggedIn: false })
              // }
              CounterFloodLight('DC-12665261/thums0/thums02j+unique');
              MetaPixel('VoteNow');
              TTDPixel('5d3q2ub');
              TwitterPixel('tw-ofua3-ofvbe');
            }}>
              {t.vote_now}
            </button>
            {/* <p className='last-date'>{t.last_date_ticket}</p> */}
          </Col>
        </Row>
        <p className='last-date  mt-2'>{t.last_date_ticket}</p>
        {/* <h6 className='bottom-txt mt-2'>{t.last_date_ticket}</h6> */}
      </Modal.Body>
    </Modal>
  );
};

export default TicketModel;
