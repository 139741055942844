import React, { useContext } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { sendReferAFriendCode } from "../../actions/Auth";
import { 
  CounterFloodLight, 
  MetaPixel, 
  TTDPixel, 
  getUserDetails,
  handleErrorMessages,
  TwitterPixel
 } from "../../utils/helper";
import { LoaderContext } from "../loader/LoaderContext";
import { handleKeyPressMobileNumber } from "../../utils/helper";
import { Link } from "react-router-dom";
import { privacy_policy } from "../../config/constant";
import Loader from "../loader";
import { ClickButtonCDP } from "../../utils/cdsHelper";

const ReferAFriend = ({ t, handleClose, show,referFriendSuccussModel }) => {

  let user_details = getUserDetails();
  const { toggleLoader } = useContext(LoaderContext);

  // schema for field level validation
  const schema = Yup.object().shape({
    MobileNumber: Yup.string().required(t.validation_messages.mobile_required)
    .matches(/^[6789]\d{9}$/, t.validation_messages.invalid_mobile_number),
    Email: Yup.string()
      .email(t.validation_messages.invalid_email)
      .test("is-valid-email", t.validation_messages.invalid_email, (value) => {
        // Custom email validation logic
        if (!value) return true; // Allow empty value
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
  });

  /**
   * @method handleSubmit
   * @description Handles the form submission for sending mobileNumber and proceeding to the next step
   */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    try {
      toggleLoader();
      const requestData = {
        Id: user_details && user_details.Id,
        MobileNumber: values.MobileNumber
      };
      if (values.Email) {
        requestData.Email = values.Email
      }
      CounterFloodLight('DC-12665261/thums0/thums023+unique');
      MetaPixel('ReferAFriendSubmit');
      TTDPixel('udrry3c');
      TwitterPixel('tw-ofua3-ofvcf');
      const response = await sendReferAFriendCode(requestData);
      if (response && response.status === 200) {
        // Handle success, e.g., show a success message
        handleClose();
        let data = response && response.data && response.data.Data
        let refCode = data.RefCode
        referFriendSuccussModel(refCode);
        ClickButtonCDP('Refer_Friend');
        // window.google_tag_manager[process.env.REACT_APP_G_GTM_ID].dataLayer.push({
        //   event: 'tureferafriendsubmitbtn'
        // });
        window.dataLayer.push({event: 'tureferafriendsubmitbtn'})
      }
    } catch (error) {
      console.log("Error:", error);
      handleErrorMessages(error, setFieldError)
    } finally {
      toggleLoader();
    }
  };
  
  /**
   * @method renderPartnershipForm
   * @description render partnership form fields
   */
  const renderPartnershipForm = () => {
    return (
      <Formik
        validationSchema={schema}
        initialValues={{
          MobileNumber: "",
          Email: "",
        }}
        onSubmit={handleSubmit}
        //validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Form.Group controlId="mobileNumber">
              <Form.Label>{t.friends_mobile_number}</Form.Label>
              <Field
                type="text"
                name="MobileNumber"
                className={`form-control ${errors.MobileNumber ? "is-invalid" : ""}`}
                //placeholder={t.enter_mobile_number}
                value={values.MobileNumber}
                onChange={handleChange}
                //onKeyPress={handleKeyPressMobileNumber}
                onInput={handleKeyPressMobileNumber}
              />
              {errors.MobileNumber && (
                <div className="error_message">{errors.MobileNumber}</div>
              )}
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>{t.friends_email} ({t.optional})</Form.Label>
              <Field
                type="email"
                name="Email"
                className={`form-control ${errors.Email ? "is-invalid" : ""}`}
                //placeholder={t.enter_email}
                value={values.Email}
                onChange={handleChange}
              />
              {values.Email && errors.Email && (
                <div className="error_message error_messagesec">{errors.Email}</div>
              )}
            </Form.Group>
            {errors.Message && (
              <div className="error_message error_red">{errors.Message}</div>
            )}
            <Button className="mb-3 referfriendsubmitbtn" variant="primary" type="submit" block>
              {t.submit}
            </Button>
          </Form>
        )}
      </Formik>
    );
  };


  return (
    <>
     <Loader />
      {show && <Modal
        size="md"
        className="tu-common-modal refer-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <div>
          <Modal.Header>
            <img
              className="cross-button"
              src="assets/images/close.png"
              alt="close-btn"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <img
                  className="modal-left-img"
                  src="assets/images/modal-left-img.png"
                  alt="model-img"
                />
              </Col>
              <Col sm={7}>
                <div className="modal-right-section">
                  <Modal.Title>{t.partnership}</Modal.Title>
                  <div className="modal-sub-title">{t.invite_friends}</div>
                  <h6>{t.signup_coin}</h6>
                  {renderPartnershipForm()}
                  <div className="display-2">
                    <Link to={privacy_policy} target={'_blank'} className="anchor-with-underscore">{t.privacy_policy}</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>}
    </>
  );
};

export default ReferAFriend;