import React, { useState, useEffect, useRef } from 'react';
import { Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Feature from './Feature';
import BuyNow from './BuyNow'
import PrivacyPolicy from './PrivacyPolicy'
import LanguageSwitcherSideBar from './LanguageSwitchSideBar';

const Sidebar = ({ t, setShowLoginModal }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    // Add event listener to handle clicks outside the navbar
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const navigateTo = (route) => {
    // Close the sidebar
    setIsExpanded(false);
    // Navigate to the specified route
    navigate(route);
  };

  return (
    <div className='sidebar-main'>
      <Navbar expand="xxl" expanded={isExpanded} ref={navbarRef}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
        <Navbar.Collapse id="basic-navbar-nav" className='offcanvas-menu'>
          {/* Nav content */}
          <Feature t={t} setShowLoginModal={setShowLoginModal} navigateTo={navigateTo} />
          {/* <BuyNow t={t} navigateTo={navigateTo} /> */}
          <LanguageSwitcherSideBar/>
          <PrivacyPolicy t={t} navigateTo={navigateTo} />
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Sidebar;