import { Link } from 'react-router-dom';
import { BuyNowCDP } from '../../utils/cdsHelper';
import { CounterFloodLight, MetaPixel, TwitterPixel } from '../../utils/helper';

function BuyNow({ t, navigateTo }) {
  return (
    <div className='tu-sidebar-buy-main'>
      <h2>{t.buy_now}</h2>
      <div className='brand-items list-bottom-border'>
        {/* Amazon */}
        <Link 
          to={'https://www.amazon.in/s?bbn=16392737031&rh=n:27960888031'} 
          target={'_blank'} 
          onClick={() => {
            navigateTo();
            BuyNowCDP('BuyNow_Amazon','Amazon');
            CounterFloodLight('DC-12665261/thums0/thums025+unique');
            MetaPixel('Amazon');
          }}
        >
          <img src={('assets/images/amazon.png')} alt="amazon icon" />
        </Link>

        {/* Flipkart */}
        <Link 
          target={'_blank'} 
          to={'https://www.flipkart.com/grocery/snacks-beverages/soft-drinks/pr?sid=73z%2Cujs%2Cdfw&otracker=categorytree&marketplace=GROCERY&fm=neo%2Fmerchandising&iid=M_0f2e17cd-633e-4dd5-a7e0-7b01cfc70930_1_S4W8Z7USNO16_MC.L6I5OTOPKRJ0&otracker=pp_rich_navigation_7_1.navigationCard.RICH_NAVIGATION_Snacks%2B%2526%2BBeverages~Soft%2BDrinks_L6I5OTOPKRJ0&otracker1=pp_rich_navigation_PINNED_neo%2Fmerchandising_NA_NAV_EXPANDABLE_navigationCard_cc_7_L1_view-all&cid=L6I5OTOPKRJ0&p%5B%5D=facets.brand%255B%255D%3DThums%2BUp&utm_source=Flipkart+Grocery&utm_medium=CPC&utm_campaign=Thums+Up-UTM'} 
          onClick={() => {
            navigateTo();
            BuyNowCDP('BuyNow_Flipkart','Flipkart');
            CounterFloodLight('DC-12665261/thums0/thums028+unique');
            MetaPixel('Flipkart');
            TwitterPixel('tw-ofua3-ofvc0');
          }}
        >
          <img src={('assets/images/flipkart.png')} alt="flipkart icon" />
        </Link>

        {/* Blinkit */}
        <Link 
          target={'_blank'} 
          // to={'https://blinkit.com/prn/thums-up-soft-drink-750-ml/prid/314'} 
          onClick={() => {
            navigateTo();
            BuyNowCDP('BuyNow_Blinkit','Blinkit');
            CounterFloodLight('DC-12665261/thums0/thums027+unique');
            MetaPixel('Blinkit');
          }}
        >
          <img src={('assets/images/blinkit.png')} alt="blinkit icon" />
        </Link>

        {/* Big Basket */}
        <Link 
          target={'_blank'} 
          className='mb-0' 
          to={'https://www.bigbasket.com/ps/?q=thumsup&nc=pscs'} 
          onClick={() => {
            navigateTo();
            BuyNowCDP('BuyNow_BigBasket','BigBasket');
            CounterFloodLight('DC-12665261/thums0/thums026+unique');
            MetaPixel('BigBasket');
          }}
        >
          <img src={('assets/images/bb.png')} alt="bb icon" />
        </Link>

        {/* Swiggy */}
        <Link 
          target={'_blank'} 
          className='mb-0' 
          to={'https://www.swiggy.com/?pid=Instagram&c=Awareness&af_force_deeplink=true&is_retargeting=true'} 
          onClick={() =>{ 
            navigateTo();
            BuyNowCDP('BuyNow_Instamart','Swiggy Instamart');
            CounterFloodLight('DC-12665261/thums0/thums029+unique');
            MetaPixel('Swiggy');
            TwitterPixel('tw-ofua3-ofvcl');
          }}
        >
          <img src={('assets/images/swigy.png')} alt="swigy icon" />
        </Link>

        {/* Dmart */}
        <Link 
          target={'_blank'} 
          className='mb-0' 
          to={'https://www.dmart.in/searchResult?searchTerm=thums%20up'} 
          onClick={() => {
            navigateTo();
            BuyNowCDP('BuyNow_DMartReady','D Mart Ready');
            CounterFloodLight('DC-12665261/thums0/thums02-+unique');
            MetaPixel('DMart');
            TwitterPixel('tw-ofua3-ofvbh');
          }}
        >
          <img src={('assets/images/dmart.png')} alt="swigy icon" />
        </Link>
      </div>
    </div>
  );
}

export default BuyNow;
