/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import VoteNow from './VoteNow';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import TossNow from './TossNow';
import { Link } from 'react-router-dom';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import { CounterFloodLight, MetaPixel, TTDPixel, TwitterPixel } from '../../utils/helper';
import { saveToofaniPulse } from '../../actions/Auth'
import { getUserDetails } from '../../utils/helper';

function VoteAndTossSection(props) {
  const { showLoginModal, setShowLoginModal } = props;
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  let user_details = getUserDetails();


  /**
  * @method handleSaveToofaniPulse
  * @description handle save toofani pulse
  */
  const handleSaveToofaniPulse = async () => {
    if (user_details) {
      let requestData = {
        Id: user_details && user_details.Id,
      };
      try {
        await saveToofaniPulse(requestData);
      } catch (error) { }
    }
  };

  return (
    <>
      <section className="section bg-pattern bg-pattern-3 pt-0">
        <div className="container-fluid">
          <VoteNow
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
          />
          <Row className="mt-n15 mt-md-0 pb-14 pb-md-8 vote-sec">
            <Col lg={6} className="tu-middle-section">
              <img
                className="vector vector-left d-block d-md-none"
                src={'assets/images/player-left-img.png'}
                alt="cup icon"
              />
              <img
                className="vector vector-right d-block d-md-none"
                src={'assets/images/player-right-img.png'}
                alt="player"
              />
              <div className="tu-content-wrapper tu-content-wrapper-check pt-0">
                {/* <h3 className="display-1 text-secondary mb-1">{t.desh_ka}</h3>
                <h2 className="section-title mb-1">{t.toofaani_pulse}</h2> */}
                <img src={'assets/images/fanpulse.png'} alt={'toofani pulse'} className='fanpulse fanpulse-home' />
                <h2 className="section-desc">{t.find_india_what_say}</h2>
                <Link to={'/toofani_pulse'} className="btn btn-sm btn-primary checknowbtn"
                  onClick={() => {
                    ClickButtonCDP('Check_Toofani_Pulse_Web');
                    MetaPixel('check_now');
                    CounterFloodLight('DC-12665261/thums0/thums01v+unique');
                    TTDPixel('kkosbbj');
                    TwitterPixel('tw-ofua3-ofvcc');
                    handleSaveToofaniPulse()
                  }}
                >
                  {t.check_now}
                </Link>
              </div>
            </Col>
          </Row>
          <TossNow
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showSection={true}
            closeTossNow={() => console.log('')}
          />
        </div>
      </section>
    </>
  );
}

export default VoteAndTossSection;
