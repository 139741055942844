import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate,useLocation } from 'react-router-dom';


const InfoModal = ({ title, content, onClose, button, image, bgImage, className, topHeading=''}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;
  console.log('currentPathname',currentPathname)

  return (
      <Modal className={`tu-common-modal ${className}`} show={true} onHide={onClose} centered onClick={() =>   navigate(`${currentPathname}`, { replace: true })}>
        <div className="modal-bg" style={{ backgroundImage: `url(${bgImage})` }}>
          <Modal.Header className="text-center mt-3">
          <img
            className="cross-button"
            src="assets/images/close.png"
            alt="Close"
            onClick={onClose}
            style={{ 'cursor': 'pointer' }}
          />
          </Modal.Header>
          <Modal.Body className="text-center">
            {topHeading}
            {image}
            {title}
            {content}
            {button}
          </Modal.Body>
        </div>
      </Modal>
  );
};

export default InfoModal;

