import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { Link } from 'react-router-dom';
import { privacy_policy, terms_condition } from '../../config/constant';
import { currentYear } from '../../utils/helper'

function Footer() {
  
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <footer className="footer">
      <div className="container-fluid">
        <Row className='align-items-baseline'>
          <Col sm={7} className="text-center text-sm-left">
            <p>
             {`${t.the_coco_cola_company}`}
            </p>
            <p>{`${t.trade_mark_owner}`}</p>
          </Col>
          <Col sm={5} className="text-center text-sm-right mt-3 mt-xs-0 terms">
            <p>
              *<Link to={terms_condition} target={'_blank'}>{t.terms_condition}</Link> | <Link to={privacy_policy} target={'_blank'}>{t.privacy_policy}</Link>
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
export default Footer;
