import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import InfoModal from './InfoModal';
import { claimMerchSuccess, claimTicketSuccess, redeemErrorModel, merchClaimError, referFriendSuccess, oneMatchCoinWin,fiveMatchCoinCollected, profileCompletedSuccess } from '../../utils/modelHelper'
import LanguageContext from '../../utils/LanguageContext';
import translations from "../../utils/translation";
import MyWallet from '../common/MyWallet';


function Popups() {
    const [showWallet, setShowMyWallet] = useState(false);
    const [popup, setPopupInfo] = useState({
        title: "",
        content: "",
    });

    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const closePopup = () => {
        setPopupInfo({
            title: "",
            content: "",
        })
    }

    return (
        <div className="container-fluid py-5">
            <h1 className="text-center">Popups</h1>
            <div className="row row-cols-3">
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(claimMerchSuccess(t, language))}>Claim merch success</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(claimTicketSuccess(t, language))}>Claim Ticket success</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(redeemErrorModel(t))}>Already reddem</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(merchClaimError(t))}>All merch claim</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(referFriendSuccess(t, '6768'))}>Refer friend success</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(oneMatchCoinWin(t, setShowMyWallet, closePopup))}>One match coin win</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(fiveMatchCoinCollected(t,closePopup, '', ''))}>Five match coin collected</Button>
                </div>
                <div className="col mb-3">
                    <Button onClick={() => setPopupInfo(profileCompletedSuccess(t, setShowMyWallet, closePopup))}>Profile complete</Button>
                </div>
            </div>
            {popup.title && (
                <InfoModal
                    title={popup.title} // Set the title for the pop-up
                    content={popup.content} // Set the content for the pop-up
                    onClose={closePopup} // Close the pop-up
                    button={popup.button}
                    image={popup.image}
                    className={popup.className}
                    topHeading={popup.topHeading}
                />
            )}
            {<MyWallet
                t={t}
                show={showWallet}
                handleClose={() => setShowMyWallet(false)}
            />}
        </div>
    );
}

export default Popups;
