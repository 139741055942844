import React, { useContext } from 'react';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { currentYear } from '../../utils/helper';

const HeroBannerContest = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <>
      <section className="section-no-strip position-relative banner contest">
        <img
          className="w-100 d-none d-md-block"
          src="assets/images/bgs/bg-9.svg"
          alt="TU"
        />
        <img
          className="w-100 d-block d-md-none"
          src="assets/images/bgs/bg-10.svg"
          alt="TU"
        />
        <div className="container-fluid xy-center">
          <div className="row align-items-center no-gutters text-center">
            <div className="col-3 offset-lg-1 col-lg-2 col-xl-3">
              {/* <img
                src={`assets/images/hero-banner/TU1_${t.lang}.png`}
                alt="toofan"
              /> */}
            </div>
            <div className="col-6 col-md-5 d-flex justify-content-start">
              <h1 
                className="section-title section-title-main section-title-main-heroContest" 
                style={{marginTop:'45px'}}
                dangerouslySetInnerHTML={{
                  __html: t.grab_a_thumps_up,
                }}
              >
                {/* {t.grab_a_thumps_up}&#174;{t.and} <br />{t.start_playing_icic} {currentYear} {t.ticket} {t.and} {t.more}! */}
                {/* {t.grab_a_thumps_up} {t.and} <br />
                {t.start_playing_icic} {currentYear} {t.ticket} {t.and} {t.more}
                ! */}
                 {/* <h3 className="section-sub-title section-sub-title-heroContest mt-1" dangerouslySetInnerHTML={{ __html: t.banner_1_sub_heading_how_to_win }}></h3> */}
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroBannerContest;
