import React, { useContext, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { sendOtp } from '../../actions/Auth';
import { LoaderContext } from '../loader/LoaderContext'; // Import the LoaderContext
import Loader from '../loader';
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  handleKeyPressMobileNumber,
  handleErrorMessages,
  TwitterPixel
} from '../../utils/helper';
const MobileNumberForm = (props) => {
  const { toggleLoader } = useContext(LoaderContext);
  const { t, nextStep, setModelContent } = props;

  /**
   * @method useEffect
   * @description React hook that sets the model content when the component mounts
   */
  useEffect(() => {
    setModelContent({
      title: t.welcome_player,
      header: t.sign_up_redeem_coin,
      image: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @constant MobileNumberSchema
   * @description The form validation schema for mobile number input
   */
  const MobileNumberSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required(t.validation_messages.required)
      .matches(/^[6789]\d{9}$/, t.validation_messages.invalid_mobile_number)
      .min(10, `${t.validation_messages.min_length} 10 ${t.digits}`)
      .max(10, `${t.validation_messages.max_length} 10 ${t.digits}`),
  });

  /**
   * @method handleSubmit
   * @description Handles the form submission for sending mobileNumber and proceeding to the next step
   */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    MetaPixel('GetOTP');
    TTDPixel('9g9h5t0');
    toggleLoader();
    let data = {
      MobileNo: values.mobileNumber,
    };
    try {
      const response = await sendOtp(data);
      if (response.status === 200) {
        resetForm();
        nextStep({
          mobile: values.mobileNumber,
        });
      }
    } catch (error) {
      handleErrorMessages(error, setFieldError)
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  return (
    <>
      <Loader />
      <Formik
        validationSchema={MobileNumberSchema}
        initialValues={{ mobileNumber: '' }}
        onSubmit={handleSubmit}
        //validateOnChange={false}
        validateOnBlur={false}>
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Form.Group className="mt-5 text-left" controlId="mobileNumber">
              <Form.Label style={{ color: 'white' }}>
                {t.enter_mobile_number}
              </Form.Label>
              <Field
                type="text"
                name="mobileNumber"
                className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''
                  }`}
                //placeholder={t.enter_mobile_number}
                value={values.mobileNumber}
                //onKeyPress={handleKeyPressMobileNumber}
                onInput={handleKeyPressMobileNumber}
                onChange={handleChange}
                style={{ border: '1px solid #ccc' }}
              />
              {errors.mobileNumber && (
                <div className="error_message">{errors.mobileNumber}</div>
              )}
            </Form.Group>
            {errors.Message && (
              <div className="error_message error_red">{errors.Message}</div>
            )}
            <div className="d-flex justify-content-between mb-4">
              <Button variant="primary" type="submit" block className='loginbtn'
                onClick={() => {
                  CounterFloodLight('DC-12665261/thums0/thums01r+unique');
                  TwitterPixel('tw-ofua3-ofvc1');
                }}
              >
                {t.continue}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MobileNumberForm;
