import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders'
import { useEffect, useRef } from 'react'

const ModelViewer = () => {
  const canvasRef = useRef(null)
  const engineRef = useRef(null)
  const sceneRef = useRef(null)
  const cameraRef = useRef(null)
  const animationGroupRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current

    // Create a Babylon.js engine and a scene
    const engine = new BABYLON.Engine(canvas, true)
    const scene = new BABYLON.Scene(engine)

    // Store engine, scene, and camera references
    engineRef.current = engine
    sceneRef.current = scene

    // Load the glTF model
    BABYLON.SceneLoader.Append('assets/images/TU_Coin_Animation_4.gltf', '', scene, (scene) => {
      // Model loaded callback

      // Set up camera and lighting
      const camera = new BABYLON.ArcRotateCamera('camera', 0, 0, 10, BABYLON.Vector3.Zero(), scene)
      camera.attachControl(canvas, true)
      const light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene)

      // Configure animation to run once
      scene.animationGroups.forEach((animationGroup) => {
        animationGroupRef.current = animationGroup
        animationGroup.stop()
      })

      // Set background color to transparent
      scene.clearColor = new BABYLON.Color4(0, 0, 0, 0)

      // Run the render loop
      engine.runRenderLoop(() => {
        scene.render()
      })

      // Play the animation once it is loaded
      const animationGroup = animationGroupRef.current
      if (animationGroup) {
        animationGroup.play()
      }
    })

    // Handle window resize
    const resizeHandler = () => {
      engine.resize()
    }
    window.addEventListener('resize', resizeHandler)

    // Clean up on component unmount
    return () => {
      engine.stopRenderLoop()
      window.removeEventListener('resize', resizeHandler)
      scene.dispose()
      engine.dispose()
    }
  }, [])

  return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
}

export default ModelViewer