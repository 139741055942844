import React, { Fragment, useState, useEffect, useContext } from 'react';
import Map from '../components/Map';
import { getVotesForWorldCup } from '../actions/Auth';
import { LoaderContext } from './loader/LoaderContext';
import Meter from './common/Meter';
import { useLocation } from 'react-router-dom';
import { getUserDetails } from '../utils/helper';

const BelievoMeter = (props) => {

  let user_details = getUserDetails()
  const { t } = props;
  const [voteDetails, setVotesDetails] = useState([]);
  const { toggleLoader } = useContext(LoaderContext);
  const { pathname } = useLocation();

  useEffect(() => {
    getVoteDetails();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [pathname]);

  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getVoteDetails = async () => {
    let reqData = {
      Id: user_details ?  user_details.Id : ''
    }
    try {
      toggleLoader();
      const response = await getVotesForWorldCup(reqData);
      let data = response && response.data && response.data.Data;
      console.log('response***', data);
      setVotesDetails(data);
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  console.log('voteDetails', voteDetails);

  return (
    <Fragment>
      <main>
        <Meter voteDetails={voteDetails} t={t} />
        {/* <Map voteDetails={voteDetails} t={t} /> */}
        <Map voteDetails={voteDetails && voteDetails.Top10CityVotes} t={t} />
      </main>
    </Fragment>
  );
};

export default BelievoMeter;
