/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import LanguageContext from '../../utils/LanguageContext';
import {
  TossNowFlag,
  getWalletDetailsById,
  checkmatchClaimedToday,
  saveMerchWinner
} from '../../../src/actions/Auth';
import { LoaderContext } from '../loader/LoaderContext';
import InfoModal from '../model/InfoModal'; // Import the pop-up component
import Button from 'react-bootstrap/Button';
import translations from '../../utils/translation';
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  TwitterPixel,
  getUserDetails, handleShare, getErrorMessage
} from '../../utils/helper';
import ClaimTicket from '../model/ClaimTicket';
import ModelViewer from './CoinAnimation';
import MyWallet from '../common/MyWallet';
//import MyWallet from '../common/NewMyWallet';
import CommonModel from '../model/CommonModel';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import CommonContext from '../contextApi';
import { claimMerchSuccess, redeemErrorModel, claimfailError } from '../../utils/modelHelper';


function TossNow(props) {

  const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
  const { showSection = false, tossNowEvent = false, closeTossNow } = props;
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [walletDetails, setWalletDetails] = useState('');
  const [showClaimTicket, setShowClaimTicket] = useState(false);
  const [showMywallet, setShowMywallet] = useState(false);
  const [popup, setPopupInfo] = useState({
    title: '',
    content: '',
  }); // State to manage the pop-up properties
  const { language } = useContext(LanguageContext, LoaderContext);
  const { toggleLoader } = useContext(LoaderContext);
  const t = translations[language];
  let user_details = getUserDetails();

  console.log('tossNowEvent', tossNowEvent)

  useEffect(() => {
    if (tossNowEvent) {
      handleTossNow()
    } else if (actionType && actionType.isLoggedIn) {
      if (actionType.type === 'toss_now') {
        handleTossNow()
      }
    }
    //plz uncomment
    // tossfail()
    // userWinMerchModel()
    //matchCoinCollectionComplete()
    // redeemMerchModel()
    //tossCompleted(0)
    // tossfail()
    //toofaniWinMerchaindise()
    //tossAnimation()
    // getWalletDetails(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tossNowEvent, actionType]);

  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getWalletDetails = async (countCoins) => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      toggleLoader();
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setWalletDetails(data);
        let matchCoins = data && data.MatchCoin && data.MatchCoin.Coins;
        console.log('matchCoins', matchCoins, data.MerchClaimed)
        if (matchCoins >= 5) {
          //ones  complete 5 match coins horray model with open 
          if (data.MerchClaimed < 2) {
            matchCoinCollectionComplete(data);
          } else {
            tossCompleted(countCoins);
          }
        } else {
          // ones user wins 0, 1 or 2 coins
          tossCompleted(countCoins);
        }
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  //save merch winner api call
  const saveMerchWinnerAPI = async () => {
    let requestData = {
      UserId: user_details && user_details.Id,
      Coin: 0,
      IsMerchUsingVictoryCoin: false
    }
    try {
      const response = await saveMerchWinner(requestData);
      if (response.status === 200) {

      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  }

  /**
   * @method checkClaimedOrNot
   * @description check if user cliamed or not
   */
  const checkClaimedOrNot = async (matchCoins) => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      toggleLoader();
      const response = await checkmatchClaimedToday(requestData);
      if (response.status === 200) {
        // daily cota is not over open claim form 
        setShowClaimTicket(true)
        // save merch winner 
        saveMerchWinnerAPI()
      }
    } catch (error) {
      //dailt cota is over message will show 
      let errorRes = error.response && error.response.data && error.response.data
      console.error(error, 'error');
      if (errorRes.ErrorCode === 709) {
        redeemMerchModel();
      } else {
        let message = getErrorMessage(errorRes.Message)
        apiErrorModel(message)
      }
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  // handle all type of api error
  const apiErrorModel = (message) => {
    setPopupInfo({
      title: (<h2>{''}</h2>),
      content: (<h4>{message}<br /></h4>),
      image: '',
      className: 'incorrect-noball no_ball',
    });
  }

  /**
   * @method matchCoinCollectionComplete
   * @description when daily quata is over and user is not redeemed 2 merch
   */
  const matchCoinCollectionComplete = (data) => {
    let notableToRedeemMerch = data && (data.Merch === 2)
    console.log('merch', data && (data.Merch === 2))
    // setPopupInfo(fiveMatchCoinCollected(t,closePopup, checkClaimedOrNot,notableToRedeemMerch));
    setPopupInfo({
      topHeading: <h2>{`${t.hooray}!`}</h2>,
      title: (
        <div>
          <h3 className="mb-0">{t.coin_collection}</h3>
          <h3>{`${t.hurray}!`}</h3>
        </div>
      ),
      image: <img src={'assets/images/5-match-coins.png'} alt={'ticket'} />,
      button: (
        <Button
          variant="danger"
          className="center-button"
          disabled={notableToRedeemMerch}
          onClick={() => {
            setPopupInfo({
              title: '', // Reset the title
              content: '', // Reset the content
            });
            // check fdaily cota is over or not 
            checkClaimedOrNot()
            // call api for match coin save
          }}>
          {`${t.redeem_merch}`}
        </Button>
      ),
      className: 'redeem-success',
    });
  };

  /**
   * @method redeemMerchModel
   * @description daily quata is over model
   */
  const redeemMerchModel = () => {
    setPopupInfo(redeemErrorModel(t))
  };

  /**
   * Handle the completed toss event
   * @param {number} countCoins - Number of coins won
   */
  const tossCompleted = (countCoins) => {
    let title = '', content = '', heading = '';
    if (countCoins === 0) {
      title = t.duck;
      content = t.retry_toss;
      heading = t.won_0_match_coin
    } else if (countCoins === 1) {
      title = t.coin_board;
      content = t.keep_winning;
      heading = t.won_1_match_coin
    } else if (countCoins === 2) {
      title = t.ek_se_bhle_do;
      content = t.keep_winning;
      heading = t.won_2_match_coin
    }
    setPopupInfo({
      title: <h3>{title}</h3>,
      button: <h4>{content}</h4>,
      image:
        countCoins !== '' ? (
          <img src={'assets/images/thumps-up-coin.png'} alt={'img'} />
        ) : (
          <ModelViewer />
        ),
      bgImage: 'assets/images/model-background-error.png',
      content: countCoins !== '' ? (
        <div>
          <h4>{heading}</h4>
          <Button variant="danger" className="center-button"
            onClick={() => {
              setShowMywallet(true)
              setPopupInfo({
                title: '', // Reset the title
                content: '', // Reset the content
              });
              MetaPixel('see_collection');
              TwitterPixel('tw-ofua3-ofvci');
            }}>
            {/* You have won {countCoins} Match {countCoins > 1 ? 'Coins' : 'Coin'} */}
            {t.see_collection}
          </Button>
        </div>
        // <Button variant="danger" className="center-button" >
        // You have won {countCoins} Match {countCoins > 1 ? 'Coins' : 'Coin'}
        // </Button>
      ) : (
        <div></div>
      ),
      className: 'toss_now_default',
      topHeading: <h2>{t.daily_toss}</h2>,
    });
  };

  //Show toss fail model
  const tossfail = () => {
    setPopupInfo({
      title: <h2>{t.daily_toss}</h2>,
      content: (
        <div>
          <h3>{t.taken_toss}</h3>
          <h4>{t.retry}</h4>
        </div>
      ),
      image: '',
      // bgImage: 'assets/images/model-background-error.png',
      className: 'vote_now_default',
    });
  };

  //Show toss now model
  const handleTossNow = async () => {
    if (!user_details) {
      setShowLoginModal(true);
      triggerActionAfterLogin({ type: 'toss_now', isLoggedIn: false })
    } else {
      const requestData = {
        Id: user_details && user_details.Id,
      };
      tossCompleted('');
      setTimeout(async () => {
        try {
          const response = await TossNowFlag(requestData);
          let countCoins = response?.data?.Data;
          getWalletDetails(countCoins);
          ClickButtonCDP('Daily_Toss_Web');
          window.dataLayer.push({ event: 'tu_tossnow' })
          // Show the result or perform any other actions here
        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 409) {
            tossfail();
          }
        }
      }, 3000);
    }
  };

  //Show toss closePopup model
  const closePopup = () => {
    setPopupInfo({
      title: '', // Reset the title
      content: '', // Reset the content
    });
    closeTossNow()
    triggerActionAfterLogin('')
  };

  // render toofani merch success model after claim merch 
  const toofaniWinMerchaindise = () => {
    setPopupInfo(claimMerchSuccess(t, language));
  };

  // render toss now button 
  const renderTossNowButton = () => {
    return (
      <a
        href={void 0}
        className="btn btn-sm btn-primary tossnowbtn"
        onClick={() => {
          handleTossNow();
          CounterFloodLight('DC-12665261/thums0/thums01w+unique');
          MetaPixel('DailyToss');
          TTDPixel('kn254ql');
          TwitterPixel('tw-ofua3-ofvco');
        }}>
        {t.toss_now}
      </a>
    )
  }

  // render toss now section of home page
  const renderTossNowSection = () => {
    return (
      <div className="tu-dailytoss-section mx-n4" id={'toss_now'}>
        <img
          className="subtract subtract-bottom"
          src={'assets/images/subtract-bottom.png'}
          alt="subtract-top img"
        />
        <div className="tu-worldcup-section-wrapper">
          <img
            className="vector vector-left d-none d-md-block"
            src={'assets/images/player-left-img.png'}
            alt="cup icon"
          />
          <div className="tu-worldcup-section">
            <img
              className="vector vector-right"
              src={'assets/images/toss-image.png'}
              alt="player"
            />
            <div className="tu-content-wrapper">
              <h2 className="section-title mb-1">{t.daily_toss}</h2>
              <h2 className="section-desc">
                {t.kro_boss} <br />
                {t.win_daily_coins}
              </h2>
              {renderTossNowButton()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
 * @method claimfailError
 * @description model will open if you claim all the tickets
 */
  const claimFail = (content1, content2) => {
    setPopupInfo(claimfailError(content1, content2));
  };


  return (
    <>
      {showSection ? renderTossNowSection() : ''}
      {popup.title && (
        <InfoModal
          title={popup.title} // Set the title for the pop-up
          content={popup.content} // Set the content for the pop-up
          onClose={closePopup} // Close the pop-up
          button={popup.button}
          image={popup.image}
          // bgImage={popup.bgImage}
          className={popup.className}
          topHeading={popup.topHeading}
          style={popup.style}
        />
      )}
      {showClaimTicket && (
        <ClaimTicket
          source={'merch'}
          show={showClaimTicket}
          handleClose={() => setShowClaimTicket(false)}
          t={t}
          toofaniWinMerchaindise={toofaniWinMerchaindise}
          redeemMerchModel={redeemMerchModel}
          claimFail={claimFail}
          handleCross={() => {
            setShowClaimTicket(false)
          }}
        />
      )}
      {
        <MyWallet
          t={t}
          setShowLoginModal={false}
          show={showMywallet}
          handleClose={() => setShowMywallet(false)}
          getWalletDetails={getWalletDetails}
          walletDetails={walletDetails}
        />
      }
      {showLoginModal && <CommonModel
        t={t}
        title={t.worldCup2023TicketKeyunique}
        header={t.find_code}
        handleShowLoginModal={() => setShowLoginModal(true)}
        showLoginModal={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
        active={2}
      />}
    </>
  );
}

export default TossNow;
