import React, { useContext, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { 
    CounterFloodLight, 
    MetaPixel, 
    TTDPixel, 
    TwitterPixel, 
    getUserDetails } from '../../utils/helper';
import CommonContext from '../contextApi';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { currentYear } from '../../utils/helper';


const MerchaindiseModel = ({ show, onClose, setShowLoginModal, ReferFriendModelOpen, handleTossNow }) => {

    let user_details = getUserDetails()
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const { triggerAction, triggerActionAfterLogin, actionType } = useContext(CommonContext);

    useEffect(() => {
        if (actionType && actionType.isLoggedIn) {
          if (actionType.type === 'toss_now') {
            onClose()
          }
        }
      }, [actionType]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleReferModel = () => {
        if (user_details) {
            ReferFriendModelOpen()
        } else {
            setShowLoginModal()
            triggerActionAfterLogin({ type: 'refer', isLoggedIn: false })
        }
    }

    return (
        <Modal size='lg' className={`tu-common-modal collect-coins silver-coins`} show={show} onHide={onClose} centered>
            <Modal.Header className="text-center">
                <img
                    className="cross-button"
                    src="assets/images/close.png"
                    alt="Close"
                    onClick={onClose}
                    style={{ 'cursor': 'pointer' }}
                />
            </Modal.Header>
            <Modal.Body className="text-center">
                <img className="img-fluid thumps-up" src={'assets/images/thump-up-5-coin.png'} alt={'thumps-up'} />
                <h2 className='mt-5'>{t.collect_five_match_coins}<br />
                    {t.reddem_for_exclusive}</h2>
                <Row className='justify-content-center'>
                    <Col md={10}>
                        <Row className='match-coin-btns'>
                            <Col lg={4} xs={12} className='mt-2'>

                                <Button variant="danger" className="btn-sm" onClick={() => {
                                    onClose()
                                    handleTossNow()
                                    triggerActionAfterLogin({ type: 'toss_now', isLoggedIn: false })
                                    //navigate('/#toss_now')
                                    CounterFloodLight('DC-12665261/thums0/thums02i+unique');
                                    MetaPixel('TossNow');
                                    TTDPixel('uoq2tmu');
                                    TwitterPixel('tw-ofua3-ofvbb');
                                }}>
                                    {t.daily_toss}
                                </Button>
                                <h5 className='display-3 mt-2'>{t.daily_toss_coin}</h5>
                            </Col>
                            <Col lg={4} xs={6} className='mt-2'>
                                <Button variant="danger" className="btn-sm"
                                    onClick={() => {
                                        CounterFloodLight('DC-12665261/thums0/thums021+unique');
                                        onClose()
                                        handleReferModel()
                                    }}
                                >{t.refer_friend}</Button>
                                <h5 className='display-3 mt-2 px-5'>{t.refer_a_friend_coin}</h5>
                            </Col>
                            <Col lg={4} xs={6} className='mt-2'>
                                <Button variant="danger" className="btn-sm"
                                    onClick={() => {
                                        CounterFloodLight('DC-12665261/thums0/thums02h+unique');
                                        onClose()
                                        if (user_details) {
                                            triggerAction(true)
                                        } else {
                                            setShowLoginModal()
                                            triggerActionAfterLogin({ type: 'profile', isLoggedIn: false })
                                        }
                                        TTDPixel('54wnwud');
                                        TwitterPixel('tw-ofua3-ofvb9');
                                    }}
                                >{t.complete_profile}</Button>
                                <h5 className='display-3 mt-2'>{t.daily_voting_coin}</h5>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <h6 className='mt-2 mb-0 display-1'>{t.redeem_pieces}</h6>
                <p className='mt-1 last-date'>{t.last_date_merch}</p>
            </Modal.Body>
        </Modal>
    );
};

export default MerchaindiseModel;
