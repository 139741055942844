import React, { useState, useContext, useEffect } from "react";
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import TicketModel from '../model/TicketModel';
import MerchaindiseModel from '../model/MerchaindiseModel';
import ReferFriendModel from '../model/ReferFriendModel'
import {
    CounterFloodLight,
    MetaPixel,
    TTDPixel,
    TwitterPixel,
    getUserDetails
} from "../../utils/helper";
import CommonModel from "../model/CommonModel";
import InfoModal from "../model/InfoModal";
import { currentYear } from '../../utils/helper'
import { ClickButtonCDP } from "../../utils/cdsHelper";
import TossNow from "./TossNow";
import CommonContext from '../contextApi';
import { referFriendSuccess } from "../../utils/modelHelper";
import MyWallet from '../common/MyWallet';

const HowToWin = () => {

    const { language } = useContext(LanguageContext);
    const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
    const t = translations[language];
    const [ticketModel, setTicketModel] = useState(false)
    const [merchaindiseModel, setmerchaindiseModel] = useState(false)
    const [openRefermodel, setOpenReferModel] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [tossNow, setTossNow] = useState(false)
    let user_details = getUserDetails();
    const [popup, setPopupInfo] = useState({
        title: "",
        content: "",
    }); // State to manage the pop-up properties
    const [showUniqueCodeModel, setShowUniqueCodeModel] = useState(false);
    const [activeStep, setActiveStep] = useState({ step: 1, type: '' })
    const [showMywallet, setShowMywallet] = useState(false)


    useEffect(() => {
        if (actionType && actionType.isLoggedIn) {
            if (actionType.type === 'refer' || actionType.type === 'profile') {
                setmerchaindiseModel(false)
            } else if (actionType.type === 'unique_code') {
                setTicketModel(false)
                setShowUniqueCodeModel(true)
            } else if (actionType.type === 'vote_now') {
                setTicketModel(false)
            }
        }
    }, [actionType])

    /**
     * @method referFriendSuccussModel
     * @description daily quata is over model
     */
    const referFriendSuccussModel = (code) => {
        setPopupInfo(referFriendSuccess(t, code));
    };

    //Show toss closePopup model
    const closePopup = () => {
        setPopupInfo({
            title: "", // Reset the title
            content: "", // Reset the content
        });
    };

    const handleTossNow = () => {
        if (user_details) {
            setTossNow(true)
        } else {
            setActiveStep({ step: 2, type: 'merch' })
            setShowUniqueCodeModel(true)
        }
    }

    return (
        <section className="section position-relative bg bg-pattern-1" id={'how_to_win'}>
            <header className="section-header">
                <h2 className="section-title section-title-guj">
                    {t.how_to_win}*
                </h2>
            </header>

            <div className="container-fluid pb-3 pb-md-10">
                <div className="row justify-content-center">
                    {/* Card first start here  */}
                    <div className="col-6 col-lg-5 col-xl-4">
                        <div className="card-block contest-ticket">
                            <img
                                className="card-block-img-top"
                                //src="/assets/images/tickets.png"
                                src="assets/images/coin-border.png"
                                alt={'card-img'}
                            />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                                href={void (0)}
                                onClick={() => {
                                    setTicketModel(true);
                                    ClickButtonCDP('HowToWin_Tickets');
                                    CounterFloodLight('DC-12665261/thums0/thums01x+unique');
                                    MetaPixel('HowToWinTicket');
                                    TTDPixel('fcvijdb');
                                    TwitterPixel('tw-ofua3-ofvc4');
                                }}
                                className={`btn btn-primary contest-btn contest-btn-${language} contest-btn-left`}
                                dangerouslySetInnerHTML={{
                                    __html: t.icic_mens_world_cup
                                }}
                            ></a>
                        </div>
                    </div>
                    <div id={'how_to_win'}></div>
                    {/* Card secound start here  */}
                    <div className="col-6 col-lg-5 col-xl-4">
                        <div className="card-block">
                            <div>
                                <img
                                    className="card-block-img-top"
                                    src="/assets/images/cap-win.png"
                                    alt={'card-img'}
                                />
                            </div>
                            <div>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href={void (0)}
                                    onClick={() => {
                                        setmerchaindiseModel(true);
                                        ClickButtonCDP('HowToWin_Merch');
                                        CounterFloodLight('DC-12665261/thums0/thums01y+unique');
                                        MetaPixel('HowToWinMerch');
                                        MetaPixel('ExclusiveMerchandise');
                                        TTDPixel('nvbscid');
                                        TwitterPixel('tw-ofua3-ofvc3');
                                    }}
                                    className={`btn btn-primary card-block-how-to-win-merch-section contest-btn contest-btn-${language}`}
                                >
                                    <span style={{ color: 'transparent' }}>Exc</span> {t.exclusive_merchaindise} <span style={{ color: 'transparent' }}>Exc</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <img className="dots-block dots-block-left"
                src="/assets/images/horizontalDot.svg"
                alt="horizontal"
            />
            <img className="dots-block dots-block-right"
                src="/assets/images/verticalDot.svg"
                alt="vertical"
            />
            {ticketModel && <TicketModel
                t={t}
                show={ticketModel}
                onClose={() => setTicketModel(false)}
                showUniqueCodeModel={() => setShowUniqueCodeModel(true)}
                setShowLoginModal={() => {
                    setActiveStep({ step: 2, type: 'ticket' })
                    setShowUniqueCodeModel(true)
                }}
            />}
            {tossNow && <TossNow tossNowEvent={tossNow} closeTossNow={() => setTossNow(false)} />}
            {merchaindiseModel && <MerchaindiseModel
                t={t}
                show={merchaindiseModel}
                onClose={() => {
                    setmerchaindiseModel(false)
                    triggerActionAfterLogin('')
                }}
                setShowLoginModal={() => {
                    setActiveStep({ step: 2, type: 'merch' })
                    setShowUniqueCodeModel(true)
                }}
                ReferFriendModelOpen={() => setOpenReferModel(true)}
                handleTossNow={handleTossNow}
            />}
            {openRefermodel &&
                <ReferFriendModel
                    show={openRefermodel}
                    handleClose={() => {
                        setOpenReferModel(false)
                        triggerActionAfterLogin('')
                    }}
                    t={t}
                    referFriendSuccussModel={(code) => referFriendSuccussModel(code)}
                />}
            {!user_details && showLoginModal && (
                <CommonModel
                    t={t}
                    title={''}
                    header={''}
                    handleShowLoginModal={() => setShowLoginModal(true)}
                    showLoginModal={showLoginModal}
                    handleClose={() => {
                        setShowLoginModal(false)
                        triggerActionAfterLogin('')
                    }}
                    active={2}
                />)}
            {popup.title && (
                <InfoModal
                    title={popup.title} // Set the title for the pop-up
                    content={popup.content} // Set the content for the pop-up
                    onClose={closePopup} // Close the pop-up
                    button={popup.button}
                    image={popup.image}
                    className={popup.className}
                    topHeading={popup.topHeading}
                />
            )}
            {showUniqueCodeModel && (
                <CommonModel
                    t={t}
                    title={t.worldCup2023TicketKeyunique}
                    header={t.find_code}
                    handleShowLoginModal={() => setShowUniqueCodeModel(true)}
                    showLoginModal={showUniqueCodeModel}
                    handleClose={(value) => {
                        if (value === 'my_wallet') {
                            setShowMywallet(true)
                        }
                        if (activeStep.type === 'merch') {
                            setmerchaindiseModel(true)
                        } else if (activeStep.type === 'ticket') {
                            setTicketModel(true)
                        }
                        setShowUniqueCodeModel(false)
                        triggerActionAfterLogin('')
                    }}
                    active={activeStep.step}
                    seperateUniqueCodeModel={true}
                />)}
            {<MyWallet
                t={t}
                setShowLoginModal={showMywallet}
                show={showMywallet}
                handleClose={() => setShowMywallet(false)}
            />}
        </section>
    );
}

export default HowToWin