/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useContext } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import UniqueCodeForm from '../form/UniqueCodeForm';
import MobileNumberForm from '../form/MobileNumberForm';
import OtpVerificationForm from '../form/OtpVerificationForm';
import SignUpForm from '../form/SignUpForm';
import Cookies from 'js-cookie';
import LanguageContext from '../../utils/LanguageContext';
import VictoryCoinModelJourney from '../common/VictoryCoinModelJourney';
import { getIpDetails } from '../../utils/helper'
import { RICTRICTED_STATE, ELIGABLE_COUNTRY } from '../../config/constant'
import InfoModal from './InfoModal';
import { languageList } from '../../config/constant'
import MyWallet from '../common/MyWallet';


const CommonModel = (props) => {
  const {
    t,
    title,
    header,
    showLoginModal,
    handleClose,
    active,
    seperateUniqueCodeModel = false,
  } = props;
  const ipData = getIpDetails()
  console.log('ipData', ipData)
  // risticted to login tamil nadu all regions
  const ristrictToRedirect = ipData && ipData.regionName === RICTRICTED_STATE ? true : false
  const isCountrynotIndia = ipData && ipData.country !== ELIGABLE_COUNTRY
  const { changeLanguage } = useContext(LanguageContext);
  const cookieValue = Cookies.get('language');
  const currentLanguage = cookieValue ? cookieValue : 'en';
  console.log('currentLanguage', currentLanguage);
  const [language, setlanguage] = useState(currentLanguage)
  const [show, setShow] = useState(showLoginModal);
  const [mobileNumber, setMobileNumber] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [activeStep, setActiveStep] = useState(active);
  const [uniqueCodeData, setUniqueCodeData] = useState('');
  const [modelContent, setModelContent] = useState({
    title: title, // Specify the title for the model content
    header: header, // Specify the header for the model content
    image: '', // Specify the image for the model content
  });

  const handleLanguageChange = (value) => {
    const newLanguage = value
    console.log(show, 'newLanguage', newLanguage)
    changeLanguage(newLanguage);
    setlanguage(newLanguage)
    Cookies.set('language', newLanguage, { expires: 7 })
  };

  //call immidiatly before render the componnnent, open model
  useEffect(() => {
    setShow(showLoginModal);
  }, [showLoginModal]);

  //handle next step
  const nextStep = ({ mobile, uniqueCode }) => {
    console.log('uniqueCode', uniqueCode);
    setActiveStep((prevStep) => prevStep + 1);
    setMobileNumber(mobile);
    setUniqueCode(uniqueCode);
  };

  //handle previous step
  const previousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  //handle model dynamic content change
  const handleModelContent = (content) => {
    setModelContent(content);
  };

  /**
   * Get the background and side image URLs based on the active step
   * @returns {Object} - Object containing the background and side image URLs
   */
  const getBackgroundImage = () => {
    let backgroundImageUrl = 'assets/images/model-background-1.png';
    let sideImageUrl = 'assets/images/modal-left-img.png';
    if (activeStep === 1) {
      sideImageUrl = 'assets/images/unique-code-animation-desktop.gif';
      //sideImageUrl = 'assets/images/thumps-up-bottle1.png';
    } else if (activeStep === 2) {
      backgroundImageUrl = 'assets/images/model-background-2.png';
      sideImageUrl = 'assets/images/modal-left-img.png';
    } else if (activeStep === 3) {
      backgroundImageUrl = 'assets/images/model-background-3.png';
      sideImageUrl = 'assets/images/modal-left-img.png';
    } else if (activeStep === 4) {
      backgroundImageUrl = 'assets/images/model-background-5.png';
      sideImageUrl = 'assets/images/modal-left-img.png';
    }
    return {
      bgImage: backgroundImageUrl,
      sideImage: sideImageUrl,
      // mSideImageUrl
    };
  };

  if (uniqueCodeData) {
    console.log('$$$$uniqueCodeData', uniqueCodeData);
    return (
      <div>
        <VictoryCoinModelJourney
          data={uniqueCodeData}
          t={t}
          handleSuccesModelClose={() => {
            setUniqueCodeData('');
            handleClose();
          }}
          setShowMywalletEvent={() => {
            setUniqueCodeData('')
            handleClose('my_wallet')
          }}
          showClaimForm={() => setUniqueCode('')}
        />
      </div>
    );
  }

  if (ristrictToRedirect && showLoginModal && active === 2) {
    return (
      <>
        <InfoModal
          title={<h2>{''}</h2>}
          content={<h4>{t.promotion_not_applicable}</h4>}
          onClose={handleClose}
          button={''}
          image={''}
          className={'incorrect-noball no_ball'}
          topHeading={''}
        />
      </>
    )
  }

  if (isCountrynotIndia && showLoginModal && active === 2) {
    return (
      <>
        <InfoModal
          title={<h2>{''}</h2>}
          content={<h4>{t.promotion_not_applicable_to_other_country}</h4>}
          onClose={handleClose}
          button={''}
          image={''}
          className={'incorrect-noball no_ball'}
          topHeading={''}
        />
      </>
    )
  }

  return (
    <>
      <Modal
        size="md"
        className={`tu-common-modal modal-bg${activeStep}`}
        show={showLoginModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          {' '}
          <img
            className="cross-button"
            src="assets/images/close.png"
            alt="Close"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3} sm={4} className="text-center text-sm-left">
              <img
                className="modal-left-img"
                src={`${getBackgroundImage().sideImage}`}
                alt="modal image"
              />
            </Col>
            <Col xs={9} sm={7} className="text-center text-sm-left">
              <div className="modal-right-section">
                <Modal.Title>{modelContent && modelContent.title}</Modal.Title>
                <div className="modal-sub-title">{modelContent && modelContent.header}</div>
                {activeStep === 1 && (
                  <UniqueCodeForm
                    nextStep={(activeStep) => nextStep(activeStep)}
                    setModelContent={(content) => handleModelContent(content)}
                    t={t}
                    style={{ minHeight: '576px' }}
                    className="unique-code-form-class" // Custom class name for UniqueCodeForm
                    seperateUniqueCodeModel={seperateUniqueCodeModel}
                    handleClose={() => {
                      handleClose();
                      setUniqueCodeData('');
                    }} //handle close common model
                    getApiResponse={(data) => {
                      setUniqueCodeData(data);
                    }}
                    language={language}
                  />
                )}
                {activeStep === 2 && (
                  <MobileNumberForm
                    nextStep={(activeStep) => nextStep(activeStep)}
                    setModelContent={(content) => handleModelContent(content)}
                    t={t}
                    style={{ minHeight: '430px' }}
                    className="m-number"
                  />
                )}
                {activeStep === 3 && (
                  <OtpVerificationForm
                    nextStep={(activeStep) => nextStep(activeStep)}
                    previousStep={(activeStep) => previousStep(activeStep)}
                    setModelContent={(content) => handleModelContent(content)}
                    mobileNumber={mobileNumber}
                    t={t}
                    uniqueCode={uniqueCode}
                    redeemUniqueCode={(userInfo) => redeemUniqueCode(userInfo)}
                    handleClose={handleClose}
                  />
                )}
                {activeStep === 4 && (
                  <SignUpForm
                    nextStep={(activeStep) => nextStep(activeStep)}
                    setModelContent={(content) => handleModelContent(content)}
                    mobileNumber={mobileNumber}
                    t={t}
                    handleClose={handleClose}
                    uniqueCode={uniqueCode}
                    redeemUniqueCode={(userInfo) => redeemUniqueCode(userInfo)}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer className="flex-column">
          {activeStep === 1 &&
            <>
              <h4 className="display-2 mb-2">{t.choose_preferred_language}</h4>
              <ul className="list list-horizontal">
                {languageList.map((el, i) => {
                  let selected = el.language === language ? 'selected' : ''
                  console.log(language, 'selected', selected)
                  return (
                    <li className={`list-item ${selected}`} key={i} onClick={() => handleLanguageChange(el.value)}>
                      <a href={void (0)} className='list-link'>{el.label}</a>
                    </li>
                  )
                })}
              </ul>
            </>
          }
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default CommonModel;
