/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { verifyReferCode, getWalletDetailsById, checkmatchClaimedToday } from '../../actions/Auth';
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  getUserDetails, handleShare, handleErrorMessages, TwitterPixel, getErrorMessage
} from '../../utils/helper';
import { LoaderContext } from '../loader/LoaderContext';
import InfoModal from '../model/InfoModal';
import MyWallet from '../common/MyWallet';
//import MyWallet from '../common/NewMyWallet';
import Loader from '../loader';
import ClaimTicket from '../model/ClaimTicket';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import { claimMerchSuccess, redeemErrorModel, oneMatchCoinWin, claimfailError } from '../../utils/modelHelper';

const InviteCodeModel = ({ t, handleClose, show }) => {
  let user_details = getUserDetails();
  const [showWallet, setShowMyWallet] = useState(false);
  const [showClaimTicket, setShowClaimTicket] = useState(false);
  const [walletDetails, setWalletDetails] = useState('');
  const { toggleLoader } = useContext(LoaderContext);
  const [popup, setPopupInfo] = useState({
    title: '',
    content: '',
  });

  // schema for field level validation
  const schema = Yup.object().shape({
    refCode: Yup.string()
      .required(t.validation_messages.required)
      .min(6, `${t.validation_messages.min_length} 6 ${t.digits}`)
      .max(6, `${t.validation_messages.max_length} 6 ${t.digits}`),
  });

  useEffect(() => {
    //winOnematchCoinModel()
  }, []);

  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getWalletDetails = async (countCoins) => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      toggleLoader();
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setWalletDetails(data);
        let matchCoins =
          data && data.MatchCoin && data.MatchCoin.Coins;
        if (matchCoins >= 5 && data.MerchClaimed !== 2) {
          //ones  complete 5 match coins horray model with open 
          matchCoinCollectionComplete();
        } else {
          winOnematchCoinModel()
        }
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * @method checkClaimedOrNot
   * @description check if user cliamed or not
   */
  const checkClaimedOrNot = async () => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      toggleLoader();
      const response = await checkmatchClaimedToday(requestData);
      if (response.status === 200) {
        // daily cota is not over open claim form 
        setShowClaimTicket(true)
      }
    } catch (error) {
      //dailt cota is over message will show 
      let errorRes = error.response && error.response.data && error.response.data
      console.error(error, 'error');
      if (errorRes.ErrorCode === 709) {
        redeemMerchModel();
      } else {
        let message = getErrorMessage(errorRes.Message)
        apiErrorModel(message)
      }
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  // handle all type of api error
  const apiErrorModel = (message) => {
    setPopupInfo({
      title: (<h2>{''}</h2>),
      content: (<h4>{message}<br /></h4>),
      image: '',
      className: 'incorrect-noball no_ball',
    });
  }

  /**
   * @method matchCoinCollectionComplete
   * @description when daily quata is over and user is not redeemed 2 merch
   */
  const matchCoinCollectionComplete = () => {
    let notableToRedeemMerch = walletDetails && (walletDetails.Merch === 2)
    setPopupInfo(fiveMatchCoinCollected(t, closePopup, checkClaimedOrNot, notableToRedeemMerch));
  };

  //
  const winOnematchCoinModel = () => {
    setPopupInfo(oneMatchCoinWin(t, setShowMyWallet, closePopup))
  };

  /**
   * @method redeemMerchModel
   * @description daily quata is over model
   */
  const redeemMerchModel = () => {
    setPopupInfo(redeemErrorModel(t))
  };

  const toofaniWinMerchaindise = () => {
    setPopupInfo(claimMerchSuccess(t, language));
  };

  const closePopup = () => {
    setPopupInfo({
      title: '', // Reset the title
      content: '', // Reset the content
    });
  };


  /**
   * @method handleSubmit
   * @description Handles the form submission for sending mobileNumber and proceeding to the next step
   */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    console.log('values', values);
    try {
      toggleLoader();
      const requestData = {
        Id: user_details && user_details.Id,
        refCode: values.refCode,
      };
      CounterFloodLight('DC-12665261/thums0/thums024+unique');
      MetaPixel('InviteCodeSubmit');
      TTDPixel('c9cgff3');
      TwitterPixel('tw-ofua3-ofvc5');
      const response = await verifyReferCode(requestData);
      if (response && response.status === 200) {
        handleClose();
        //winOnematchCoinModel();
        getWalletDetails();
        ClickButtonCDP('Use_Invite_Code');
        // window.google_tag_manager[process.env.REACT_APP_G_GTM_ID].dataLayer.push({
        //   event: 'tuhaveainvitecodesubmitbtn'
        // });
        window.dataLayer.push({ event: 'tuhaveainvitecodesubmitbtn' })
      }
    } catch (error) {
      console.log('Error:', error);
      handleErrorMessages(error, setFieldError)
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * @method renderReferCodeForm
   * @description render refer code form fields
   */
  const renderReferCodeForm = () => {
    return (
      <Formik
        validationSchema={schema}
        initialValues={{
          refCode: '',
        }}
        onSubmit={handleSubmit}
        //validateOnChange={false}
        validateOnBlur={false}>
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            {console.log('errors', errors)}
            <Form.Group controlId="name">
              <Field
                type="text"
                name="refCode"
                maxLength={6}
                className={`form-control ${errors.refCode ? 'is-invalid' : ''}`}
                value={values.refCode}
                onChange={handleChange}
              />
              {errors.refCode && (
                <div className="error_message">{errors.refCode}</div>
              )}
            </Form.Group>
            {errors.Message && (
              <div className="error_message invite-red">{errors.Message}</div>
            )}
            <div className="d-flex justify-content-between mb-5">
              <Button className="mb-3" variant="primary" type="submit" block>
                {t.submit}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  /**
 * @method claimfailError
 * @description model will open if you claim all the tickets
 */
  const claimFail = (content1, content2) => {
    setPopupInfo(claimfailError(content1, content2));
  };


  return (
    <>
      <Loader />
      {show && (
        <Modal
          size="md"
          className="tu-common-modal modal-invite"
          show={show}
          onHide={handleClose}
          centered>
          <div className="modal-bg">
            <Modal.Header>
              <img
                className="cross-button"
                src="assets/images/close.png"
                alt="Close"
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              />
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={4}>
                  <img
                    className="modal-left-img"
                    src={'assets/images/modal-left-img.png'}
                    alt="modal image"
                  />
                </Col>
                <Col sm={7}>
                  <div className="modal-right-section">
                    <Modal.Title>{t.enter_invite}</Modal.Title>
                    <div className="modal-sub-title mb-5">
                      {t.enter_friend_6digit_code}
                    </div>
                    {renderReferCodeForm()}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </div>
        </Modal>
      )}
      {
        <MyWallet
          t={t}
          show={showWallet}
          handleClose={() => setShowMyWallet(false)}
        />
      }
      {popup.title && (
        <InfoModal
          title={popup.title} // Set the title for the pop-up
          content={popup.content} // Set the content for the pop-up
          onClose={closePopup} // Close the pop-up
          button={popup.button}
          image={popup.image}
          className={popup.className}
          topHeading={popup.topHeading}
        />
      )}
      {showClaimTicket && (
        <ClaimTicket
          source={'merch'}
          show={showClaimTicket}
          handleClose={() => setShowClaimTicket(false)}
          t={t}
          toofaniWinMerchaindise={toofaniWinMerchaindise}
          redeemMerchModel={redeemMerchModel}
          claimFail={claimFail}
          handleCross={() => {
            setShowClaimTicket(false)
          }}
        />
      )}
    </>
  );
};

export default InviteCodeModel;
