import { createContext, useState } from 'react';

const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeRequests, setActiveRequests] = useState(0);

  const toggleLoader = () => {
    setIsLoading(prevLoading => !prevLoading);
  };

  const incrementRequests = () => {
    setActiveRequests(prevRequests => prevRequests + 1);
        
  };

  const decrementRequests = () => {
    setActiveRequests(prevRequests => prevRequests - 1);
    if (activeRequests <= 1) {
      setIsLoading(false);
    }
  };

  const value = {
    isLoading,
    toggleLoader,
    incrementRequests,
    decrementRequests
  };

  return (
    <LoaderContext.Provider value={value}>
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderProvider, LoaderContext };
