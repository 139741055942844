import { useEffect, useRef, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ percentage, text, animationDuration }) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const animationRef = useRef();
  const animationStartTime = useRef();

  //show circular animation
  useEffect(() => {
    const animateProgress = (timestamp) => {
      if (!animationStartTime.current) {
        animationStartTime.current = timestamp;
      }
      const progressTime = timestamp - animationStartTime.current;
      const progress = Math.min(progressTime / (animationDuration * 100), 1);
      const updatedPercentage = progress * percentage;
      setCurrentPercentage(updatedPercentage);
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateProgress);
      }
    };
    animationRef.current = requestAnimationFrame(animateProgress);
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [percentage, animationDuration]);

  return (
    <div className="my-progress-bar">
      <CircularProgressbar
        value={(currentPercentage)}
        text={text}
        styles={{
          path: {
            stroke: "red",
          },
          text: {
            fill: "#fff",
          },
          root: {
            width: "100px",
            height: "100px",
            marginLeft: "10px",
          },
        }}
      />
    </div>
  );
};

export default ProgressBar;
