import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ data }) => {
  const [autoplay, setAutoplay] = useState(false);
  console.log('data', data)

  useEffect(() => {
    // Check if autoplay is allowed
    const isAutoplayAllowed =
      'mediaSession' in navigator || // Chrome and Edge
      (typeof document !== 'undefined' &&
        'documentMode' in document) || // IE
      (typeof document !== 'undefined' &&
        'MozAppearance' in document.documentElement.style); // Firefox

    if (isAutoplayAllowed) {
      setAutoplay(true);
    }
  }, []);

  const config = {
    file: {
      forceVideo: true,
    },
  };

  return (
    <div>
      {data && <ReactPlayer
        url={data.vedioUrl} // Replace with your video URL
        controls={true}
        width="100%"
        height="100%"
        playing={autoplay}
        preload="auto"
        config={config}
        playsinline={true}
        type='video/mp4'
      />}
      {/* {data && <video
        controls
        width="100%"
        height="100%"
        autoPlay={autoplay}
      >
        <source src={data.vedioUrl} type="video/mp4" />
      </video>} */}
    </div>
  );
};

export default VideoPlayer;