/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
  CounterFloodLight, 
  MetaPixel, 
  TTDPixel, 
  TwitterPixel, 
  getUserDetails } from '../../utils/helper'
import ReferFriendModel from '../model/ReferFriendModel'
import InviteCodeModel from '../model/InviteCodeModel'
import MyWallet from './MyWallet';
//import MyWallet from './NewMyWallet';
import InfoModal from "../model/InfoModal"; // Import the pop-up component
import CommonContext from '../contextApi';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import { referFriendSuccess } from '../../utils/modelHelper';

function Feature({ t, setShowLoginModal, navigateTo }) {

  let user_details = getUserDetails()
  const { triggerActionAfterLogin, actionType,triggerAction,redirectname } = useContext(CommonContext);
  const [openRefermodel, setOpenReferModel] = useState(false)
  const [openinvitemodel, setOpenInviteModel] = useState(false)
  const [openMywallet, setOpenMyWalletModel] = useState(false)
  const [popup, setPopupInfo] = useState({
    title: "",
    content: "",
  }); // State to manage the pop-up properties
  console.log(user_details, 'fffffffffffffffff')

  useEffect(() => {
    //referFriendSuccussModel()
    if (actionType && actionType.isLoggedIn) {
      if (actionType.type === 'invite') {
        setOpenInviteModel(true)
      } else if (actionType.type === 'refer' || actionType.type === 'refer_a_friend') {
        setOpenReferModel(true)
      }else if (actionType.type === 'my_wallet') {
        setOpenMyWalletModel(true)
      }else if (actionType.type === 'toofani_wins') {
        navigateTo('/how_to_win#toofani_champions')
      }else if(actionType.type === 'profile'){
        triggerAction(true)
      }else if (actionType.type === 'vote_now') {
        navigateTo('/#vote_now')
      }else if (actionType.type === 'hotstar_playlist') {
        navigateTo('/#hotstar_playlist')
      }else if(actionType.type === 'invite_code'){
        setOpenInviteModel(true);
      }
    }
    if(user_details && redirectname && redirectname.show){
      if (redirectname.url_name === 'invite_code' ) {
        setOpenInviteModel(true);
      }else if (redirectname.url_name === 'refer_a_friend' ) {
        setOpenReferModel(true)
      }
    }
  }, [actionType,redirectname])// eslint-disable-line react-hooks/exhaustive-deps

  //show refer code model
  const handleReferModel = () => {
    if (user_details) {
      setOpenReferModel(true)
      navigateTo()
    } else {
      setShowLoginModal()
      navigateTo()
      triggerActionAfterLogin({ type: 'refer', isLoggedIn: false })
    }
  }

  //show invite code model
  const handleInviteCodeModel = () => {
    if (user_details) {
      setOpenInviteModel(true)
      navigateTo()
    } else {
      setShowLoginModal()
      navigateTo()
      triggerActionAfterLogin({ type: 'invite', isLoggedIn: false })
    }
  }

  const handleMyWalletModel = () => {
    if (user_details) {
      setOpenMyWalletModel(true)
      navigateTo()
    } else {
      setShowLoginModal()
      navigateTo()
      triggerActionAfterLogin({ type: 'my_wallet', isLoggedIn: false })
    }
  }

  const handleToofaniChampion = () => {
    if (user_details) {
      navigateTo();
    }
    else {
      setShowLoginModal();
      triggerActionAfterLogin({ type: 'toofani_wins', isLoggedIn: false })
    }
  }

  /**
  * @method referFriendSuccussModel
  * @description friend refer success
  */
  const referFriendSuccussModel = (code) => {
    setPopupInfo(referFriendSuccess(t,code));
  };

  //Show toss closePopup model
  const closePopup = () => {
    setPopupInfo({
      title: "", // Reset the title
      content: "", // Reset the content
    });
  };

  return (
    <div className='tu-sidebar-list-main'>
      <div className='tu-sidebar-list-main-scroll'>
      <ul>
        <li>
          <span className="tu-side-icon"><img src={('assets/images/home.svg')} alt="home icon" /></span>
          <Link to='/' onClick={() => navigateTo()}>{t.home}</Link>
        </li>
        <li>
          <span className="tu-side-icon"><img src={('assets/images/win.svg')} alt="win icon" /></span>
          <Link to='/how_to_win#how_to_win' onClick={() => {
            navigateTo();
            CounterFloodLight('DC-12665261/thums0/thums01_+unique');
            MetaPixel('HowToWin');
            TTDPixel('cjmzd50');
            TwitterPixel('tw-ofua3-ofvc2');
          }} >{t.how_to_win}</Link>
        </li>
        <li>
          <span className="tu-side-icon"><img src={('assets/images/my-wins.svg')} alt="win icon" /></span>
          <a href={void (0)} onClick={() =>{ 
            handleMyWalletModel();
            CounterFloodLight('DC-12665261/thums0/thums02+unique');
            MetaPixel('MyWins');
            TTDPixel('lk1wzhm');
            TwitterPixel('tw-ofua3-ofvc9');
          }}>{t.my_wins}</a>
        </li>
        <li>
          <span className="tu-side-icon"><img src={('assets/images/leader.svg')} alt="leader icon" /></span>
          <Link to={user_details ? '/how_to_win#toofani_champions' : '/'} onClick={() => {
            TTDPixel('hhh03n6');
            handleToofaniChampion(); 
            CounterFloodLight('DC-12665261/thums0/thums020+unique');
            MetaPixel('ToofaniWins');
          }}>
            {t.toofani_champions}
          </Link>
        </li>
        <li>
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              <span className="tu-side-icon"><img src={('assets/images/refer.svg')} alt="refer icon" /></span>
              <a 
                href={void (0)}  
                onClick={() => {
                  TTDPixel('l4j9csd');
                  handleReferModel();
                  CounterFloodLight('DC-12665261/thums0/thums021+unique');
                  MetaPixel('ReferAFriend');
                  TwitterPixel('tw-ofua3-ofvce');
                }} 
                className='referfriendbtn'
              >
                {t.refer_a_friend}
              </a>
            </div>
            <div className='d-flex pr-3'>
              {user_details && user_details.RefCode && <a style={{ textDecoration: "underline" }}>{user_details && user_details.RefCode}</a>}
              {user_details && user_details.RefCode &&
                <img src={('assets/images/share.svg')}
                  alt=""
                  style={{ paddingLeft: "10px", fill: "white" }}
                  onClick={() =>
                    handleReferModel()
                  }
                />}
            </div>
          </div>
        </li>
        <li>
          <span className="tu-side-icon"><img src={('assets/images/play.svg')} style={{maxWidth: "2.5rem"}} alt="leader icon" /></span>
          <Link to={'/#hotstar_playlist'}>
            Thums up Fanpulse
          </Link>
        </li>
        <li className='last-list-item list-bottom-border'>
          <span className="tu-side-icon"><img src={('assets/images/invite.svg')} alt="invite icon" style={{width:'25px'}}/></span>
          <a href={void (0)} onClick={() => {
            TTDPixel('j2zvt8z');
            handleInviteCodeModel();
            CounterFloodLight('DC-12665261/thums0/thums022+unique');
            MetaPixel('InviteCode');
            TwitterPixel('tw-ofua3-ofvc5');
          }}>
            {t.have_an_invite_code} <span>{t.enter_one_match_coin}</span>
          </a>
        </li>
      </ul>
      {
        <ReferFriendModel
          show={openRefermodel}
          handleClose={() => {
            setOpenReferModel(false)
            triggerActionAfterLogin('')
          }}
          t={t}
          referFriendSuccussModel={(code) => referFriendSuccussModel(code)}
        />}
      {
        <InviteCodeModel
          show={openinvitemodel}
          handleClose={() => {
            setOpenInviteModel(false)
            triggerActionAfterLogin('')
          }}
          t={t}
        />}
      {<MyWallet
        t={t}
        setShowLoginModal={setShowLoginModal}
        show={openMywallet}
        handleClose={() => {
          setOpenMyWalletModel(false)
          triggerActionAfterLogin('')
        }}
      />}
      {popup.title && (
        <InfoModal
          title={popup.title} // Set the title for the pop-up
          content={popup.content} // Set the content for the pop-up
          onClose={closePopup} // Close the pop-up
          button={popup.button}
          image={popup.image}
          className={popup.className}
          topHeading={popup.topHeading}
        />
      )}

      </div>
    </div>
  );
}

export default Feature;
