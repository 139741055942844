import React, { Fragment, useContext, useEffect, useRef } from 'react';
import {
  MapContainer,
  ImageOverlay,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/sass/map.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { roundedValue } from '../utils/helper';
import LanguageContext from '../utils/LanguageContext';
import translations from '../utils/translation';
import LineChart from "../components/LineChart";

const customMarker = new L.Icon({
  iconUrl: 'assets/images/marker.png', // URL of the marker image
  iconSize: [18, 18], // Size of the marker icon
  iconAnchor: [0, 20], // Anchor point of the icon relative to its top-left corner
  popupAnchor: [1, -31], // Anchor point of the popup relative to the marker's anchor
  // You can also define a shadow for the marker using shadowUrl, shadowSize, and shadowAnchor
});

// // Data for the markers on the map
const myData = [
  {
    id: 3,
    name: 'Haryana Ka Pulse',
    pulse: '90%',
    stopId: 'STOPID-TA21 9AD~1536',
    coordinates: [29.0588, 76.0856],
  },
  {
    id: 4,
    name: 'Bihar Ka Pulse',
    pulse: '100%',
    stopId: 'STOPID-EX4 8HH~1537',
    coordinates: [25.0961, 85.3131],
  },
  {
    id: 6,
    name: 'Maharashtra Ka Pulse',
    pulse: '100%',
    stopId: 'STOPID-TQ6 9LA~1539',
    coordinates: [19.601194, 75.552979],
  },
  {
    id: 7,
    stopId: 'STOPID-DE65 6BG~1542',
    name: 'Gujarat Ka Pulse',
    pulse: '100%',
    coordinates: [22.309425, 72.13623],
  },
  // ... Other data objects
];

const MapComponent = ({ voteDetails }) => {
  const indiaBounds = L.latLngBounds(
    L.latLng(8, 68.111378), // Southwest coordinates of India
    L.latLng(35.504475, 97.395555) // Northeast coordinates of India
  );

  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const mapRef = useRef(null);
  const initialZoom = 4.5;
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
      map.invalidateSize();
      // Additional code to handle map resizing or other operations
    }
  }, []);
  return (
    <Fragment>
      {/* <h4 className="mapSection-title">
          {t.toofani_vote}
          <br />
          {t.eco_with_nation}
        </h4> */}
      <MapContainer
        center={[22.5, 82]}
        zoom={initialZoom}
        ref={mapRef}
        // className="scrollable-map"
        scrollWheelZoom={false} // Disable zooming via scroll wheel
        doubleClickZoom={false} // Disable zooming via double-click
        touchZoom={false} // Disable zooming via touch gestures
        zoomControl={false} // Disable the default zoom control
        zoomAnimation={false}
        dragging={false} // Disable dragging the map
        attributionControl={false}>
        {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}
        {/* Add an image overlay to the map */}
        <ImageOverlay
          url={`assets/images/map_${language}.png`} // URL of the map image
          bounds={indiaBounds} // Bounds of the map image
          interactive={false} // Disable interaction with the overlay
          zIndex={0} // Set the z-index of the overlay
        />
        {voteDetails &&
          voteDetails.length !== 0 &&
          voteDetails.map((stop) => (
            <Marker
              key={stop.id}
              position={[stop.Latitude, stop.Longitude]}
              icon={customMarker}>
              <Popup>
                <div className="map-popup">
                  <div className="popup-pulse">
                    <h3>{roundedValue(stop.Percentage)}%</h3>
                  </div>
                  <div className="popup-title">
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: `${stop.City} ${t.believes_india_will_win}`,
                      }}></h3>
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
      </MapContainer>
      {/* <h4 className="mapSection-title">
          {t.toofani_vote}
          <br />
          {t.eco_with_nation}
        </h4> */}
    </Fragment>
  );
};

export default function world_cup_ticket({ voteDetails }) {
  return (
    <div className="mapSection">
      <Container fluid>
        <div className="d-flex justify-content-center position-relative">
          <div className="mapSection-left">
            <MapComponent voteDetails={voteDetails} />
          </div>
          {/* <div className="mapSection-right">
          <LineChart />
          </div> */}
        </div>
      </Container>
    </div>
  );
}
