import React from 'react';
import ProgressBar from './CircularProgressBar';
import { Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { roundedValue, getUserDetails } from '../../utils/helper';


const Meter = ({ voteDetails, t }) => {
  let user_details = getUserDetails()
  
  let top_city = voteDetails && voteDetails.Top10CityVotes
  //let top_city = voteDetails
  
  let my_city = voteDetails && voteDetails.MyCityVotes
  console.log('%%voteDetails', top_city);

  const dummy = [
    {
      Percentage: 40,
      City: 'Delhi',
    },
    {
      Percentage: 70,
      City: 'Mumbai',
    },
    {
      Percentage: 30,
      City: 'Pune',
    },
    {
      Percentage: 20,
      City: 'Indore',
    },
    {
      Percentage: 70,
      City: 'Kerla',
    },
    {
      Percentage: 10,
      City: 'Goa',
    },
    {
      Percentage: 60,
      City: 'Bhopal',
    },
    {
      Percentage: 50,
      City: 'Mohali',
    },
  ];
  const tooltipStyles = {
    fontSize: '20px', // Increase the font size
    maxWidth: '300px',
    padding: '21px',
    backgroundColor: 'transparent',
    // Increase the maximum width
    // Add any other custom styles you want
  };


  const renderProgressBar = (el) => {
    return (
      <div className="progress-bar-wrapper">
        <ProgressBar animationDuration={2} percentage={el.Percentage} />
        <span className="text-center d-block progress-bar-text">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={tooltipStyles} id={`tooltip-${el.City}`}>
                {el.City}
              </Tooltip>
            }>
            <span className="truncate">
              {el.City.length > 10
                ? `${el.City.slice(0, 8)}...`
                : el.City}
            </span>
          </OverlayTrigger>
          <span className="d-block">{t.ka_pulse}</span>{' '}
          {roundedValue(Number(el.Percentage))}%
        </span>
      </div>
    )
  }

  let final_city = top_city

  if(my_city){
    final_city = top_city && top_city.filter(el => el.City !== my_city.City)
  }
  

  console.log('final_city',final_city,my_city)

  return (
    <section className="section believo-meter-section pt-0">
      <div className="meter-wrapper text-center">
        {/* <h2 className="meter-title">{t.desh_ka}</h2>
        <h1 className="meter-heading">{t.toofaani_pulse}</h1> */}
        <img src={'assets/images/fanpulse.png'} alt={'toofani pulse'} className='fanpulse' />
        {/* <h4 className="meter-sub-heading">
          {t.check_out_which_city_india_win_world_cup}
        </h4> */}
      </div>
      {/* <div className="meter-container"> */}
      <ul
        className={`d-flex meter-list ${final_city && final_city.length >= 3
          ? 'justify-content-start justify-content-xl-center'
          : 'justify-content-center'
          }`}>
        {/* {dummy && dummy.length && dummy.map((el, i) => ( */}
        {/* my city */}
        {user_details && my_city && <div className='city text-center'>
          <span className='city-text'>{t.my_city}</span>
         <li> {renderProgressBar(my_city)}</li>
        </div>}
        {final_city &&
          final_city.length !== 0 &&
          final_city.map((el, i) => (
            <li key={i}>
               <span className='city-text'>{'My City'}</span>
              {renderProgressBar(el)}
            </li>
          ))}
      </ul>
      {/* </div> */}
    </section>
  );
};

export default Meter;
