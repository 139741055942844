

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CounterFloodLight, TTDPixel, TwitterPixel } from "../utils/helper";
import { ClickButtonCDP } from "../utils/cdsHelper";
import { handleShare,extractHrefFromHTML } from "../utils/helper";
import { terms_condition } from "../config/constant";


export const claimMerchSuccess = (t, language) => {
    const winInfo = {
        topHeading: (
            <div>
                <h2>{t.thank_you}</h2>
                <h3 className="mb-0 display-2 p-0">{t.toofani_win_heading}</h3><br />
                <h3 className="display-3">{t.share_toofani_win}</h3>
            </div>
        ),
        title: <></>,
        image: <img src={`assets/images/merchandise-wins_${language}.png`} alt={"ticket"} />,
        button: (
            <div>
                <Button
                    variant="danger"
                    className="center-button"
                    onClick={() => {
                        CounterFloodLight('DC-12665261/thums0/thums02q+unique');
                        ClickButtonCDP('Share_MerchWin');
                        handleShare(
                            `I just won the exclusive ICC Men's Cricket World Cup 2023 Merchandise! You can too! Just grab a bottle of Thums Up® and get playing. Visit https://bit.ly/44D7RrZ *TnC Apply`,
                            `I just won the exclusive ICC Men's Cricket World Cup 2023 Merchandise! You can too! Just grab a bottle of Thums Up® and get playing. Visit https://bit.ly/44D7RrZ *TnC Apply`,
                            `assets/images/merchandise-wins_${language}.png`,
                            ''
                        )
                    }}
                >
                    {t.share}
                </Button>
            </div>
        ),
        className: "win-ticket thanks-win",
    };

    return winInfo;
}

/**
   * @method claimTicketSuccess
   * @description claim ticket success model
   */
export const claimTicketSuccess = (t, language) => {
    const winInfo = {
        topHeading: (
            <div>
                <h2>{t.thank_you}</h2>
                <h3 className="mb-0 display-2 mx-auto p-md-0">{t.ticket_deliver_within_xyz}</h3><br />
                <h3 className="display-3">{t.share_toofani_win}</h3>
            </div>
        ),
        title: <></>,
        image: <img src={`assets/images/toofani-win-tickets_${language}.svg`} alt={"ticket"} />,
        button: (
            <div>
                <Button
                    variant="danger"
                    className="center-button"
                    onClick={() => {
                        CounterFloodLight('DC-12665261/thums0/thums02q+unique');
                        ClickButtonCDP('Share_TicketWin');
                        handleShare(
                            `I just won the Exclusive Thums Up® ICC 2023 commemorative silver coin! You can win too! Just grab a bottle of Thums Up® and get playing. Visit https://bit.ly/44D7RrZ *TnC Apply`,
                            `I just won the Exclusive Thums Up® ICC 2023 commemorative silver coin! You can win too! Just grab a bottle of Thums Up® and get playing. Visit https://bit.ly/44D7RrZ *TnC Apply`,
                            `assets/images/toofani-win-tickets_${language}.png`,
                            ''
                        );
                    }}
                >
                    {`${t.share}`}
                </Button>
            </div>
        ),
        className: "win-ticket thanks-win",
    }
    return winInfo
};


/**
 * @method redeemMerchModel
 * @description model will show ones user win the reddem merch
 */
export const redeemErrorModel = (t) => {
    const info = {
        title: <h2>{t.close}</h2>,
        content: (
            <div>
                <h3 className="h4 px-5 px-sm-3">{t.merch_toofani_player}</h3>
                <h4>{t.redeeming_tomorrow}</h4>
            </div>
        ),
        image: "",
        className: "no_ball redeem-merch",
    }

    return info
};

/**
  * @method merchClaimError
  * @description you have claimed all the merch 2/2
  */
export const merchClaimError = (t) => {
    const info = {
        title: <h2>{t.close}</h2>,
        content: (
            <div>
                <h3 className="h4 px-5 px-sm-3">{"You have claimed all the merch"}</h3>
                <h4>{t.redeeming_tomorrow}</h4>
            </div>
        ),
        image: "",
        className: "no_ball redeem-merch",
    }
    return info
};

/**
 * @method referFriendSuccess
 * @description refer friend success
 */
export const referFriendSuccess = (t, code) => {
    const info = {
        title: <h2>{t.thank_you}</h2>,
        content: (
            <div>
                <h3>{t.invite_sent_to_friend}</h3>
                <label className="btn btn-light">{code}</label>
                <h3 className="h4 px-5 px-sm-3">{t.get_one_match_coin}</h3>
            </div>
        ),
        image: '',
        className: 'no_ball redeem-merch thank-redeem',
    }

    return info
};


// for time being adding to open once a referal is completed to open coin win bcz its code is not separetee, mixed with invite model, we can scrape it once code is separated
export const oneMatchCoinWin = (t, setShowMyWallet, closePopup) => {
    const info = {
        title: <h2>{t.skipper}</h2>,
        content: <h3>{t.won_coins1}</h3>,
        image: <img src={'assets/images/thumps-up-coin.png'} alt={'thumps-up'} />,
        button: (
            <Button
                variant="danger"
                className="center-button"
                onClick={() => {
                    TTDPixel('056jm5v');
                    setShowMyWallet(true);
                    closePopup();
                    TwitterPixel('tw-ofua3-ofvc7');
                }}>
                {`${t.my_wallet}`}
            </Button>
        ),
        className: 'profile-success',
    }
    return info
};

/**
   * @method fiveMatchCoinCollected
   * @description match coin collection complete model
   */
export const fiveMatchCoinCollected = (t, closePopup, checkClaimedOrNot, notableToRedeemMerch = false) => {
    const info = {
        topHeading: <h2>{`${t.hooray}!`}</h2>,
        title: (
            <div>
                <h3 className="mb-0">{t.coin_collection}</h3>
                <h3>{`${t.hurray}!`}</h3>
            </div>
        ),
        image: <img src={"assets/images/5-match-coins.png"} alt={"ticket"} />,
        button: (
            <Button
                variant="danger"
                className="center-button"
                disabled={notableToRedeemMerch}
                onClick={() => {
                    closePopup();
                    checkClaimedOrNot()
                }}
            >
                {`${t.redeem_merch}`}
            </Button>
        ),
        className: "redeem-success",
    }
    return info
};

/**
   * Display the profile completion popup
   */
export const profileCompletedSuccess = (t, setShowMyWallet, closePopup) => {
    const info = {
        title: <h2>{t.skipper}</h2>,
        content: <h3>{t.won_coins1}</h3>,
        image: <img src={"assets/images/thumps-up-coin.png"} alt={"thumps-up"} />,
        button: (
            <Button
                variant="danger"
                className="center-button"
                onClick={() => {
                    TTDPixel('056jm5v');
                    setShowMyWallet(true);
                    closePopup();
                    TwitterPixel('tw-ofua3-ofvc7');
                }}
            >
                {`${t.my_wallet}`}
            </Button>
        ),
        className: "profile-success",
    }
    return info
};

/**
  * @method claimfailError
  * @description claim fail model will open 
  */
export const claimfailError = (content1, content2) => {
    const info = {
        title: <h2>{content1}</h2>,
        content: (
            <div>
                <h3 className="h4 px-5 px-sm-3">{content2}</h3>
                {/* <h4>{t.redeeming_tomorrow}</h4> */}
            </div>
        ),
        image: "",
        className: "no_ball redeem-merch",
    }
    return info
};

/**
  * @method promotionInfoModel
  * @description show this model ones user lands on home page first time 
  */
export const promotionInfoModel = (t) => {
    const info = {
        title: <h3 className="h4 px-5 px-sm-3">{t.hi_user}</h3>,
        content: (
            <div>
                <h3 className="h4 px-5 px-sm-3">{t.promotion_info}</h3><br/>
            </div>
        ),
        button: (
            <div className="mt-4 privacy">
                <Link to={terms_condition} target={'_blank'}><h3 className="h4 px-5 px-sm-3"  style={{color:'white', textDecoration: 'none'}}>
                    {/* {t.t_c} */}
                    T&C
                </h3></Link>
            </div>
        ),
        image: "",
        className: "no_ball redeem-merch",
    }
    return info
};

/**
  * @method captureImageSuccess
  * @description capture image success model
  */
export const captureImageSuccess = (t) => {
    const info = {
        title: <h3 className="h4 px-5 px-sm-3">{t.hey_toofan}</h3>,
        content: (
            <div>
                <h3 className="h4 px-5 px-sm-3">{t.capture_submit_success}</h3>,
                <h3 
                    className="h4 px-5 px-sm-3"
                    dangerouslySetInnerHTML={{
                        __html: t.capture_link,
                    }}
                ></h3><br/>
            </div>
        ),
        image: "",
        className: "no_ball redeem-merch",
    }
    return info
};

