import React from "react";
import HeroBannerContest from "./common/HeroBannerContest";
import HowToWin from "./common/howToWin";
import WinnerTable from "./common/WinnerTable";
import NewWinnerTable from "./common/NewWinnerTable";


const Contest = () => {

    return (
        <>
            <HeroBannerContest/>
            <HowToWin/>
            {/* <WinnerTable/> */}
            <NewWinnerTable/>
        </>
    );
}

export default Contest