import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Modal,
  Row,
  Button,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import LanguageContext from "../../utils/LanguageContext";
import translations from "../../utils/translation";
import InfoModal from "../model/InfoModal";
import ClaimTicket from "../model/ClaimTicket";
import {
  getUserDetails,
  ableToclaim,
  ableToRedeemMerch,
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  TwitterPixel,
} from "../../utils/helper";
import { checkmatchClaimedToday, saveMerchWinner } from "../../actions/Auth";
import { LoaderContext } from "../loader/LoaderContext";
import { handleShare, getErrorMessage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import CommonModel from "../model/CommonModel";
import { getWalletDetailsById } from "../../actions/Auth";
import MerchaindiseModel from "../model/MerchaindiseModel";
import ReferFriendModel from '../model/ReferFriendModel';
import { ClickButtonCDP } from "../../utils/cdsHelper";
import TossNow from "./TossNow";
import CommonContext from '../contextApi';
import { claimMerchSuccess, claimTicketSuccess, redeemErrorModel, merchClaimError, referFriendSuccess, claimfailError } from "../../utils/modelHelper";

const MyWallet = ({ handleClose, show, setShowLoginModal }) => {

  const [showMywallet, setShowMywallet] = useState(false)
  const { triggerActionAfterLogin } = useContext(CommonContext);
  const navigate = useNavigate();
  let user_details = getUserDetails();
  const { toggleLoader } = useContext(LoaderContext);
  const [showClaimTicket, setShowClaimTicket] = useState(false);
  const [ticketModel, setTicketModel] = useState(false);
  const [howtoWinModel, setHowtoWinModel] = useState(false)
  const [showUniqueCodeModel, setShowUniqueCodeModel] = useState(false);
  const [openRefermodel, setOpenReferModel] = useState(false);
  const [tossNow, setTossNow] = useState(false)
  const [popup, setPopupInfo] = useState({
    title: "",
    content: "",
  });
  const [walletDetails, setWalletDetails] = useState("");
  const [coinType, setCoinType] = useState('');
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  let victoryCoin = walletDetails && walletDetails.VictoryCoinDetail;
  let matchCoins =
    walletDetails && walletDetails.MatchCoin && walletDetails.MatchCoin.Coins;


  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getWalletDetails = async () => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      toggleLoader();
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setWalletDetails(data);
      }
    } catch (error) {
      console.error("Error updating profile name:", error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };


  useEffect(() => {
    if (show) {
      getWalletDetails();
      setShowMywallet(show)
    }
    //redeemMerchModel()
    //toofaniWinMerchaindise()
    //toofaniWinTicket()  
  }, [show, showMywallet]);

  /**
   * @method saveMerchWinnerAPI
   * @description save merch after reddeming the merch
   */
  const saveMerchWinnerAPI = async (type) => {
    let requestData = {
      UserId: user_details && user_details.Id,
      Coin: coinType,
      IsMerchUsingVictoryCoin: type
    }
    try {
      const response = await saveMerchWinner(requestData);
      if (response.status === 200) {

      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  }

  /**
   * @method checkClaimedOrNot
   * @description check if user cliamed or not
   */
  const checkClaimedOrNot = async (type) => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      toggleLoader();
      const response = await checkmatchClaimedToday(requestData);
      if (response.status === 200) {
        if (type) {
          //for victory coin set 
          setTicketModel("merch");
          setShowClaimTicket(true)
          saveMerchWinnerAPI(type)
        } else if (matchCoins >= 5 && walletDetails.Merch < 2) {
          //for match coin set
          setTicketModel("merch");
          setShowClaimTicket(true)
          saveMerchWinnerAPI(type);
          ClickButtonCDP('Win_ICCMerch');
        } else {
          claimedAllMerch();
        }
      }
    } catch (error) {
      let errorRes = error.response && error.response.data && error.response.data
      console.error(error, 'error');
      if (errorRes.ErrorCode === 709) {
        redeemMerchModel();
      } else {
        let message = getErrorMessage(errorRes.Message)
        apiErrorModel(message)
      }
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  // handle all type of api error
  const apiErrorModel = (message) => {
    setPopupInfo({
      title: (<h2>{''}</h2>),
      content: (<h4>{message}<br /></h4>),
      image: '',
      className: 'incorrect-noball no_ball',
    });
  }


  /**
   * @method redeemMerchModel
   * @description model will show ones user win the reddem merch
   */
  const redeemMerchModel = () => {
    setPopupInfo(redeemErrorModel(t));
  };

  /**
   * @method claimedAllMerch
   * @description you have claimed all the merch 2/2
   */
  const claimedAllMerch = () => {
    setPopupInfo(merchClaimError(t));
  };

  // show toofani win merchaintise success model
  const toofaniWinMerchaindise = () => {
    setPopupInfo(claimMerchSuccess(t, language));
  };

  /**
   * @method toofaniWinTicket
   * @description toofani win ticket success model
   */
  const toofaniWinTicket = () => {
    setPopupInfo(claimTicketSuccess(t, language));
  };

  /**
  * @method referFriendSuccussModel
  * @description daily quata is over model
  */
  const referFriendSuccussModel = (code) => {
    setPopupInfo(referFriendSuccess(t, code));
  };

  /**
   * @method closePopup
   * @description close model
   */
  const closePopup = () => {
    setPopupInfo({
      title: "", // Reset the title
      content: "", // Reset the content
    });
  };

  const coinData = [
    {
      coin: victoryCoin && victoryCoin.Coin_1983,
      image: "assets/images/Coin-Mockup_1983.png",
      coin_type: "1983",
    },
    {
      coin: victoryCoin && victoryCoin.Coin_2007,
      image: "assets/images/Coin-Mockup_2007.png",
      coin_type: "2007",
    },
    {
      coin: victoryCoin && victoryCoin.Coin_2011,
      image: "assets/images/Coin-Mockup_2011.png",
      coin_type: "2011",
    },
    {
      coin: victoryCoin && victoryCoin.Coin_2013,
      image: "assets/images/Coin-Mockup_2013.png",
      coin_type: "2013",
    },
    {
      coin: victoryCoin && victoryCoin.Coin_2023,
      image: "assets/images/Coin-Mockup_2023.png",
      coin_type: "2023",
    },
  ];


  const renderCoins = ({ el }) => {
    if (!el) return null;
    return (
      <div className="clickable-btn">
        {/* <button class="btn-light" type="button">+</button> */}
        <label className="my-victory-coins-label">
          <img
            src={el.image}
            alt="coin"
            className={`img-victory ${el.coin >= 5 ? "glow" : el.coin > 0 ? "active" : ""
              }`}
          />
        </label>
        {/* <button class="btn-light" type="button">-</button> */}
      </div>
    );
  };

  // render my wins section
  const renderMyWins = () => {
    let userWinallVictoryCoin = !ableToclaim(victoryCoin)
    let ticketClaimCount = walletDetails && walletDetails && walletDetails.TicketClaimed
    let ticketCount = walletDetails && walletDetails && walletDetails.Ticket
    let silverCoinCount = walletDetails && walletDetails.Silver
    let silverClaimCount = walletDetails && walletDetails.SilverClaimed
    let merchCapActive = walletDetails && (walletDetails.Merch !== 0 && walletDetails.MerchClaimed !== 2)
    console.log(merchCapActive,'silverClaimCount')
    return (
      <div className="mb-5">
        <Modal.Title className="text-center mb-4">{`${t.my_wins}`}</Modal.Title>
        <div className="card">
          <div className="card-body">
            <Row className="justify-content-center">
              <Col sm={12} md={12}>
                <Row className="justify-content-center">
                  {/* silver coin logic added */}
                  <Col className="p-0">
                    <div className="block">
                      <div className={`claim-box-${((silverClaimCount !== 1) && silverCoinCount !== 0) ? 'active' : ''} claim-box text-center`}>
                        <img
                          src="assets/images/coin-border.png"
                          className="img-claim"
                          alt="coin"
                        />
                        <h6 className="count">
                          {walletDetails ? walletDetails.Silver : 0}/1
                        </h6>
                      </div>
                      <Button
                        className="btn btn-xs btn-primary claim-btn"
                        //disabled={!userWinallVictoryCoin && (ticketClaimCount === 2 || ticketCount === 0) || (ticketCount === ticketClaimCount && ticketClaimCount !== 0)}
                        disabled={(silverClaimCount > 1 || silverCoinCount === 0) || (silverCoinCount === silverClaimCount && silverClaimCount !== 0)}
                        onClick={() => {
                          TTDPixel('phqpm0k');
                          setShowClaimTicket(true);
                          setTicketModel("ticket");
                          handleMyWalletClose();
                          CounterFloodLight('DC-12665261/thums0/thums02f+unique');
                          MetaPixel('ClaimTicket');
                          TwitterPixel('tw-ofua3-ofvb8');
                        }}
                      >
                        {t.claim_now}
                      </Button>
                    </div>
                  </Col>
                  <Col className="p-0">
                    <div className="block">
                      <div className={`claim-box text-center`}>
                        <img
                          src="assets/images/tickets.png"
                          className="img-claim"
                          alt="coin"
                        />
                        <h6 className="count">
                          {walletDetails ? walletDetails.Ticket : 0}/2
                        </h6>
                      </div>
                      <Button
                        className="btn btn-xs btn-primary claim-btn"
                        //disabled={!userWinallVictoryCoin && (ticketClaimCount === 2 || ticketCount === 0) || (ticketCount === ticketClaimCount && ticketClaimCount !== 0)}
                        //disabled={(ticketClaimCount === 2 || ticketCount === 0) || (ticketCount === ticketClaimCount && ticketClaimCount !== 0)}
                        //always disable
                        disabled={true}
                        onClick={() => {
                          TTDPixel('phqpm0k');
                          setShowClaimTicket(true);
                          setTicketModel("ticket");
                          handleMyWalletClose();
                          CounterFloodLight('DC-12665261/thums0/thums02f+unique');
                          MetaPixel('ClaimTicket');
                          TwitterPixel('tw-ofua3-ofvb8');
                        }}
                      >
                        {t.claim_now}
                      </Button>
                    </div>
                  </Col>
                  <Col className="p-0">
                    <div className="block">
                      <div className={`claim-box text-center ${merchCapActive ? 'active' : ''}`}>
                        <img
                          src="assets/images/cap-win.png"
                          className="img-claim"
                          alt="coin"
                        />
                        <h6 className="count">
                          {walletDetails ? walletDetails.Merch : 0}/2
                        </h6>
                      </div>
                      {/* {when any one victory coin equal to 5 and  merch claim is less than 2 than enable the button} */}
                      <Button
                        className="btn btn-xs btn-primary claim-btn"
                        disabled={(walletDetails.MerchClaimed === 2 || walletDetails.MerchClaimed === walletDetails.Merch || walletDetails.Merch === 0)}
                        onClick={() => {
                          TTDPixel('fdu3zy8');
                          setShowClaimTicket(true);
                          setTicketModel("merch");
                          handleMyWalletClose();
                          CounterFloodLight('DC-12665261/thums0/thums02g+unique');
                          MetaPixel('ClaimMerch');
                          TwitterPixel('tw-ofua3-ofvb4');
                        }}
                      >
                        {t.claim_now}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  // render my wallet victory coin section
  const renderMyWallet = () => {
    return (
      <div className="my-5 pt-5">
        <Modal.Title className="text-center"> {`${t.wallet}`}</Modal.Title>
        <p className="modal-sub-title text-center">
          {t.find_collected_coins_here}
        </p>
        <p className="modal-sub-title text-center mt-2 mb-4 text-center">
          {t.my_victory_coins}
        </p>

        <div className="card">
          <div className="card-body">
            <div className="coins-cards">
              <Row>
                <Col xs={3} className="px-1 px-sm-auto block vote-border-inset-right">
                  <p className="text-center caption-title">{t.vote_to_collect}</p>
                  {renderCoins({ el: coinData[0] })}
                </Col>
                <Col xs={9}>
                  <p className="text-center caption-title">
                    {t.enter_unique_code_collect}
                  </p>
                  <Row>
                    {coinData.slice(1).map((el, i) => (
                      <Col key={i} className="px-1 px-sm-auto block">
                        {renderCoins({ el })}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <div className="border-inset-bottom py-2 mb-2 w-100"></div>
              <Row className="text-center">
                <Col
                  xs={3}
                  className="d-flex justify-content-center align-items-center"
                >
                  <h5 className="count d-flex">
                    {coinData?.[0].coin}
                    {coinData?.[0].coin >= 5 && (
                      <div className="checkbox-wrapper">
                        <pre className="ml-2">
                          <input
                            type="radio"
                            name="one"
                            value={coinData?.[0].coin_type}
                            onChange={(e) => setCoinType(e.target.value)}
                          />
                          <label></label>
                        </pre>
                      </div>
                    )}
                  </h5>
                </Col>
                <Col xs={9}>
                  <Row>
                    {coinData.slice(1).map((el, i) => (
                      <Col
                        key={i}
                        className="d-flex justify-content-center align-items-center px-1"
                      >
                        <h5 className="count d-flex">
                          {el.coin}
                          {el.coin >= 5 && (
                            <div className="checkbox-wrapper">
                              <pre className="ml-2">
                                <input
                                  type="radio"
                                  name="one"
                                  value={el.coin_type}
                                  onChange={(e) => setCoinType(e.target.value)}
                                />
                                <label></label>
                              </pre>
                            </div>
                          )}
                        </h5>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row className="text-center">
                <Col xs={3} className="text-center px-1 px-sm-auto">
                  <Button
                    className="btn btn-sm btn-primary mx-auto px-auto vote-now-btn"
                    onClick={() => {
                      CounterFloodLight('DC-12665261/thums0/thums02j+unique');
                      handleMyWalletClose();
                      navigate("/#vote_now");
                      TTDPixel('5d3q2ub');
                    }}
                  >
                    {t.vote_now}
                  </Button>
                </Col>
                <Col xs={9} className="text-center">
                  <Button
                    className="btn btn-sm btn-primary w-100 unique-code-btn"
                    onClick={() => {
                      setShowUniqueCodeModel(true);
                      handleMyWalletClose(); // handle close my wallet model
                    }}
                  >
                    {t.enter_unique_code}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //render redeem mmerch button section
  const renderRedeemButton = () => {
    return (
      ableToRedeemMerch(victoryCoin) && walletDetails && walletDetails.MerchClaimed !== 2 && walletDetails.Merch !== 2 && (
        <div className="my-5">
          <Row className="justify-content-center">
            <Col md={4} sm={6} xs={8}>
              {coinType === '' ? <OverlayTrigger
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                      <h6 className="tool-tip">{t.select_coin_for_merch}</h6>
                    </Popover.Content>
                  </Popover>
                }
              >
                {/* {merch claim is less than 2 than enable the button} */}
                <Button
                  className="btn btn-sm btn-primary mt-3 w-100"
                  onClick={() => {
                    MetaPixel('RedeemNow');
                    MetaPixel('RedeemMerch');
                    CounterFloodLight('DC-12665261/thums0/thums02m+unique');
                    TwitterPixel('tw-ofua3-ofvcd');
                  }}
                >
                  {t.redeem_merch}
                </Button>
              </OverlayTrigger> :
                <>
                  {/* <p>{t.redeem_merch_para}</p> */}
                  <div className="redeem-section">
                    <Button
                      className="btn btn-sm btn-primary mt-3 w-100"
                      onClick={() => {
                        MetaPixel('RedeemNow');
                        MetaPixel('RedeemMerch');
                        CounterFloodLight('DC-12665261/thums0/thums02m+unique');
                        TwitterPixel('tw-ofua3-ofvcd');
                        if (coinType) {
                          checkClaimedOrNot(true);
                          // call save match coin api if merch is not reddemed /claim
                          handleMyWalletClose();
                        }
                      }}
                    >
                      {t.redeem_merch}
                    </Button>
                    {/* <label for="text" className="merch-label mt-4">5</label> */}
                  </div>
                </>
              }
            </Col>
          </Row>
        </div>
      )
    );
  };

  // render match coins section
  const renderMatchCoins = () => {
    return (
      <div>
        <Row className="justify-content-center">
          <Col md={4} sm={6} xs={8}>
            <div className="modal-sub-title text-center mb-2">
              {t.my_match_coins}
            </div>
            <div className="card card-match-coins">
              <div className="card-body">
                <div className={`block ${matchCoins > 0 ? "active" : ""}`}>
                  <img src="assets/images/thumps-up-coin.png" alt="coin" />

                  <h5 className="mb-0 count border-inset-top w-100 text-center pt-3">
                    {matchCoins}
                  </h5>
                </div>
              </div>
            </div>
            <div className="text-center">
              {matchCoins < 5 && (
                <Button
                  className="btn btn-sm btn-primary mt-4 w-100"
                  onClick={() => {
                    CounterFloodLight('DC-12665261/thums0/thums01_+unique');
                    setHowtoWinModel(true)
                    handleMyWalletClose();
                  }}
                >
                  {t.how_to_win}
                </Button>
              )}
              {matchCoins >= 5 && (
                <Button
                  className="btn btn-sm btn-primary mt-4 w-100"
                  disabled={walletDetails && (walletDetails.Merch === 2)}
                  onClick={() => {
                    checkClaimedOrNot(false);
                    // call save match coin api if merch is not reddemed /claim
                    handleMyWalletClose();
                    CounterFloodLight('DC-12665261/thums0/thums02m+unique');
                    TwitterPixel('tw-ofua3-ofvcd');
                  }}
                >
                  {t.redeem_merch}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  // handle close my wallet model
  const handleMyWalletClose = () => {
    handleClose()
    setCoinType('')
    setShowMywallet(false)
  }

  const handleTossNow = () => {
    if (user_details) {
      setTossNow(true)
    } else {
      setShowLoginModal(true)
    }
  }

  /**
  * @method claim fail error model show
  * @description model will open if you claim all the tickets
  */
  const claimFail = (content1, content2) => {
    setPopupInfo(claimfailError(content1, content2));
  };


  return (
    <>
      {(show || showMywallet) && (
        <Modal
          size="lg"
          className="tu-common-modal modal-wallet"
          show={(show || showMywallet)}
          onHide={handleMyWalletClose}
          centered
        >
          <Modal.Header>
            <img
              className="cross-button"
              src="assets/images/close.png"
              alt="Close"
              onClick={handleMyWalletClose}
              style={{ cursor: "pointer" }}
            />
          </Modal.Header>
          <Modal.Body className="p-1 p-sm-5">
            {renderMyWins()}
            {renderMyWallet()}
            {renderRedeemButton()}
            {renderMatchCoins()}
          </Modal.Body>
        </Modal>
      )}
      {popup.title && (
        <InfoModal
          title={popup.title} // Set the title for the pop-up
          content={popup.content} // Set the content for the pop-up
          onClose={closePopup} // Close the pop-up
          button={popup.button}
          image={popup.image}
          // bgImage={popup.bgImage}
          className={popup.className}
          topHeading={popup.topHeading}
        />
      )}
      {showClaimTicket && (
        <ClaimTicket
          source={ticketModel}
          show={showClaimTicket}
          handleClose={() => {
            setShowClaimTicket(false)
            setCoinType('')
            setShowMywallet(false)
          }}
          t={t}
          toofaniWinMerchaindise={toofaniWinMerchaindise}
          toofaniWinTicket={toofaniWinTicket}
          redeemMerchModel={redeemMerchModel}
          claimFail={claimFail}
          coinType={coinType}
          handleCross={() => {
            setShowClaimTicket(false)
            setCoinType('')
            setShowMywallet(true)
            getWalletDetails()
          }}
          type={'my_wallet'}
        />
      )}
      {showUniqueCodeModel && (
        <CommonModel
          t={t}
          title={t.worldCup2023TicketKeyunique}
          header={t.find_code}
          handleShowLoginModal={() => setShowUniqueCodeModel(true)}
          showLoginModal={showUniqueCodeModel}
          handleClose={(value) => {
            if (value === 'my_wallet') {
              setShowMywallet(true)
              getWalletDetails()
            }
            setShowUniqueCodeModel(false)
          }}
          active={1}
          seperateUniqueCodeModel={true}
        />
      )}
      {tossNow && <TossNow tossNowEvent={tossNow} closeTossNow={() => setTossNow(false)} />}
      {howtoWinModel && (
        <MerchaindiseModel
          t={t}
          show={howtoWinModel}
          onClose={() => setHowtoWinModel(false)}
          setShowLoginModal={() => setShowLoginModal(true)}
          ReferFriendModelOpen={() => setOpenReferModel(true)}
          handleTossNow={handleTossNow}
        />
      )}
      {openRefermodel && (
        <ReferFriendModel
          show={openRefermodel}
          handleClose={() => {
            setOpenReferModel(false)
            triggerActionAfterLogin('')
          }}
          t={t}
          referFriendSuccussModel={(code) => referFriendSuccussModel(code)}
        />
      )}
    </>
  );
};

export default MyWallet;
