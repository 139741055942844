import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const TicketModel = ({ show, onClose, t }) => {
   
    return (
        <Modal size='sm' className={`tu-common-modal contact-modal`} show={show} onHide={onClose} centered>
            <Modal.Header className="text-center">
                <img
                    className="cross-button"
                    src="assets/images/close.png"
                    alt="close-btn"
                    onClick={onClose}
                    style={{ 'cursor': 'pointer' }}
                />
            </Modal.Header>
            <Modal.Body className="text-center">
                <Row className="justify-content-center">
                    <Col xs={4} className="text-cente">
                        <img className="modal-left-img" src={'assets/images/modal-left-img.png'} alt="model-img" />
                    </Col>
                    <Col xs={7} className="text-cente">
                        <div className="modal-right-section text-center">
                            <Modal.Title>{t.contact_us}</Modal.Title>
                            <div className='mt-4'>
                                <h4>{t.email_us}</h4>
                                <div className="modal-sub-title">{'indiahelpline@coca-cola.com'}</div>
                            </div>
                            <div className='toll-number'>
                                <h4>{t.call_us_toll_free_at}</h4>
                                <div className="modal-sub-title">1800 208 2653<br/>
                                9:00 AM - 9:00 PM ({t.monday}-{t.saturday}) <br/>
                                9:00 AM - 6:00 PM ({t.sunday})</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default TicketModel;

