import React, { useContext, useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import ProfileMenu from './ProfileMenu';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import MyWallet from './MyWallet';
//import MyWallet from './NewMyWallet';
import UniqueCode from './UniqueCode';
import LanguageSwitcher from './LanguageSwicher';
import CommonModel from '../model/CommonModel'
import { Button, Col, Row } from "react-bootstrap";
import { getWalletDetailsById } from "../../actions/Auth";
import { LoaderContext } from "../loader/LoaderContext";
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  TwitterPixel,
  getUserDetails
} from '../../utils/helper';
import CommonContext from '../contextApi';


const Header = () => {

  const { myWalletAction, redirectname, setRedirectname } = useContext(CommonContext);
  console.log('myWalletAction', myWalletAction)
  const { toggleLoader } = useContext(LoaderContext);
  const [showWallet, setShowMyWallet] = useState(false);
  const { language } = useContext(LanguageContext);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1)
  const [walletDetails, setWalletDetails] = useState('')
  const t = translations[language];
  let userDetails = getUserDetails();

  console.log('userDetails', userDetails)

  useEffect(() => {
    if (userDetails) {
      getWalletDetails()
      setShowMyWallet(myWalletAction)
    }
    if (redirectname && redirectname.notLogin) {
      handleShowLoginModal()
    }
    if (userDetails && redirectname && redirectname.show) {
      if (redirectname.url_name === 'my_wallet') {
        setShowMyWallet(true)
      }
    }
  }, [myWalletAction, redirectname])// eslint-disable-line react-hooks/exhaustive-deps


  const handleShowLoginModal = () => {
    setActiveStep(2)
    setShowLoginModal(true);
  };

  const handleClose = () => {
    setShowLoginModal(false);
    setShowMyWallet(false);
    setRedirectname({ show: false, url_name: '', notLogin: false })
  }

  /**
     * @method getWalletDetails
     * @description get wallet details api calling
     */
  const getWalletDetails = async () => {
    let requestData = {
      Id: userDetails && userDetails.Id
    }
    try {
      toggleLoader()
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data
        setWalletDetails(data)
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  }



  return (
    <>
      <header className="header" >
        <div className=" header-top d-md-none">
          <div className="container-fluid">
            <Row className="align-items-center py-1">
              <Col md={6} className="col-12">
                <LanguageSwitcher />
              </Col>
            </Row>
          </div>
        </div>

        <div className="container-fluid">
          <div className='d-flex align-items-center'>
            <Sidebar
              t={t}
              setShowLoginModal={handleShowLoginModal}
            />
            <div className="d-none d-md-block ml-2 mr-2">
              {/* Language Switcher */}

              <LanguageSwitcher />
            </div>
          </div>
          {userDetails && userDetails.Id && <div className='d-flex align-items-center'>
            <UniqueCode
              t={t}
              setShowLoginModal={handleShowLoginModal}
              getWalletDetails={getWalletDetails}
              walletDetails={walletDetails}
            />
            <Button className='p-0 tu-wallet-link' variant="link" onClick={() => {
              TTDPixel('056jm5v');
              setShowMyWallet(true);
              CounterFloodLight('DC-12665261/thums0/thums01z+unique');
              MetaPixel('MyWallet');
              TwitterPixel('tw-ofua3-ofvc7');
            }}>{`${t.wallet}`}</Button>
            {<MyWallet
              t={t}
              setShowLoginModal={setShowLoginModal}
              show={showWallet}
              handleClose={handleClose}
              getWalletDetails={getWalletDetails}
              walletDetails={walletDetails}
            />}
            <ProfileMenu t={t} getWalletDetails={getWalletDetails}
              walletData={walletDetails}
              setShowLoginModal={handleShowLoginModal}

            />
          </div>}
          {!userDetails && <div className='d-flex align-items-center'>
            <UniqueCode
              t={t}
              setShowLoginModal={handleShowLoginModal}
              getWalletDetails={getWalletDetails}
              walletDetails={walletDetails}
            />
            <Button
              className='p-0 tu-wallet-link'
              variant="link"
              onClick={() => {
                handleShowLoginModal();
                CounterFloodLight('DC-12665261/thums0/thums01p+unique');
                MetaPixel('Login');
                TTDPixel('vrm548g');
                TwitterPixel('tw-ofua3-ofunb');
              }}
            >
              {`${t.login}`}
            </Button>
          </div>}
        </div>
      </header>
      <div style={{ position: 'relative' }}>
        {!userDetails && showLoginModal &&
          <CommonModel t={t}
            title={t.worldCup2023TicketKeyunique}
            header={t.find_code}
            handleShowLoginModal={handleShowLoginModal}
            showLoginModal={showLoginModal}
            handleClose={handleClose}
            active={activeStep}
          />}
      </div>
    </>
  );
}


export default Header;
