import en from '../lang/en.json'; // English
import bn from '../lang/bn.json'; // Bangoli
import hi from '../lang/hi.json'; // Hindi
import te from '../lang/te.json'; // Malayalam
import or from '../lang/or.json'; // Marathi
import kn from '../lang/kn.json'; // Tamil
import gu from '../lang/gu.json'; // Gujarati
import mr from '../lang/mr.json' // Marathi
import pa from '../lang/pa.json' // Panjabi
import { currentYear } from '../utils/helper'

const translations = {
  en,
  bn,
  hi,
  te,
  or,
  kn,
  gu,
  mr,
  pa
};

// Iterate over each JSON file in the translations object
for (const language in translations) {
  if (Object.hasOwnProperty.call(translations, language)) {
    const jsonString = JSON.stringify(translations[language]);
    const modifiedJsonString = jsonString.replace(/2023/g, currentYear);
    const modifiedJson = JSON.parse(modifiedJsonString);
    translations[language] = modifiedJson;
  }
}

export default translations;