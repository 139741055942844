import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { encryptUserId, getUserDetails } from '../../utils/helper';
import { ARPlatformUrl } from '../../config/constant';

function ArEna() {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  let user_details = getUserDetails();

  const handleEnterAr = () => {
    if(user_details){
      const userId = user_details.Id;
      const encryptedUserId = (userId);
      
      if(encryptedUserId != null){
        const arAnchor = document.getElementById('arEnter');
        const redirectedUrl = ARPlatformUrl + `?userid=${encryptedUserId}`;
    
        window.open(redirectedUrl,'_blank','noopener,noreferrer');
      }
      else{
        window.open(ARPlatformUrl,'_blank','noopener,noreferrer');
      }
    }else{
      window.open(ARPlatformUrl,'_blank','noopener,noreferrer');
    }
  }

  return (
    <section className="section section-no-strip bg-pattern bg-pattern-arena arena">
      <div className="container-fluid">
        <Row className="justify-content-center justify-content-md-end no-gutters flex-column-reverse flex-md-row">
          <Col md={7} className='text-md-right text-center'>
            <img
              className="w-100"
              src="assets/images/ar-ena.png"
              alt="Toofani Hand"
            />
          </Col>
          <Col md={5}>
            <header className="section-header pt-2 px-0">
              <h2 className="section-title mb-1">
                {t.step_into_the} <br className='d-none d-md-block'/>{t.toofani_ar_ana}
              </h2>

              <p className="section-desc mb-5">
                {t.get_closer}
              </p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href={void 0}
                className="btn btn-sm btn-primary tuarenternowbtn"
                onClick={() => handleEnterAr()}
                >
                {t.enter_now}
              </a>
            </header>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default ArEna