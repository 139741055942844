import React, { useContext, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  CounterFloodLight,
  MetaPixel,
  getCDSPixelId, getUserDetails, handleInputLimitedLength, handleErrorMessages, TwitterPixel
} from '../../utils/helper'
import { validateAndRedeemCodevalue } from "../../actions/Auth";
import { LoaderContext } from "../loader/LoaderContext";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

const UniqueCodeForm = (props) => {
  const { t, nextStep, className, handleClose, seperateUniqueCodeModel, getApiResponse, language } = props;
  let user_details = getUserDetails()
  const { toggleLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;
  const [flag, setFlag] = useState(false)

  const SignupSchema = Yup.object().shape({
    Code: Yup.string()
      .required(t.validation_messages.required)
      .matches(/^[a-zA-Z0-9]+$/, t.validation_messages.invalid_code)
      .min(10, `${t.validation_messages.min_length} 10 ${t.digits}`)
      .max(10, `${t.validation_messages.max_length} 10 ${t.digits}`),
  });

  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    if (seperateUniqueCodeModel && values.Code) {
      //this will run single unique code model
      submitUniqueCode(values, resetForm, setFieldError)
    } else {
      // this will run with step form 
      nextStep({
        uniqueCode: values.Code
      });
      localStorage.setItem('uniqueCode', values.Code)
    }
  };

  // handle unique code submit api call
  const submitUniqueCode = async (values, resetForm, setFieldError) => {
    const hfSessionKey = await getCDSPixelId();
    let reqData = {
      Code: values.Code,
      UserId: user_details && user_details.Id,
      HfSession: hfSessionKey
    }
    toggleLoader();
    try {
      const response = await validateAndRedeemCodevalue(reqData);
      if (response.status === 200) {
        let data = response.data && response.data.Data
        resetForm()
        getApiResponse({
          coin_type: data,
          error: false
        })
        console.log('ga events')
        window.dataLayer.push({event: 'tu_uniquecode'})
        // window.google_tag_manager[process.env.REACT_APP_G_GTM_ID].dataLayer.push({
        //   event: 'tu_uniquecode'
        // });
        //navigate(`${currentPathname}?uniquecode=true`, { replace: true });
      }
    } catch (error) {
      navigate(`${currentPathname}`, { replace: true })
      console.log('error in unique', error.response.data.Message);
      let code = error.response && error.response.data && error.response.data.ErrorCode
      // getApiResponse({
      //   coin_type: '',AcceptReferred
      //   error: true,
      //   errorCode: code
      // })
      handleErrorMessages(error, setFieldError)
    }
    setTimeout(() => {
      toggleLoader();
    }, 500); // Add a small delay before hiding the loader (500ms in this example)
  };


  const handleSkip = () => {
    if (seperateUniqueCodeModel) {
      handleClose()//handle close unique code model
    } else {
      nextStep(2);
    }
  };

  return (
    <>
      <div className={className}>
        <Formik
          validationSchema={SignupSchema}
          initialValues={{ Code: "" }}
          onSubmit={handleSubmit}
          //validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} autoComplete="off" >
              <Form.Group controlId="Code">
                <Form.Label style={{ color: "white", fontWeight: "bold" }}>
                  {/* Label content */}
                </Form.Label>
                <Field
                  type="text"
                  name="Code"
                  placeHolder={t.enter_unique_code}
                  className={`form-control ${errors.Code ? "is-invalid" : ""}`}
                  value={values.Code}
                  onInput={(e) => {
                    //navigate('/?uniquecode=true', { replace: true });
                    handleInputLimitedLength(e, 10);
                  }}
                  onChange={handleChange}
                  style={{ borderRadius: "12px", border: "1px solid #ccc" }}
                />
                {errors.Code && (
                  <div className="error_message">{errors.Code}</div>
                )}
              </Form.Group>
              {errors.Message && (
                <div className="error_message error_red">{errors.Message}</div>
              )}
              <div className="d-block text-center">
                <Button
                  variant="primary"
                  type="submit"
                  className={`btn-block uniquecode_submit_btn`}
                  style={{ margin: "0 auto" }}
                  onClick={() => {
                    CounterFloodLight('DC-12665261/thums0/thums01q+unique');
                    MetaPixel('UniqueSubmit');
                    MetaPixel('EnterUniqueCode');
                    TwitterPixel('tw-ofua3-ofung');
                  }}
                >
                  {t.submit}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleSkip}
                  style={{ fontSize: '15px', borderRadius: "12px", textDecoration: 'underline' }}
                >
                  {t.skip}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UniqueCodeForm;
