import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { RegisterUser } from '../../actions/Auth';
import { avatarImages } from '../../config/constant';
import { 
  CounterFloodLight, 
  MetaPixel, 
  getCDSPixelId, setUserDetails,setToken, TTDPixel,handleErrorMessages, TwitterPixel } from '../../utils/helper';
import { LoaderContext } from '../loader/LoaderContext'; // Import the LoaderContext
import Loader from '../loader';
import { handleKeyPressName } from '../../utils/helper';
import { terms_condition, privacy_policy } from '../../config/constant';
import { useNavigate } from "react-router-dom";
import CommonContext from '../contextApi';
import { getGeoData } from '../../utils/cdsHelper';
import ReactGA4 from "react-ga4";

const SignUpForm = (props) => {

  const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
  const navigate = useNavigate();
  const { toggleLoader } = useContext(LoaderContext);
  const { t, handleClose, mobileNumber, setModelContent } = props;
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [isSuccess, setSuccess]  = useState(false)
  const modelContent = {
    title: t.you_are_almost_there,
    header: t.sign_up_playing_make_squad,
    image: '',
  };
  
  useEffect(() => {
    setModelContent(modelContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
  
  Handles the selection of an avatar image.
  @param {string} imageUrl - The URL of the selected avatar image.
  @param {function} setFieldValue - Formik's setFieldValue function.
  */
  const handleAvatarSelection = (imageUrl, setFieldValue) => {
    setSelectedAvatar(imageUrl);
    setFieldValue('ProfileImg', imageUrl);
    setIsHovering(false);
  };
  /**
  
  Validation schema for the signup form.
  Email: Validate the Email field with an email format.
  agreeTerms: Validate that agreeTerms is set to true and require it to be set.
  */
  const SignupSchema = Yup.object().shape({
    Name: Yup.string().matches(
      /^[a-zA-Z ]*$/,
      t.validation_messages.invalid_name
    ),
    Email: Yup.string()
      .email(t.validation_messages.invalid_email)
      .test('is-valid-email', t.validation_messages.invalid_email, (value) => {
        // Custom email validation logic
        if (!value) return true; // Allow empty value
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
    //.required('Email is required'),
    agreeTerms: Yup.bool()
      .oneOf([true], t.validation_messages.terms_condition_required)
      .required(t.validation_messages.terms_condition_required),
  });
  /**
  
  Handles the form submission.
  @param {object} values - Form values.
  @param {object} formikHelpers - Formik's form helper functions.
  */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    try {
      toggleLoader();
      const dataforLatitudeAndLongitude = localStorage.getItem('ipDetails');
      const geoData = getGeoData();
      const hfSessionKey = await getCDSPixelId();
      CounterFloodLight('DC-12665261/thums0/thums01t+unique');
      const { lat, lon, city, regionName, zip } = dataforLatitudeAndLongitude
        ? JSON.parse(dataforLatitudeAndLongitude)
        : {};
      const requestData = {
        MobileNo: mobileNumber,
        Name: values.Name ?? '',
        ProfileImg: selectedAvatar
          ? selectedAvatar
          : '/assets/images/avatar.png',
        Email: values.Email,
        Latitude: lat,
        Longitude: lon,
        City: city,
        State: regionName,
        Pincode: zip ? zip : '111111',
        HfSession : hfSessionKey,
        ...geoData
      };
      const response = await RegisterUser(requestData);
      if (response?.status === 200) {
        const data = response.data?.Data;
        let token = data.Token
        setToken(token)
        setUserDetails(data);
        resetForm();
        handleClose();
        let type = actionType && actionType.type
        let uniqueCode = actionType && actionType.Code ? actionType.Code : ''
        triggerActionAfterLogin({type: type, isLoggedIn: true, Code: uniqueCode})
        navigate('/?registration=true', { replace: true });
        setSuccess(true)
        // ReactGA4.event({
        //   event_category: 'tu_registrations',
        //   event_name: 'tu_registrations',
        // });
        console.log('ga events')
        // window.gtag('event', 'Button Click', {
        //   event_category: 'tu_registrations',
        //   event_action: 'tu_registrations',
        //   event_label: 'tu_registrations'
        // });
        window.dataLayer.push({event: 'tu_registrations'})
      }
    } catch (error) {
      setSuccess(false)
      handleErrorMessages(error, setFieldError)
    } finally {
      setTimeout(() => {
        toggleLoader();
      }, 500);
    }
  };
  /**
  
  Handles the mouse enter event.
  */
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  /**
  
  Renders the avatar options tooltip.
  @param {function} setFieldValue - Formik's setFieldValue function.
  @returns {JSX.Element} The Tooltip component with avatar options.
  */
  const renderTooltip = (setFieldValue) => (
    <Tooltip
      id="avatar-tooltip"
      className="tooltip-profile-avatar"
      placement="top-right">
      {avatarImages.map((imageUrl, index) => (
        <img
          key={index}
          width={100}
          height={100}
          src={imageUrl}
          alt={`Avatar ${index}`}
          className={`avatar-option ${
            selectedAvatar === imageUrl ? 'selected' : ''
          }`}
          onClick={() => handleAvatarSelection(imageUrl, setFieldValue)}
        />
      ))}
    </Tooltip>
  );


  return (
    <>
      <Loader />
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          Name: '',
          Email: '',
          mobileNumber: '',
          agreeTerms: true,
          ProfileImg: '',
        }}
        onSubmit={handleSubmit}
        //validateOnChange={false}
        validateOnBlur={false}>
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form
            className="text-left mt-sm-2"
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off">
            <div className="my-6">
              <OverlayTrigger
                overlay={() => renderTooltip(setFieldValue)}
                trigger="click">
                <label
                  htmlFor="ProfileImg"
                  className="avtar-signup"
                  onMouseLeave={() => setIsHovering(false)}
                  onClick={handleMouseEnter}>
                  {selectedAvatar ? (
                    <img src={selectedAvatar} alt="Selected Avatar" />
                  ) : (
                    <img
                      src="/assets/images/avatar.png"
                      alt="Selected Avatar"
                    />
                  )}
                  {isHovering && (
                    <div className="avtar-dropdown">
                      {avatarImages.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Avatar ${index}`}
                          className={`avatar-option ${
                            selectedAvatar === imageUrl ? 'selected' : ''
                          }`}
                          onClick={() => {
                            handleAvatarSelection(imageUrl, setFieldValue);
                          }}
                          style={{
                            cursor: 'pointer',
                            margin: '5px',
                            border: '2px solid transparent',
                            transition: 'border-color 0.3s ease',
                          }}
                        />
                      ))}
                    </div>
                  )}
                </label>
              </OverlayTrigger>

              {errors.ProfileImg && (
                <div className="error_message">{errors.ProfileImg}</div>
              )}
              <p className="form-label m-0">{t.choose_avatar} ({t.optional})</p>
            </div>

            <Form.Group controlId="Name">
              <Form.Label>
                {t.name} ({t.optional})
              </Form.Label>
              <Field
                type="text"
                name="Name"
                maxLength={50}
                className={`form-control ${
                  errors.Name && touched.Name ? 'is-invalid' : ''
                }`}
                value={values.Name}
                onChange={handleChange}
                //onKeyPress={handleKeyPressName}
                onInput={handleKeyPressName}
              />
              {errors.Name && (
                <div className="error_message">{errors.Name}</div>
              )}
            </Form.Group>
            <Form.Group controlId="Email">
              <Form.Label>
                {t.email_addresss} ({t.optional})
              </Form.Label>
              <Field
                type="email"
                name="Email"
                maxLength={100}
                className={`form-control ${
                  errors.Email && touched.Email ? 'is-invalid' : ''
                }`}
                //placeholder={t.enter_email}
                value={values.Email}
                onChange={handleChange}
              />
              {errors.Email && (
                <div className="error_message">{errors.Email}</div>
              )}
            </Form.Group>
            <Form.Group controlId="agreeTerms">
              <Form.Check
                type="checkbox"
                name="agreeTerms"
                label={
                  <span className="signup-privacy">
                    {t.certify_that}
                    <a href={terms_condition} target="_blank" rel="noopener noreferrer">
                      {' '}
                      {t.t_and_c}{' '}
                    </a>
                    {t.and}
                    <a href={privacy_policy} target="_blank" rel="noopener noreferrer">
                      {' '}
                      {t.privacy_policy}{' '}
                    </a>
                    {t.agree_disc}
                  </span>
                }
                checked={values.agreeTerms}
                onChange={handleChange}
                isInvalid={errors.agreeTerms && touched.agreeTerms}
              />
              {errors.agreeTerms && (
                <div className="error_message">{errors.agreeTerms}</div>
              )}
            </Form.Group>
            {errors.Message && (
              <div className="error_message error_red">{errors.Message}</div>
            )}
            <div className="d-flex justify-content-between mb-4">
              <Button variant="primary" type="submit" block className={`signupbtn ${isSuccess ? 'registration_success_btn' : ''}`}
                onClick={() => {
                  MetaPixel('Sign Up');
                  TTDPixel('y20xnts');
                  TwitterPixel('tw-ofua3-ofunc');
                }}
              >
                {t.sign_up}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
