

export const API = {
  getIP:`https://api.ipify.org?format=json`,
  getData: `/api/User/SendOTP`,
  postMobileVerifyOtp: `/api/User/VerifyOTP`,
  registerData: `/api/User/Register`,
  updateProfileName: `api/User/UpdateName`,
  updateProfile: `/api/User/UpdateProfielImg`,
  updateProfileEmail: `api/User/UpdateEmail`,
  updateProfileAge: `api/User/UpdateAge`,
  updateProfileGender: `api/User/UpdateGender`,
  updateProfileCity: `api/User/UpdateCity`,
  updateProfileState: `api/User/UpdateState`,
  updateProfileStateCity: `api/User/UpdateCityState`,
  updateProfilePinCode: `api/User/UpdatePincode`,
  voteNowForFlag: `/api/Voting/VoteForWorldCup`,
  updateProfileImage: `/api/User/updateProfilellmg`,
  getUserById: `/api/User/GetUserById`,
  tossNowForFlag: `/api/Voting/GetDailyTossCoin`,
  validateUniqueCode: `/api/Voting/VerifyAndRedeemMixCode`,
  getWalletById: `/api/Voting/GetWalletDetailsById`,
  getDetailsByMap: `/api/Voting/GetVotesForWorldCup`,
  sendReferCode: `/api/User/SendRefCode`,
  sendClaimMerch: `/api/Voting/ClaimMerch`,
  sendClaimTicket: `/api/Voting/ClaimTicket`,
  verifyreferCode: `/api/User/VeryfyRefCode`,
  saveMatchCoinProfileComplete: `/api/User/SaveMatchCoinForProfileComplete`,
  worldcupVotes: `/api/Voting/GetVotesByTopCites`,
  linegraphDetails: `/api/Voting/GetWeeklyVotesForLineGraph`,
  checkMatchClaim: `/api/Voting/CheckMerchClaimedToday`,
  ticketWinnerCount: `/api/Voting/GetTicketWinnerCount`,
  saveTicketWin: `/api/Voting/SaveTicketWinner`,
  merchList: `/api/Voting/GetMerchWinnerUsers`,
  winTicketList: `api/Voting/GetTicketWinnerUsers`,
  silverCoinList: `/api/Voting/GetSilverWinnerUsers`,
  saveMerchWinner: `/api/Voting/SaveMerchWinner`,
  saveToofaniPulse:`/api/Voting/SaveToofaniPulse`,
  logout:`/api/User/LogOut`,
  imageUpload: `/api/User/MediaUploadNew`
};

