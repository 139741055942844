import React, { useContext, useState, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import LanguageContext from '../../utils/LanguageContext';
import {
  voteNowForFlag,
  getWalletDetailsById,
  saveTicketWinner,
} from '../../actions/Auth';
import { LoaderContext } from '../loader/LoaderContext';
import Loader from '../loader';
import InfoModal from '../model/InfoModal';
import Button from 'react-bootstrap/Button';
import translations from '../../utils/translation';
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  getUserDetails,
  getCountryNameBasedOnCode,
  TwitterPixel
} from '../../utils/helper';
import MyWallet from '../common/MyWallet';
//import MyWallet from '../common/NewMyWallet';
import ClaimTicket from '../model/ClaimTicket';
import { getIpDetails,getErrorMessage } from '../../utils/helper';
import { useNavigate } from "react-router-dom";
import { ClickButtonCDP } from '../../utils/cdsHelper';
import CommonContext from '../contextApi';
import { claimTicketSuccess, redeemErrorModel, claimfailError } from '../../utils/modelHelper';

const VoteNow = (props) => {

  const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
  const navigate = useNavigate();
  const { setShowLoginModal } = props;
  const ipData = getIpDetails()
  const [country, setCountry] = useState();
  const [isSubmit, setSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [popup, setPopupInfo] = useState({
    title: '',
    content: '',
  });
  const [showMywallet, setShowMywallet] = useState(false);
  const [walletDetails, setWalletDetails] = useState('');
  const { language } = useContext(LanguageContext);
  const { toggleLoader } = useContext(LoaderContext);
  const t = translations[language];
  let user_details = getUserDetails();
  const [ticketModel, setTicketModel] = useState(false);
  const [showClaimTicket, setShowClaimTicket] = useState(false);
  const countryNames = {
    AF: t.country.AF,
    AU: t.country.AU,
    BD: t.country.BD,
    GB: t.country.GB,
    IN: t.country.IN,
    NL: t.country.NL,
    NZ: t.country.NZ,
    PK: t.country.PK,
    ZA: t.country.ZA,
    LK: t.country.LK,
  };

  let teamName = getCountryNameBasedOnCode(country)

  useEffect(() => {
    if (actionType && actionType.isLoggedIn) {
      if (actionType.type === 'vote_now') {
        handleVote()
      }
    }
    // voteNowSuccessModel()
    // voteClose()
    //userWinTicketModel()
  }, [actionType]);// eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getWalletDetails = async () => {
    let requestData = {
      Id: user_details && user_details.Id,
    };
    try {
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setWalletDetails(data);
        let victoryCoin = data && data.VictoryCoinDetail && data.VictoryCoinDetail.Coin_2023 > 0;
        console.log('%%%walletDetails', data);
        if (data && data.Silver < 1 && victoryCoin) {
          // userWinTicketModel();
          saveTicketWinnerApi();
        } else {
          voteNowSuccessModel();
        }
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  };

  // vote successfully and win victory coin model
  const voteNowSuccessModel = () => {
    setPopupInfo({
      title: <h2>{t.big_one_victory_coin}</h2>,
      content: (
        <h4>
          {t.you_win1_victory_coin} <br />
          {t.and} {t.toofani_pulse}
        </h4>
      ),
      image: <img src={'assets/images/Coin_1983.png'} alt={'img-1983'} />,
      button: (
        <Button
          variant="danger"
          className="center-button"
          onClick={() => {
            closePopup();
            setShowMywallet(true);
            MetaPixel('see_collection');
            TwitterPixel('tw-ofua3-ofvci');
          }}>
          {`${t.see_collection}`}
        </Button>
      ),
      className: 'profile-success',
    });
  };

  // already voted model
  const voteClose = () => {
    setPopupInfo({
      title: <h2>{t.daily_voting}</h2>,
      content: <h3>{t.already_voted}</h3>,
      button: <h4>{t.retry}</h4>,
      image: '',
      className: 'vote_now_default',
    });
  };

  // handle all type of api error
  const apiErrorModel = (message) => {
    setPopupInfo({
      title: (<h2>{''}</h2>),
      content: (<h4>{message}<br /></h4>),
      image: '',
      className: 'incorrect-noball no_ball',
    });
  }

  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const saveTicketWinnerApi = async () => {
    let requestData = {
      UserId: user_details && user_details.Id,
    };
    try {
      const response = await saveTicketWinner(requestData);
      if (response.status === 200) {
        userWinTicketModel();
      }
    } catch (error) {
      let errorRes = error.response && error.response.data
      let message = getErrorMessage(errorRes.Message)
      apiErrorModel(message)
    }
  };

  /**
   * @method userWinTicketModel
   * @description model will show ones user win all the victory coins
   */
  const userWinTicketModel = () => {
    setPopupInfo({
      title: <h2>{t.toofan_uthega}</h2>,
      content: (
        <div>
          <h3 className="mb-0">{t.congratulations}</h3>
          <h3>
            {t.victory_coin_collection}
            <br />
            {t.claim_your_world_cup_ticket}
          </h3>
        </div>
      ),
      image: <img src={'assets/images/silver-coin-win.png'} alt={'ticket-img'} />,
      button: (
        <Button
          variant="danger"
          className="center-button"
          onClick={() => {
            closePopup();
            setTicketModel('ticket');
            setShowClaimTicket(true);
          }}>
          {`${t.claim_now}`}
        </Button>
      ),
      className: 'wallet-success',
    });
  };

  /**
   * @method toofaniWinTicket
   * @description toofani win ticket model congratulations
   */
  const toofaniWinTicket = () => {
    setPopupInfo(claimTicketSuccess(t, language));
  };

  /**
   * @method redeemMerchModel
   * @description daily quata is over model
   */
  const redeemMerchModel = () => {
    setPopupInfo(redeemErrorModel(t))
  };

  // console.log('tag-manager^^', window.google_tag_manager[process.env.REACT_APP_G_GTM_ID])

  /**
   * Handle the vote action
   * @param {Object} values - Form values
   */
  const handleVote = async (values) => {

    if (!user_details) {
      console.log('case1', user_details);
      setShowLoginModal();
      triggerActionAfterLogin({ type: 'vote_now', isLoggedIn: false })
    } else {
      toggleLoader();
      let city = ipData && ipData.city
      if (ipData && ipData.regionName === 'Delhi') {
        city = 'Delhi'
      }
      let data = {
        IsIndiaWin: country === 'IN',
        UserId: user_details?.Id,
        TeamName: teamName,
        City: user_details && user_details.City ? user_details.City : city,
        Latitude: user_details && user_details.Latitude ? user_details.Latitude : ipData ? ipData.lat : '',
        Longitude: user_details && user_details.Longitude ? user_details.Longitude : ipData ? ipData.lon : ''
      };

      try {
        const response = await voteNowForFlag(data);
        if (response.status === 200) {
          ClickButtonCDP('Vote_Web');
          let country_name = teamName
          let key_name = `${country_name}vote`
          getWalletDetails();
          setIsSuccess(true)
          const teamNameSpaceRemoved = teamName.replaceAll(/\s/g, '');
          window.dataLayer.push({ event: `tu_votenowbtn_${teamNameSpaceRemoved}` })
        }
      } catch (error) {
        voteClose();
        setIsSuccess(false)
      } finally {
        setTimeout(() => {
          toggleLoader();
        }, 100);
      }
    }
  };

  const closePopup = () => {
    setPopupInfo({
      title: '',
      content: '',
    });
    setIsSuccess(false)
  };

  const allowedCountries = Object.keys(countryNames);

  /**
 * @method claimfailError
 * @description model will open if you claim all the tickets
 */
  const claimFail = (content1, content2) => {
    setPopupInfo(claimfailError(content1, content2));
  };


  return (
    <div className="vote-popup position-relative mx-n4" id={'vote_now'}>
      <Loader />
      <img
        className="subtract subtract-top"
        src={'assets/images/subtract-top.png'}
        alt="subtract-top img"
      />
      <div className="tu-worldcup-section tu-content-wrapper tu-first">
        <img
          className="vector vector-left vector-left-trophy"
          src={'assets/images/world-cup-image.png'}
          alt="cup icon"
        />
        <img
          className="vector vector-right d-none d-md-block"
          src={'assets/images/player-right-img.png'}
          alt="player"
        />
        <h2 className="section-title section-title-two">
          {t.who_will_win} <br className="d-block d-md-none" />
          {t.the_world_cup}
        </h2>
        <div className={`w-75 mx-auto selected_country_${country}`}>
          <ReactFlagsSelect
            selected={country}
            onSelect={(code) => {
              setIsSuccess(true)
              setCountry(code)
            }}
            fullWidth={true}
            className="world_cup_ticket tu-country-select"
            countries={allowedCountries}
            customLabels={countryNames}
            placeholder={t.select}
          />
          {!country && isSubmit && <div className="error_message">{t.validation_messages.select_country}</div>}
          <button onClick={() => {
            if (country) {
              setSubmit(false)
              handleVote()
            } else {
              setSubmit(true)
            }
            CounterFloodLight('DC-12665261/thums0/thums01u+unique');
            MetaPixel('DailyVoting');
            TTDPixel('279wtf8');
            TwitterPixel('tw-ofua3-ofvcr');
          }} className={`btn btn-sm btn-primary w-100 votenowbtn ${country && isSuccess ? teamName : ''}`}>
            {t.vote_now}
          </button>
        </div>
        <h2 className="section-desc mt-3">
          {t.cast_your_vote}
        </h2>
      </div>
      {popup.title && (
        <InfoModal
          title={popup.title}
          content={popup.content}
          onClose={closePopup}
          button={popup.button}
          image={popup.image}
          className={popup.className}
          topHeading={popup.topHeading}
        />
      )}
      {
        <MyWallet
          t={t}
          setShowLoginModal={false}
          show={showMywallet}
          handleClose={() => setShowMywallet(false)}
          getWalletDetails={getWalletDetails}
          walletDetails={walletDetails}
        />
      }
      {showClaimTicket && (
        <ClaimTicket
          source={ticketModel}
          show={showClaimTicket}
          handleClose={() => setShowClaimTicket(false)}
          t={t}
          toofaniWinTicket={toofaniWinTicket}
          redeemMerchModel={redeemMerchModel}
          claimFail={claimFail}
          handleCross={() => {
            setShowClaimTicket(false)
          }}
        />
      )}
    </div>
  );
};

export default VoteNow;
