import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import CommonModel from '../components/model/CommonModel';
import HomeCarousel from './common/HomeCarousel';
import VoteAndTossSection from './common/VoteAndTossSection';
import InfoModal from './model/InfoModal';
import Toofani from './common/Toofani';
import Playlist from './common/Playlist';
import { useLocation } from 'react-router-dom';
import MyWallet from './common/MyWallet';
//import MyWallet from './common/NewMyWallet';
import { 
  CounterFloodLight, 
  MetaPixel, 
  TTDPixel, 
  TwitterPixel, 
  getUserDetails } from '../utils/helper'
import { ClickButtonCDP, HomeCDP } from '../utils/cdsHelper';
import ArEna from './common/ArEna';
// import ExcelToJson from '../utils/ExceltoJsonTranslator';
import { oneMatchCoinWin } from '../utils/modelHelper';

const Home = (props) => {

  const { t } = props;
  let userDetails = getUserDetails();
  const [activeStep, setActiveStep] = useState(1);
  console.log('tttt', t);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [popup, setPopupInfo] = useState({
    title: '',
    content: '',
  });
  let uniqueCode = localStorage.getItem('uniqueCode');
  const { pathname } = useLocation();
  //invite code
  const [openinvitemodel, setOpenInviteModel] = useState(false);
  const [showWallet, setShowMyWallet] = useState(false);


  useEffect(() => {
    let referStatus;
    TTDPixel('08cy9ty');
    MetaPixel('HomePage');
    CounterFloodLight('DC-12665261/thums0/thums01o+unique');
    TwitterPixel('tw-ofua3-ofv98');
    HomeCDP();
    const mobileNo = userDetails && userDetails['MobileNo'];

    setTimeout(() => {
      referStatus = localStorage.getItem("newReferCompleted");
      console.log("refer", referStatus)
      if (referStatus == 'true') {
        console.log("called");
        winOnematchCoinModel();
        ClickButtonCDP(`Referral_Completed_${mobileNo}`);
      }
    }, 2000)
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  // for time being adding to open once a referal is completed to open coin win bcz its code is not separetee, mixed with invite model, we can scrape it once code is separated
  const winOnematchCoinModel = () => {
    setPopupInfo(oneMatchCoinWin(t,setShowMyWallet, closePopup))
  };


  useEffect(() => {
    //codeRedeemModel()
    //inCorrectCodeModel()
    //usedCodeModel()
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleShowLoginModal = () => {
    console.log('enter123');
    setActiveStep(2);
    setShowLoginModal(true);
  };

  const handleClose = () => setShowLoginModal(false);

  const closePopup = () => {
    setPopupInfo({
      title: '', // Reset the title
      content: '', // Reset the content
    });
    localStorage.removeItem('uniqueCode');
  };

  return (
    <>
    {/* <ExcelToJson/> */}
      <main>
        <HomeCarousel setShowLoginModal={handleShowLoginModal} />
        <VoteAndTossSection
          showLoginModal={showLoginModal}
          setShowLoginModal={handleShowLoginModal}
        />
        {/* uncomment in next phase */}
        {/* <Toofani /> */}
        
        <ArEna/>
        <Playlist />
        <div style={{ position: 'relative' }}>
          {!userDetails && showLoginModal && (
            <CommonModel
              t={t}
              title={t.worldCup2023TicketKeyunique}
              header={t.find_code}
              handleShowLoginModal={handleShowLoginModal}
              showLoginModal={showLoginModal}
              handleClose={handleClose}
              active={activeStep}
            />)}
          {popup.title && (
            <InfoModal
              title={popup.title} // Set the title for the pop-up
              content={popup.content} // Set the content for the pop-up
              onClose={closePopup} // Close the pop-up
              button={popup.button}
              image={popup.image}
              // bgImage={popup.bgImage}
              className={popup.className}
              topHeading={popup.topHeading}
            />
          )}
        </div>
      </main>
      {
        <MyWallet
          t={t}
          show={showWallet}
          handleClose={() => setShowMyWallet(false)}
        />
      }
    </>
  );
};

export default Home;
