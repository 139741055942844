import React from 'react';

function CaptureBanner(props) {
    const { t } = props
    return (
        <section className='section tu-capture-section pb-4'>
            <h2 className='text-center pt-4'>{t.toofani_cheers}</h2>
            <div className=' d-flex justify-content-around text-center pb-4 '>
                <div className=''>
                    <img className=""
                        src="/assets/images/capture-step-1.svg"
                        alt="Step 1"
                    />
                    <h3 className='pt-4'>{t.step1}</h3>
                    <p>{t.click_photo} </p>
                </div>
                <div>
                    <img className=""
                        src="/assets/images/capture-step-2.svg"
                        alt="Step 2"
                    />
                    <h3 className='pt-4'>{t.step2}</h3>
                    <p>{t.access_camera} </p>
                </div>

                <div>
                    <img className=""
                        src="/assets/images/capture_step3_2.svg"
                        alt="Step 3"
                    />
                    <h3 className='pt-4'>{t.step3}</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t.capture_yourself,
                        }}></p>
                </div>
            </div>
        </section>
    );
}

export default CaptureBanner;
