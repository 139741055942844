// LanguageSwitcher.js
import { useContext, useState,useEffect } from 'react';
import LanguageContext from '../../utils/LanguageContext';
import Cookies from 'js-cookie';
import translations from '../../utils/translation';

function LanguageSwitcherSideBar() {
  const { changeLanguage } = useContext(LanguageContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const cookieValue = Cookies.get('language');
  const [currentLanguage,setCurrentLanguage] = useState(cookieValue ? cookieValue : 'en');
  let list = [
    { label: 'English', value: 'en' },
    { label: 'हिंदी', value: 'hi' },
    { label: 'తెలుగు', value: 'te' },
    { label: 'मराठी', value: 'mr' },
    {label : 'ગુજરાતી' ,  value : 'gu'},
    {label : 'বাংলা', value: 'bn'},
    {label : 'ಕನ್ನಡ', value : 'kn'},
    {label : 'ଓଡିଆ',value : 'or'}
  ]

  let currentLangFull = list.find(item => item.value == currentLanguage);
  console.log('currentLanguage', currentLanguage,currentLangFull)
  const [value, setvalue] = useState(currentLangFull ? currentLangFull.value : currentLanguage);
  const [valChanged,setValChanged] = useState(false);

  const handleLanguageChange = (value) => {
    if (value) {
      const newLanguage = value;
      changeLanguage(newLanguage);
      Cookies.set('language', newLanguage, { expires: 7 })
      setvalue(value)
      const currentLangVal = list.find(item => item.label == newLanguage);
      setCurrentLanguage(currentLangVal?.value);
      // window.location.reload()
    } else {
      setvalue('')
    }
  };

  useEffect(() =>{
    console.log('cookie',cookieValue,value);
    if(cookieValue && cookieValue != value){
      setvalue(cookieValue);
    }
  },[cookieValue]);// eslint-disable-line react-hooks/exhaustive-deps

  const renderLanguageSideBar = () => {
    
    return (
      <div className='tu-language'>
      <select value={value} onChange={(event) => handleLanguageChange(event.target.value)} className='tu-language-select-side'>
        {/* <option value="">{t.change_language}</option> */}
        {
          list.map((el, i) => {
            let selected = el.value === value ? 'selected' : ''
            return (
              <option value={el.value} key={i} >{el.label}</option>
            )
          })
        }
      </select>
      </div>
    );
  }

  return (
    <div className='tu-sidebar-privacy-main tu-language-side-bottom-bar mt-0'>
      {renderLanguageSideBar()}
      {/* {renderlanguageHeader()} */}
      {/* <select value={value} onChange={(event) => handleLanguageChange(event.target.value)}>
        <option value="">Others</option>
        <option value="bn">Bangali</option>
        <option value="or">Oriya</option>
        <option value="kn">Kannada</option>
        <option value="gu">Gujarati</option>
        <option value="mr">Marathi</option>
      </select> */}
    </div>
  );
}

export default LanguageSwitcherSideBar;