import { useState, useContext, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale, Tooltip } from 'chart.js';
import { getLineGraph } from '../actions/Auth';
import { LoaderContext } from './loader/LoaderContext';
import LanguageContext from '../utils/LanguageContext';
import translations from '../utils/translation';
import moment from 'moment'

Chart.register(CategoryScale, Tooltip);
Chart.defaults.font.weight = 'bold';
Chart.defaults.font.family = 'Thums Up';
Chart.defaults.font.size = '16px';


const LineChart = () => {

  const [chartData, setChartData] = useState([]);
  const [allChartData, setAllChartData] = useState([]);
  const { toggleLoader } = useContext(LoaderContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  useEffect(() => {
    getChartDetails();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const getChartDetails = async () => {
    try {
      toggleLoader();
      const response = await getLineGraph();
      console.log('response***', response);
      const data = response && response.data && response.data.Data;
      setAllChartData(data);
      const percentages = data.map((item) => item.Percentage);
      setChartData(percentages.map(parseFloat));
    } catch (error) {
      console.error('Error updating profile name:', error);
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  console.log('chartDetails', chartData);

  // get point radius based on name
  const getPointRadius = (context) => {
    console.log(context, 'allChartData[index]', allChartData);
    let index = context && context.index;
    let name = allChartData[index]?.Name;
    console.log(index, 'name', name)
    let style = name ? 5 : 0
    return style; // Default to 'circle' if you don't have a specific condition
  }

  // get point hover radius based on name
  const getPointHoverRadius = (context) => {
    console.log(context, 'allChartData[index]', allChartData);
    let index = context && context.dataIndex;
    let name = allChartData[index]?.Name;
    let style = name ? 5 : 0
    return style; // Default to 'circle' if you don't have a specific condition
  }

  // Step 1: Convert date labels to date objects
  const dates = allChartData.map((el) => {
    let date = new Date(el.Date)
    let format_date = moment(date).format('DD')
    return  format_date
  });

  // Step 2: Create chart data array with 'x' and 'y' properties
  const chartDataPoints = dates.map((date, index) => ({ x: date, y: chartData[index] }));

  console.log(dates,'chartDataPoints',chartDataPoints)

  const options = {
    scales: {
      x: {
        grid: {
          drawBorder: false,
          offset: true,
          display: false, // Show x-axis grid lines
          padding: {
            left: 20, // Adjust the left padding as needed
            right: 20, // Adjust the right padding as needed
          },
          color: 'rgba(0, 158, 219, 0.5)', // Change the color of the grid lines
        },
        ticks: {
          display: false, // Hide the horizontal labels
          color: 'white', // Change the color of the x-axis labels
        },
        border: {
          color: 'transparent'
        }
      },
      y: {
        min: 0,
        max: 100,
        grid: {
          offset: true,
          padding: {
            top: 20, // Adjust the top padding as needed
            bottom: 20, // Adjust the bottom padding as needed
          },
          color: 'rgba(0, 158, 219, 0.5)', // Change the color of the grid lines
        },
        ticks: {
          color: 'white', // Change the color of the y-axis labels
          callback: (value) => `${value}%`, // Add the callback function to append '%' to the y-axis labels
          stepSize: 20, // Set the difference between ticks to 20
        },
        border: {
          color: 'transparent'
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(255, 0, 0, 0.8)', // Set tooltip background color to red with opacity
        titleColor: 'white', // Set tooltip title color to white
        bodyColor: 'white', // Set tooltip body color to white
        displayColors: false, // Hide color indicators in tooltip
        callbacks: {
          title: (context) => {
            let index = context[0].dataIndex;
            console.log(context, 'allChartData[index]', allChartData);
            return allChartData[index]?.Name || '';
          }, // Set the tooltip title
          label: () => '', // Add '%' to the tooltip label
          labelTextColor: () => 'white', // Set the tooltip label text color to white
        },
        position: 'nearest', // Position the tooltip below the marker
        caretPadding: 14, // Adjust the space between the tooltip and the marker
      },
    },
    elements: {
      point: {
        backgroundColor: 'red', // Change the color of the data points
        borderColor: 'red', // Change the border color of the data points
        pointStyle: 'circle', // Set the point style to 'circle'
        radius: 5, // Set the radius of the points
        hoverRadius: 8, // Set the hover radius of the points
        //pointStyle: getPointStyle
      },
      line: {
        borderColor: 'white', // Change the color of the line
      },
    },
  };

  //const labels = ['100%', '80%', '60%', '40%', '20%', '0%'];
  const labels = dates
  const data = {
    labels,
    datasets: [
      {
        label: 'Ind vs Pak',
        //data: chartData,
        data:chartDataPoints,
        borderColor: '#009EDB',
        backgroundColor: 'rgba(0, 158, 219, 0.5)',
        borderWidth: 2,
        pointBackgroundColor: 'red', // Set point color to red
        pointRadius: getPointRadius, // Set point radius to 5 to display as dots
        pointHoverRadius: getPointHoverRadius, // Set hover radius
      },
    ],
  };

  return (
    <div>
      <div className="text-center">
        <h2 className="heading">{t.daily_live_santiment}</h2>
        <h5 className="sub-heading">
          {t.track_india_toofani_pulse}
          <br />
          {t.move_closer_to_world_cup}
        </h5>
      </div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
