// LanguageSwitcher.js
import { useContext, useState, Fragment } from 'react';
import LanguageContext from '../../utils/LanguageContext';
import Cookies from 'js-cookie';

function LanguageSwitcher() {
  const { changeLanguage } = useContext(LanguageContext);

  const cookieValue = Cookies.get('language');
  const currentLanguage = cookieValue ? cookieValue : 'en'
  console.log('currentLanguage', currentLanguage)
  const [value, setvalue] = useState(currentLanguage)

  const handleLanguageChange = (value) => {
    if (value) {
      const newLanguage = value;
      changeLanguage(newLanguage);
      Cookies.set('language', newLanguage, { expires: 7 })
      setvalue(value)
      // window.location.reload()
    } else {
      setvalue('')
    }
  };

  let list = [
    { label: 'English', value: 'en' },
    { label: 'हिंदी', value: 'hi' },
    { label: 'తెలుగు', value: 'te' },
    // { label: 'मराठी', value: 'mr' },
    // {label : 'ગુજરાતી' ,  value : 'gu'},
    // {label : 'বাংলা', value: 'bn'},
    // {label : 'ಕನ್ನಡ', value : 'kn'},
    // {label : 'ଓଡିଆ',value : 'or'}
  ]

  // render first 3 language 
  const renderlanguage = () => {
    return list.map((el, i) => {
      let selected = el.value === value ? 'selected' : ''
      return (
        <Fragment key={i}>
          <span  className={`language ${selected}`} onClick={() => handleLanguageChange(el.value)}>{el.label}</span>
         {i  <3 && <span className="divider"></span>}
        </Fragment>
      )
    })
  }

  return (
    <div className='tu-language-select'>
      {renderlanguage()}
      {/* <span className="divider"></span> */}
      {/* <div className='others-tu-language-header'> */}
        <select value={value} onChange={(event) => handleLanguageChange(event.target.value)} className='others-tu-language-header'>
          <option value="">Others</option>
          <option value="bn">বাংলা</option>
          <option value="or">ଓଡିଆ</option>
          <option value="kn">ಕನ್ನಡ</option>
          <option value="gu">ગુજરાતી</option>
          <option value="mr">मराठी</option>
        </select>
      {/* </div> */}
    </div>
  );
}

export default LanguageSwitcher;