import React from 'react';
import { Modal } from 'react-bootstrap';
import VideoPlayer from '../common/VedioPlayer'

const StumpCamModel = ({ show, onClose, camDetails }) => {

    return (
        <Modal className="tu-common-modal tu-wallet-modal" show={show} onHide={onClose} centered>
            <div className="modal-bg">
                <Modal.Header className="text-center">
                    <img
                        className="cross-button"
                        src="assets/images/close.png"
                        alt="Close"
                        onClick={onClose}
                        style={{ 'cursor': 'pointer' }}
                    />
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h2>{camDetails.title}</h2>
                    <div>
                        {/* <Card className="text-center"> */}
                            {/* <Card.Img variant="top" src={camDetails.image} alt="video" /> */}
                            <VideoPlayer data={camDetails}/>
                        {/* </Card> */}
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default StumpCamModel;
