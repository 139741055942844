import React, { useContext, useState } from "react";
import LanguageContext from "../utils/LanguageContext";
import translations from "../utils/translation";
import { Button } from "react-bootstrap";
import CaptureImage from './common/CaptureImage'
import CaptureBanner from './common/CaptureBanner'
import ImageGrid from "./common/ImageGrid";

const Capture = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const [imageList, setImageList] = useState([])
    const onSuccess = (img) => {
        setImageList(prevState => [...prevState, img]);
    }

    return (
        <>
            <CaptureBanner t={t}/>
            <div className="cs_container capture-image pb-12">

                <div className="content">
                    <div className="d-flex flex-column align-items-center justify-content-center w-100">
                        <div className="my-3 capture-wrapper">
                            <CaptureImage onSuccess={(img) => onSuccess(img)} t={t}/>
                        </div>

                    </div>
                </div>   
                {/* <ImageGrid imageList={imageList} /> */}
            </div>

        </>
    );
};

export default Capture;
