import { useState, useEffect } from "react";

const useTimer = (initialTime = 0, isReverseTimer = false) => {
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);

  const startTimer = () => {
    setIsRunning(true);
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setTime(initialTime);
    setIsRunning(false);
  };

  useEffect(() => {
    let timer;

    if (isRunning) {
      if (isReverseTimer) {
        timer = setInterval(() => {
          setTime((prevTime) => {
            if (prevTime === 0) {
              return pauseTimer();
            }
            return prevTime - 1;
          });
        }, 1000);
      } else {
        timer = setInterval(() => {
          setTime((prevTime) => prevTime + 1);
        }, 1000);
      }
    }

    return () => clearInterval(timer);
  }, [isRunning, isReverseTimer]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return {
    time,
    isRunning,
    startTimer,
    pauseTimer,
    resetTimer,
    formattedTimerTime: formatTime(time),
  };
};

export default useTimer;
