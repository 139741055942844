import React, { useCallback, useEffect, useState, useContext } from "react";
import { isMobile } from "react-device-detect";
import Webcam from "react-webcam";
import { Button, Form } from "react-bootstrap";
import { downloadFileByAnchor, base64ToBlob } from "../../utils/helper";
import useTimer from "../../utils/useTimer";
import { LoaderContext } from "../loader/LoaderContext";
import { uploadImageAPI } from "../../actions/Auth";
import { getUserDetails } from "../../utils/helper";
import Loader from '../loader'
import CommonContext from "../contextApi";
import { captureImageSuccess } from '../../utils/modelHelper';
import InfoModal from '../../components/model/InfoModal';


const overlayImage = "assets/images/picture-frame.png";


const CaptureImage = ({ onSuccess, onClose, t }) => {
  const { triggerActionAfterLogin } = useContext(CommonContext);
  let user_details = getUserDetails();
  const { toggleLoader } = useContext(LoaderContext);
  const [certImage, setCertImage] = useState("");
  const webcamRef = React.useRef(null);
  const [facingMode, setFacingMode] = useState("user"); // "user" for front camera, "environment" for back camera
  const [showTimer, setShowTimer] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [blobImage, setBlobImage] = useState('')
  const [infoModel, setPopupInfo] = useState({
    title: '',
    content: '',
  });
  const[ischecked,setIschecked] = useState(false);


  const { time, startTimer, pauseTimer, resetTimer, formattedTimerTime } =
    useTimer(10, true);

  const videoConstraints = {
    facingMode: facingMode,
    width: 480,
    height: 480,
    aspectRatio: 1,
  };
  const unaffected_cap = {
    fontSize: '3.2rem',
   
  };

  useEffect(() => {
    triggerActionAfterLogin('')
    if (time === 0) {
      setShowTimer(false);
      capture();
    }
  }, [time]);

  const handleCheckChange = (event) => {
    setIschecked(event.target.checked);
  }

  const uploadCapturedImage = useCallback(async () => {
    if (user_details) {
      try {
        toggleLoader();
        console.log('certImage', certImage)
        let blobImage = base64ToBlob(certImage, 'snapshot.jpg')
        console.log('blobImage', blobImage)
        let formData = new FormData();
        formData.append("file", blobImage, "small.jpeg");
        formData.append("Uid", user_details?.Id);
        await uploadImageAPI(formData);
        setPopupInfo(captureImageSuccess(t))
      } catch (error) {
        console.error("Error updating profile name:", error);
      }
      setTimeout(() => {
        toggleLoader();
      }, 500);
    }
  }, [certImage]);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc: ", imageSrc);

    const frameWidth = 457;
    const frameHeight = 620;

    const canvas = document.createElement("canvas");
    canvas.width = frameWidth; // Set the canvas width to match the frame width
    canvas.height = frameHeight; // Set the canvas height to match the frame height
    // canvas.style.border = "1px solid black";
    const ctx = canvas.getContext("2d");

    const imgBase64 = new Image();
    const imgHTML = new Image();

    imgBase64.onload = function () {
      imgHTML.onload = function () {
        // Calculate the aspect ratio of the webcam image
        const aspectRatio = imgBase64.width / imgBase64.height;

        let scaledWidth, scaledHeight;

        if (aspectRatio > frameWidth / frameHeight) {
          // Webcam image is wider
          scaledWidth = frameWidth;
          scaledHeight = frameWidth / aspectRatio;
        } else {
          // Webcam image is taller
          scaledHeight = frameHeight;
          scaledWidth = frameHeight * aspectRatio;
        }

        // const webcamX = (frameWidth - scaledWidth) / 2;
        // const webcamY = (frameHeight - scaledHeight) / 2;

        ctx.drawImage(imgBase64, 0, 155, scaledWidth, scaledHeight);
        ctx.drawImage(imgHTML, 0, 0, frameWidth, frameHeight);

        console.log('canvas &&', canvas.toDataURL());
        setCertImage(canvas.toDataURL());
      };
      imgHTML.src = overlayImage;
    };

    imgBase64.src = imageSrc;
  };

  const save = () => {
    // downloadFileByAnchor(certImage, "YOYO_CERTIFICATE", "jpeg");
    uploadCapturedImage();
    onSuccess(certImage)
  };

  console.log('certImage', certImage)


  const renderButtonSection = () => {
    return (
      <>
        {certImage === '' && <div>
          <div className="btn-block">
            <button
              onClick={capture}
              className="btn btn-primary  capture-btn btn-lg w-100 my-4"
              // disabled={certImage !== ''}
              disabled={ischecked?certImage !== '' ? true : false : true}
            >
              {t.capture_photo}
            </button>
          </div>
          <Form>
            <Form.Group controlId="agreeTerms">
              <Form.Check
                type="checkbox"
                name="agreeTerms"
                label={
                  <span className="signup-privacy">
                    {t.capture_content}
                  </span>
                }
                checked={ischecked}
                onChange={handleCheckChange}
              />
            </Form.Group>
          </Form>
        </div>}
        <div className="d-md-flex ">
          <button
            onClick={() => {
              save();
            }}
            className="btn btn-primary btn-sm w-md-50 w-100 mr-md-4 step-btn  mt-4"
            style={unaffected_cap}
            disabled={certImage === ''}
          >
            {/* {t.submit} */}
            Submit
          </button>
          <button
            onClick={() => setCertImage("")}
            className="btn btn-outline btn-sm w-md-50 w-100 ml-md-4  step-btn mt-4"
            style={unaffected_cap}
            disabled={certImage === ''}
          >
            {t.retake}
          </button>
        </div>
      </>
    )
  }

  const closePopup = () => {
    setPopupInfo({
      title: '', // Reset the title
      content: '', // Reset the content
    });
    setCertImage('')
  };

  if (certImage) {
    return (
      <>
        <Loader />
        <img src={certImage} alt="Certificate" />
        {renderButtonSection()}
        {infoModel && infoModel.title && <InfoModal
          title={infoModel.title} // Set the title for the pop-up
          content={infoModel.content} // Set the content for the pop-up
          className={infoModel.className}
          topHeading={infoModel.topHeading}
          button={infoModel.button}
          onClose={closePopup} // Close the pop-up
        />}
      </>
    );
  }

  return (
    <>
      <div className="ar-wrapper">
        <div className="position-relative">
          <img src={overlayImage} className="position-relative capture-img" alt='capture' />
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            height={380}
            width={380}
            videoConstraints={videoConstraints}
            mirrored
            className="position-absolute tu-capture-frame"
          />
        </div>
        {renderButtonSection()}
      </div>
    </>
  );
};

export default CaptureImage;
