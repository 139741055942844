import React, { useContext, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { verifyOtp, sendOtp } from '../../actions/Auth';
import { BsArrowLeft } from 'react-icons/bs';
import {
  CounterFloodLight,
  MetaPixel,
  getCDSPixelId,
  setUserDetails, setToken, TTDPixel, TwitterPixel
} from '../../utils/helper';
import { LoaderContext } from '../loader/LoaderContext'; // Import the LoaderContext
import Loader from '../loader';
import { handleKeyPressotp, handleErrorMessages } from '../../utils/helper';
import { useNavigate } from "react-router-dom";
import CommonContext from '../contextApi';
import { getGeoData } from '../../utils/cdsHelper';

const OptVerificationForm = (props) => {

  const navigate = useNavigate();
  const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
  const { toggleLoader } = useContext(LoaderContext);
  const { t, mobileNumber, previousStep, nextStep, setModelContent, handleClose } = props;

  /**
   * @constant modelContent
   * @description The content object for the model, including title, header, and image
   */
  const modelContent = {
    title: t.otp_verification,
    header: <><span>{`${t.enter_six_digit_code_sent}`} </span><span style={{ whiteSpace: 'nowrap' }}>{` +91 ${mobileNumber}`}</span></>,
    image: '',
  };

  /**
   * @method useEffect
   * @description React hook that sets the model content when the component mounts
   */
  useEffect(() => {
    setModelContent(modelContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @constant OtpVerificationSchema
   * @description The form validation schema for OTP verification
   */
  const OtpVerificationSchema = Yup.object().shape({
    OTP: Yup.string().required(t.validation_messages.required)
    .matches(/^[0-9]{6}$/, t.validation_messages.invalid_otp),
  });

  /**
   * @method handleSubmit
   * @description Handles the form submission for OTP verification and proceeds to the next step
   * @param {Object} values - The form values containing the OTP
   * @param {Object} formikBag - The Formik form handler methods
   */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    try {
      toggleLoader();
      const hfSessionKey = await getCDSPixelId();
      const geoData = getGeoData();
      console.log('hfSessionKey', hfSessionKey)
      const requestData = {
        MobileNo: mobileNumber,
        OTP: values.OTP,
        HfSession: hfSessionKey,
        ...geoData
      };
      MetaPixel('VerifyOTP');
      TTDPixel('ruxrrt1');
      TwitterPixel('tw-ofua3-ofvcq');
      console.log('otpdata', requestData);
      const response = await verifyOtp(requestData);
      if (response && response.status === 200) {
        let data = response.data && response.data.Data;
        if (data && data?.Id) {
          let token = data.Token
          console.log('%%token', token)
          setToken(token)
          setUserDetails(data);
          handleClose()
          let type = actionType && actionType.type
          let uniqueCode = actionType && actionType.Code ? actionType.Code : ''
          triggerActionAfterLogin({ type: type, isLoggedIn: true, Code: uniqueCode })
          navigate('/')
        } else {
          nextStep({
            mobile: mobileNumber,
          });
        }
      }
    } catch (error) {
      handleErrorMessages(error, setFieldError)
    }
    setTimeout(() => {
      toggleLoader();
    }, 500); // Add a small delay before hiding the loader (500ms in this example)
  };

  /**
   * @method handleResendOTP
   * @description Handles the resend OTP request
   */
  /**
   * @method handleResendOTP
   * @description Handles the resend OTP request and clears the OTP field
   */
  const handleResendOTP = async (formikBag) => {
    MetaPixel('resend_otp');
    const requestData = {
      MobileNo: mobileNumber,
    };
    try {
      toggleLoader();
      await sendOtp(requestData);
      formikBag.resetForm(); // Reset the form to clear the OTP field
      // Handle the response data if needed
    } catch (error) { }
    setTimeout(() => {
      toggleLoader();
    }, 500); // Add a small delay before hiding the loader (500ms in this example)
  };

  /**
   * @method handleGoBack
   * @description Handles going back to the previous step
   */
  const handleGoBack = () => {
    console.log('previous step')
    previousStep(2);
  };

  return (
    <>
      <Loader />
      <div className="backArrow">
        <BsArrowLeft
          size={30}
          style={{
            cursor: 'pointer',
          }}
          onClick={handleGoBack}
        />
      </div>

      <Formik
        validationSchema={OtpVerificationSchema}
        initialValues={{ OTP: '' }}
        onSubmit={handleSubmit}
        //validateOnChange={false}
        validateOnBlur={false}>
        {({ handleSubmit, handleChange, values, errors, resetForm }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Form.Group controlId="OTP" className="mb-1">
              <Form.Label style={{ color: 'white', fontWeight: 'bold' }}>
                {t.enter_otp}
              </Form.Label>
              <Field
                type="text"
                name="OTP"
                className={`form-control ${errors.OTP ? 'is-invalid' : ''}`}
                value={values.OTP}
                onChange={handleChange}
                //onKeyPress={handleKeyPressotp}
                onInput={handleKeyPressotp}
                style={{ border: '1px solid #ccc' }}
              />
              {errors.OTP && <div className="error_message">{errors.OTP}</div>}
            </Form.Group>
            {errors.Message && (
              <div className="error_message error_red">{errors.Message}</div>
            )}

            <Button variant="primary" type="submit" block className="my-4 otpcontinuebtn"
              onClick={() => {
                CounterFloodLight('DC-12665261/thums0/thums01s+unique');
                // MetaPixel('Continue');
              }}
            >
              {t.continue}
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleResendOTP({ resetForm })}
              style={{
                fontSize: '20px',
                display: 'block',
                background: 'none',
                padding: '0',
                margin: '0 auto',
                textDecoration: 'underline',
              }}
              className='otpresendbtn'
            >
              {t.resend_otp}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default OptVerificationForm;
