import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { sendClaimMerch, sendClaimTicket } from "../../actions/Auth";
import {
  getUserDetails, setClaimFormDetailsCache, getPreFilledClaimFormDetailsCache,
  getCDSPixelId
  , CounterFloodLight,
  MetaPixel,
  TwitterPixel
} from '../../utils/helper'
import { LoaderContext } from "../loader/LoaderContext";
import { MultiSelect } from "react-multi-select-component";
import Loader from "../loader";
import { State, City } from 'country-state-city';
import { handleKeyPressName, handleKeyPressMobileNumber, handleKeyPressotp, handleKeyPressAddress, handleErrorMessages, filterCity,getErrorMessage } from '../../utils/helper'
import LanguageContext from "../../utils/LanguageContext";
import { privacy_policy, terms_condition, venueList } from "../../config/constant";
import { Link } from "react-router-dom";
import {
  getUserDetailsById,
} from "../../actions/Auth";
import { getGeoData } from "../../utils/cdsHelper";
import ReactGA from 'react-ga';


const ipData = localStorage.getItem("ipDetails")
  ? JSON.parse(localStorage.getItem("ipDetails"))
  : null;


const ClaimTicket = ({ t, handleClose, show, source, toofaniWinMerchaindise, toofaniWinTicket, coinType = '', redeemMerchModel, claimFail, arCtaAction = '', type = '', handleCross }) => {


  let user_details = getUserDetails();
  let claimFormDetails = getPreFilledClaimFormDetailsCache();
  const { toggleLoader } = useContext(LoaderContext);
  const [indianState, setIndianState] = useState([]);
  const [userData, setUserData] = useState(user_details)
  const [indianCity, setIndianCity] = useState([]);
  const errorMessageRef = useRef(null);
  const [allStateList, setAllStateList] = useState(false);


  //Create a memoized version of the data fetching function
  const fetchStatesAndCities = useCallback(() => {
    const states = State.getStatesOfCountry("IN");
    const stateName = (user_details && user_details.State) || ipData?.regionName;
    const state = states.find((s) => s.name === stateName);
    const cities = state
      ? City.getCitiesOfState("IN", state.isoCode)
      : City.getCitiesOfCountry("IN");
    return {
      indianCity: cities.map((city) => ({
        label: city.name,
        value: city.name,
      })),
      indianState: states.map((state) => ({
        label: state.name,
        value: state.name,
      })),
      allStateList: states,
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  // fetch initial city of state
  const fetchCities = useCallback((stateCode = "") => {
    const cities = City.getCitiesOfState("IN", stateCode);

    const indianCity = cities.map((city) => ({
      label: city.name,
      value: city.name,
    }));

    setIndianCity(indianCity);
  }, []);

  useEffect(() => {
    const { indianCity, indianState, allStateList } = fetchStatesAndCities();
    setIndianCity(indianCity);
    setIndianState(indianState);
    setAllStateList(allStateList);
    // getUserData()

  }, [fetchStatesAndCities]);

  // // get user data for showing default values
  // const getUserData = async () => {
  //   if (user_details) {
  //     const response = await getUserDetailsById({
  //       Id: user_details && user_details.Id,
  //     });
  //     if (response && response.status === 200) {
  //       let data = response && response.data && response.data.Data;
  //       console.log(data, '111111111111')
  //       setUserData(data)
  //     }
  //   }
  // };

  // field level validation for merch claim form
  const validationSchema = Yup.object().shape({
    Name: Yup.string().required(t.validation_messages.name_required)
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name),
    MobileNo: Yup.string().required(t.validation_messages.mobile_required)
      .matches(/^\d{10}$/, t.validation_messages.invalid_mobile_number),
    Email: Yup.string().email(`${t.validation_messages.invalid_email}`).nullable(),
    AddressLine1: Yup.string().required(t.validation_messages.address1_required),
    AddressLine2: Yup.string().nullable(),
    State: Yup.string().required(t.validation_messages.state_required),
    City: Yup.string().required(t.validation_messages.city_required),
    Pincode: Yup.string().length(6, t.validation_messages.pin_code_6digit).required(t.validation_messages.pin_required),
  });

  // field level validation for ticket claim form
  const validationSchemaTicket = Yup.object().shape({
    Name: Yup.string().required(t.validation_messages.name_required)
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name),
    MobileNo: Yup.string().required(t.validation_messages.mobile_required)
      .matches(/^\d{10}$/, t.validation_messages.invalid_mobile_number),
    Email: Yup.string().email(`${t.validation_messages.invalid_email}`).nullable(),
    AddressLine1: Yup.string().required(t.validation_messages.address1_required),
    AddressLine2: Yup.string().nullable(),
    State: Yup.string().required(t.validation_messages.state_required),
    City: Yup.string().required(t.validation_messages.city_required),
    Pincode: Yup.string().length(6, t.validation_messages.pin_code_6digit).required(t.validation_messages.pin_required),
    // Preference1: Yup.string().required(t.validation_messages.venue1_required),
    // Preference2: Yup.string().required(t.validation_messages.venue2_required),
    // Preference3: Yup.string().required(t.validation_messages.venue3_required),
  });

  /**
 * Handle form submission
 * @param {Object} values - Form values
 * @param {Function} resetForm - Formik's resetForm function
 * @param {Function} setFieldError - Formik's setFieldError function
 */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    console.log('values', values)
    try {
      toggleLoader();
      const hfSessionKey = await getCDSPixelId();
      let geoData = getGeoData();
      const requestData = {
        UserId: user_details?.Id,
        Name: values.Name,
        MobileNo: values.MobileNo,
        Email: values.Email,
        AddressLine1: values.AddressLine1,
        AddressLine2: values.AddressLine2,
        State: values.State,
        City: values.City,
        HfSession: hfSessionKey,
        ...geoData,
        Pincode: values.Pincode,
      };
      const requestDataClaimTicket = {
        ...requestData,
        Preference1: values.Preference1,
        Preference2: values.Preference2,
        Preference3: values.Preference3
      };
      // set the filled latest data by user in cache so that it can be used for next time
      setClaimFormDetailsCache(requestData);
      MetaPixel('ClaimForm');
      let response;
      console.log(requestData, 'requestDataClaimTicket', requestDataClaimTicket, source)
      if (source === "merch") {
        TwitterPixel('tw-ofua3-ofvbf');
        // requestData.Coins = coinType ? coinType : 0
        // requestData.IsMerchUsingVictoryCoin = coinType ? true : false
        response = await sendClaimMerch(requestData);
        CounterFloodLight('DC-12665261/thums0/thums02n+unique');
        MetaPixel('DetailClaimMerch');
        MetaPixel('SubmitMerchForm');
        if (response && response.status === 200) {
          handleClose(); // Close the modal after success
          resetForm();
          toofaniWinMerchaindise();
          // ReactGA.event({
          //   category: 'Form',
          //   action: 'submit_success',
          //   label: `tu_merchwinner`
          // });
          console.log('ga events')
          // window.gtag('event', 'Button Click', {
          //   event_category: 'tu_merchwinner',
          //   event_action: 'tu_merchwinner',
          //   event_label: 'tu_merchwinner',
          // });
          window.dataLayer.push({ event: 'tu_merchwinner' })
        }
      } else if (source === 'ticket') {
        TwitterPixel('tw-ofua3-ofvbg');
        CounterFloodLight('DC-12665261/thums0/thums02o+unique');
        response = await sendClaimTicket(requestDataClaimTicket);
        MetaPixel('DetailClaimTicket');
        MetaPixel('TicketClaimForm');
        if (response && response.status === 200) {
          handleClose(); // Close the modal after success
          resetForm();
          toofaniWinTicket()
          console.log('ga events')
          window.dataLayer.push({ event: 'tu_ticketwinner' })
        }
      }
    } catch (error) {
      let errorRes = error.response && error.response.data;
      if (errorRes.status === 709 || source === "merch") {
        handleCross();
        redeemMerchModel()
      }
      let errorarray = errorRes.Data && Array.isArray(errorRes.Data) && errorRes.Data.length ? errorRes.Data : '';
      if (errorarray) {
        // errorarray.map(el => {
        //   setFieldError(el.PropertyName, el.Message);
        //   return null; // Add a return statement here
        // });
        handleErrorMessages(error, setFieldError)
      } else {
        let message = getErrorMessage(errorRes.Message)
        if (arCtaAction && arCtaAction.show) {
          handleCross();
          if (source === 'ticket') {
            //claimFail(t.sorry, t.claimed_ticket)
            handleCross();
            claimFail(t.sorry, message);

          } else { 
            //claimFail(t.sorry, t.claimed_merch)
            handleCross();
            claimFail(t.sorry, message);
          }
        }else {
          handleCross();
          claimFail(t.sorry, message)
        }
        // setFieldError('Message', errorRes.Message);
        handleErrorMessages(error, setFieldError)
        errorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } finally {
      setTimeout(() => {
        toggleLoader();
      }, 500); // Add a small delay before hiding the loader (500ms in this example)
    }
  };


  /**
  * @method renderPartnershipForm
  * @description render partnership form fields
  */
  const renderClaimTicketForm = () => {
    console.log('claimFormDetails', claimFormDetails)
    return (
      <><Loader />
        <h2 className="claim-head">{source === 'ticket' ? t.enter_details_to_claim_tickets : t.details_claim_your_merch}</h2>
        <Formik
          validationSchema={source === 'ticket' ? validationSchemaTicket : validationSchema}
          initialValues={{
            Name: claimFormDetails?.Name || user_details?.Name || "",
            MobileNo: claimFormDetails?.MobileNo || user_details?.MobileNo || "",
            Email: claimFormDetails?.Email || user_details?.Email || "",
            State: claimFormDetails?.State || user_details?.State || "",
            City: claimFormDetails?.City || user_details?.City || "",
            Pincode: claimFormDetails?.Pincode || user_details?.Pincode || "",
            AddressLine1: claimFormDetails?.AddressLine1 || "",
            AddressLine2: claimFormDetails?.AddressLine2 || ""
          }}
          onSubmit={handleSubmit}
          //validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Form.Group controlId="name">
                <Form.Label>
                  {t.name}<span className="required">*</span>
                </Form.Label>
                <Field
                  type="text"
                  name="Name"
                  className={`form-control ${errors.Name ? "is-invalid" : ""}`}
                  // placeholder={t.enter_name}
                  value={values.Name}
                  maxLength={50}
                  //onKeyPress={handleKeyPressName}
                  onInput={handleKeyPressName}
                  onChange={handleChange} />
                {errors.Name && <div className="error_message">{errors.Name}</div>}
              </Form.Group>
              <Form.Group controlId="mobileNumber">
                <Form.Label>
                  {t.mobile_no_mandatory}<span className="required"></span>
                </Form.Label>
                <Field
                  type="text"
                  name="MobileNo"
                  className={`form-control ${errors.MobileNo ? "is-invalid" : ""}`}
                  // placeholder={t.enter_mobile_number}
                  value={values.MobileNo}
                  //onKeyPress={handleKeyPressMobileNumber}
                  onInput={handleKeyPressMobileNumber}
                  onChange={handleChange} />
                {errors.MobileNo && (
                  <div className="error_message">{errors.MobileNo}</div>
                )}
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{t.email} ({t.optional})</Form.Label>
                <Field
                  type="email"
                  name="Email"
                  className={`form-control ${errors.Email ? "is-invalid" : ""}`}
                  // placeholder={t.enter_email}
                  value={values.Email}
                  onChange={handleChange} />
                {errors.Email && (
                  <div className="error_message">{errors.Email}</div>
                )}
              </Form.Group>
              <Form.Group controlId="addressLine1">
                <Form.Label>
                  {t.address1}<span className="required">*</span>
                </Form.Label>
                <Field
                  type="text"
                  name="AddressLine1"
                  className={`form-control ${errors.AddressLine1 ? "is-invalid" : ""}`}
                  //placeholder={t.enter_address1}
                  value={values.AddressLine1}
                  onChange={handleChange}
                  //onKeyPress={handleKeyPressAddress}
                  onInput={handleKeyPressAddress}
                  maxLength={100}
                />
                {errors.AddressLine1 && (
                  <div className="error_message">{errors.AddressLine1}</div>
                )}
              </Form.Group>
              <Form.Group controlId="addressLine2">
                <Form.Label> {t.address2}</Form.Label>
                <Field
                  type="text"
                  name="AddressLine2"
                  className={`form-control ${errors.AddressLine2 ? "is-invalid" : ""}`}
                  // placeholder={t.enter_address2}
                  value={values.AddressLine2}
                  onChange={handleChange}
                  maxLength={100}
                />
                {errors.AddressLine2 && (
                  <div className="error_message">{errors.AddressLine2}</div>
                )}
              </Form.Group>
              <Form.Group controlId="state" className="tu-select">
                <Form.Label>
                  {t.select_state}<span className="required">*</span>
                </Form.Label>
                <div className="tu-select-wrapper pb-0">
                  <Form.Control className="one"
                    as="select"
                    name="State"
                    value={values.State}
                    onChange={(e) => {
                      const v = e.target.value;
                      handleChange(e);
                      const stateData = allStateList.find(
                        (s) => s.name === v
                      );
                      setFieldValue("City", "");
                      if (stateData) {
                        fetchCities(stateData.isoCode);
                      }
                    }}
                  >
                    <option value=""></option>
                    {indianState.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.State && <div className="error_message">{errors.State}</div>}
                </div>
              </Form.Group>
              <Form.Group controlId="city" className="tu-select">
                <Form.Label>
                  {t.city_mandatory}<span className="required">*</span>
                </Form.Label>
                <div className="tu-select-wrapper pb-0">
                  <Form.Control
                    as="select"
                    name="City"
                    value={values.City}
                    onChange={handleChange}
                    className={`form-control ${errors.City ? "is-invalid" : ""}`}
                  >
                    <option value=""></option>
                    {/* {indianCity.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))} */}
                    {
                      indianCity && filterCity(indianCity)?.map((s, i) => {
                        let label = s.value
                        if (s.value === 'Bangalore') {
                          label = 'Bengaluru'
                        }
                        if (s.value === 'Gurgaon') {
                          label = 'Gurugram'
                        }
                        return (
                          <option key={`${s.value}_${i}`} value={label}>
                            {label}
                          </option>
                        )
                      })}
                  </Form.Control>
                  {errors.City && <div className="error_message">{errors.City}</div>}
                </div>
              </Form.Group>
              <Form.Group controlId="pincode">
                <Form.Label>
                  {t.pincode_mandatory}<span className="required">*</span>
                </Form.Label>
                <Field
                  type="text"
                  name="Pincode"
                  className={`form-control ${errors.Pincode ? "is-invalid" : ""}`}
                  //placeholder={t.enter_pincode}
                  value={values.Pincode}
                  //onKeyPress={handleKeyPressotp}
                  onInput={handleKeyPressotp}
                  onChange={handleChange} />
                {errors.Pincode && (
                  <div className="error_message">{errors.Pincode}</div>
                )}
              </Form.Group>
              {/* {source === "ticket" && <Form.Group controlId="Preference1" className="tu-select mb-0">
                <Form.Label>
                  {t.select_venue}<span className="required">*</span>
                </Form.Label>
                <div className="tu-select-wrapper">
                  <Form.Control
                    as="select"
                    name="Preference1"
                    value={values.Preference1}
                    onChange={handleChange}
                    className={`form-control ${errors.Preference1 ? "is-invalid" : ""}`}
                  >
                    <option value="">{t.venue1_select}</option>
                    {venueList.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.Preference1 && <div className="error_message">{errors.Preference1}</div>}
                </div>
                <div className="tu-select-wrapper">
                  <Form.Control
                    as="select"
                    name="Preference2"
                    value={values.Preference2}
                    onChange={handleChange}
                    className={`form-control ${errors.Preference2 ? "is-invalid" : ""}`}
                  >
                    <option value="">{t.venue2_select}</option>
                    {venueList.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.Preference2 && <div className="error_message">{errors.Preference2}</div>}
                </div>
                <div className="tu-select-wrapper">
                  <Form.Control
                    as="select"
                    name="Preference3"
                    value={values.Preference3}
                    onChange={handleChange}
                    className={`form-control ${errors.Preference3 ? "is-invalid" : ""}`}
                  >
                    <option value="">{t.venue3_select}</option>
                    {venueList.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.Preference3 && <div className="error_message">{errors.Preference3}</div>}
                </div>
              </Form.Group>} */}
              {/* red error msg */}
              {/* {source === 'ticket' && <p>
                {t.claim_form_desclaimer}
              </p>} */}
              <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit" block  className={source === 'ticket' ? `mt-2 winnerclaimsubmitticketbtn` : source === 'merch' ? 'mt-2 winnerclaimsubmitmerchbtn' : ''}>
                  {t.submit}
                </Button>
              </div>
              {/* {errors.Message && (
                <div className="error_message error_red" ref={errorMessageRef}>
                  {errors.Message}
                </div>
              )} */}
            </Form>
          )}
        </Formik></>
    );
  };

  return (
    <Modal
      size="md"
      className="tu-common-modal refer-modal modal-claim-ticket"
      show={show}
      onHide={handleCross}
      centered
    >
      <div
        className="modal-bg"
      >
        <Modal.Header>
          <img
            className="cross-button"
            src="assets/images/close.png"
            alt="Close"
            onClick={handleCross}
            style={{ 'cursor': 'pointer' }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className={`m-0 model_top_1`}>
            <Col xs={4} className="px-0">
              <img
                className="modal-left-img"
                src={"assets/images/modal-left-img.png"}
                alt="Modal Left"
              />
            </Col>
            <Col xs={8} className="pl-3">
              <div className="modal-right-section">
                {renderClaimTicketForm()}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <div>
          <footer
            className="text-center"
          >
            <div className="mandatory-text mt-4">{t.mandatory_fields} </div>
            <div className="display-2 privacy">
              <Link to={terms_condition} target={'_blank'}>{t.terms_condition}</Link> | <Link to={privacy_policy} target={'_blank'}>{t.privacy_policy}</Link>
            </div>
          </footer>
        </div>
      </div>
    </Modal>
  );
};

export default ClaimTicket;
