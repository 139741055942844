/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { NavDropdown, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  checkmatchClaimedToday,
  savematchCoinForProfileComplete,
  getUserDetailsById,
  updateProfileName,
  updateProfile,
  updateProfilePinCode,
  updateProfileAge,
  updateProfileGender,
  updateProfileEmail,
  updateProfileStateAndCity,
  getWalletDetailsById,
  saveMerchWinner
} from "../../actions/Auth";
import LanguageContext from "../../utils/LanguageContext";
import { LoaderContext } from "../loader/LoaderContext"; // Import the LoaderContext
import InfoModal from "../model/InfoModal"; // Import the pop-up component
import {
  setUserDetails,
  handleKeyPressName,
  handleKeyPressotp,
  handleKeyPressAge,
  handleKeyPressRestrictNumberAndSpecialCharacter,
  capitalizeFirstLetter,
  getUserDetails,
  getCDSPixelId,
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  filterCity,
  handleErrorMessages,
  getGender,
  TwitterPixel,
  getErrorMessage
} from "../../utils/helper";
import {
  avatarImages,
  forbiddenKeywords,
  forbiddenCharactersRegex,
} from "../../config/constant";
import MyWallet from "../common/MyWallet";
//import MyWallet from '../common/NewMyWallet';
import ClaimTicket from "../model/ClaimTicket";
import { handleShare } from "../../utils/helper";
import { State, City } from "country-state-city";
//import { profileFieldValidation } from "../../utils/validations";
import CommonContext from '../contextApi';
import { ClickButtonCDP, fieldCDP } from "../../utils/cdsHelper";
import { claimMerchSuccess, claimTicketSuccess, merchClaimError, redeemErrorModel, fiveMatchCoinCollected,profileCompletedSuccess } from "../../utils/modelHelper";

// Regular expression to match special characters

function ProfileMenu({ t, walletData, setShowLoginModal }) {

  const { actionType, actionTriggered, triggerAction, arCtaAction, setArCtaAction, redirectname } = useContext(CommonContext);
  let userDetails = getUserDetails();
  console.log('City', userDetails)

  const { language } = useContext(LanguageContext);
  const { toggleLoader } = useContext(LoaderContext);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [editingGender, setEditingGender] = useState(false);
  const [editingCity, setEditingCity] = useState(false);
  const [editingState, setEditingState] = useState(false);
  const [editingPinCode, setEditingPinCode] = useState(false);
  const [editingAge, setEditingAge] = useState(false);
  const [showWallet, setShowMyWallet] = useState(false);
  const [showClaimTicket, setShowClaimTicket] = useState(false);
  const [ticketModel, setTicketModel] = useState(false);
  const [fielderror, setFieldError] = useState("");
  const [profileImage, setprofileImage] = useState();
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userData, setUserData] = useState(userDetails);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);//show profile menu
  const [allStateList, setAllStateList] = useState(false);
  const [indianState, setIndianState] = useState([]);
  const [indianCity, setIndianCity] = useState([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [walletDetails, setWalletDetails] = useState(walletData);

  const [popup, setPopupInfo] = useState({
    title: "",
    content: "",
  }); // State to manage the pop-up properties
  const tooltipRef = useRef(null);
  //let victoryCoin = walletDetails && walletDetails.VictoryCoinDetail;
  let matchCoins = walletDetails && walletDetails.MatchCoin && walletDetails.MatchCoin.Coins;
  //let ableToClaimTicket = walletDetails && walletDetails.TicketClaimed !== 2 && walletDetails.Ticket !== 0 && walletDetails.Ticket > walletDetails.TicketClaimed
  let ableToClaimSilverCoin = walletDetails && walletDetails.SilverClaimed !== 1 && walletDetails.Silver !== 0 && walletDetails.Silver > walletDetails.SilverClaimed
  let ableToRedeemMerch = matchCoins >= 5 && walletDetails && walletDetails.Merch !== 2
  //let ableToClaimMerch = walletDetails && (walletDetails.MerchClaimed !== 2 && walletDetails.Merch !== 0)

  console.log('ableToClaimMerch', walletData)

  const initialValues = {
    name: userDetails && userDetails.Name,
    email: userDetails && userDetails.Email,
    gender: userDetails && userDetails.Gender,
    city: (userDetails && userDetails.City),
    state: (userDetails && userDetails.State),
    pinCode:
      userDetails && userDetails.Pincode !== 0 ? userDetails.Pincode : "",
    mobile: userDetails && userDetails.MobileNo,
    age: userDetails && userDetails.Age !== 0 ? userDetails.Age : "",
    ProfileImg: userDetails !== undefined && userDetails && userDetails?.ProfileImg,
  };

  const [initialValue, setInitialValue] = useState(initialValues);

  useEffect(() => {
    if (arCtaAction && arCtaAction.show) {
      let ticket_type = arCtaAction.cta_name
      let type = ticket_type === 'claim_ticket' ? 'ticket' : ticket_type === 'claim_merch' ? 'merch' : ''
      if (type === 'ticket' || type === 'merch') {
        setShowClaimTicket(true)
        setTicketModel(type)
      }
    }
    if (actionType && actionType.isLoggedIn) {
      if (actionType.type === "claim_ticket" || actionType.type === "claim_merch") {
        let type = actionType.type === 'claim_ticket' ? 'ticket' : actionType.type === 'claim_merch' ? 'merch' : ''
        setShowClaimTicket(true);
        setTicketModel(type)
      }
    }
    if (userDetails && redirectname && redirectname.show) {
      if (redirectname.url_name === 'claim_ticket') {
        setShowClaimTicket(true)
        setTicketModel('ticket')
      } else if (redirectname.url_name === 'claim_merch') {
        setShowClaimTicket(true)
        setTicketModel('merch')
      }
    }
  }, [arCtaAction, walletData, redirectname])

  useEffect(() => {
    setInitialValue(initialValues);
    setprofileImage(userDetails.ProfileImg);
    getUserData();
    setprofileImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // fetch state intially
  const fetchStatesAndCities = useCallback(() => {
    const states = State.getStatesOfCountry("IN");
    const stateName = (userDetails && userDetails.State);
    const state = states.find((s) => s.name === stateName);

    const cities = state
      ? City.getCitiesOfState("IN", state.isoCode)
      : City.getCitiesOfCountry("IN");

    return {
      indianCity: cities.map((city) => ({
        label: city.name,
        value: city.name,
        latitude: city.latitude,
        longitude: city.longitude,
        stateCode: city.stateCode
      })),
      indianState: states.map((state) => ({
        label: state.name,
        value: state.name,
      })),
      allStateList: states,
    };
  }, []);

  console.log('###cities', filterCity(indianCity))

  // fetch city initially
  const fetchCities = useCallback((stateCode = "") => {
    const cities = City.getCitiesOfState("IN", stateCode);


    const indianCity = cities.map((city) => ({
      label: city.name,
      value: city.name,
      latitude: city.latitude,
      longitude: city.longitude,
      stateCode: city.stateCode
    }));

    setIndianCity(indianCity);
  }, []);

  // fetch city satate and user data
  useEffect(() => {
    const fetchData = async () => {
      const { indianCity, indianState, allStateList } = fetchStatesAndCities();
      await getWalletDetails();
      setIndianCity(indianCity);
      setIndianState(indianState);
      setAllStateList(allStateList);
      if (actionTriggered) {
        setShowDropdown(true);
      }
    };

    fetchData();
  }, [actionTriggered, fetchStatesAndCities]);

  // handle out side click event for profile dropdown
  useEffect(() => {
    function handleClickOutside(event) {

      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsTooltipOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getWalletDetails = async () => {
    let requestData = {
      Id: userDetails && userDetails.Id,
    };
    try {
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setWalletDetails(data);
      }
    } catch (error) {
      console.error("Error updating profile name:", error);
      // Handle the error as needed
    }
  };

  //save merch winner api 
  const saveMerchWinnerAPI = async () => {
    let requestData = {
      UserId: userDetails && userDetails.Id,
      Coin: 0,
      IsMerchUsingVictoryCoin: false
    }
    try {
      const response = await saveMerchWinner(requestData);
      if (response.status === 200) {

      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  }

  /**
   * @method checkClaimedOrNot
   * @description check if user cliamed or not
   */
  const checkClaimedOrNot = async () => {
    let requestData = {
      Id: userDetails && userDetails.Id,
    };
    try {
      toggleLoader();
      const response = await checkmatchClaimedToday(requestData);
      if (response.status === 200) {
        //if user not already redeem or claim than able to claim
        console.log('merch &&&&', matchCoins, walletDetails.Merch)
        if (matchCoins >= 5 && walletDetails.Merch < 2) {
          saveMerchWinnerAPI()
          setTicketModel("merch");
          setShowClaimTicket(true)
        } else {
          claimedAllMerch();
        }
      }
    } catch (error) {
      let errorRes = error.response && error.response.data && error.response.data
      console.error(error, 'error');
      if (errorRes.ErrorCode === 709) {
        redeemMerchModel();
      } else {
        let message = getErrorMessage(errorRes.Message)
        apiErrorModel(message)
      }
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  // handle all type of api error
  const apiErrorModel = (message) => {
    setPopupInfo({
      title: (<h2>{''}</h2>),
      content: (<h4>{message}<br /></h4>),
      image: '',
      className: 'incorrect-noball no_ball',
    });
  }

  /**
   * @method claimedAllMerch
   * @description you have claimed all the merch 2/2
   */
  const claimedAllMerch = () => {
    setPopupInfo(merchClaimError(t));
  };

  /**
   * @method matchCoinCollectionComplete
   * @description match coin collection complete model
   */
  const matchCoinCollectionComplete = () => {
    setPopupInfo(fiveMatchCoinCollected(t,closePopup, checkClaimedOrNot, false));
  };

  /**
   * @method redeemMerchModel
   * @description model will show if user already redeem and this was close
   */
  const redeemMerchModel = () => {
    setPopupInfo(redeemErrorModel(t))
  };

  /**
  * @method claimAllTicket
  * @description model will open if you claim all the tickets
  */
  const claimFail = (content1, content2) => {
    setPopupInfo({
      title: <h2>{content1}</h2>,
      content: (
        <div>
          <h3 className="h4 px-5 px-sm-3">{content2}</h3>
          {/* <h4>{t.redeeming_tomorrow}</h4> */}
        </div>
      ),
      image: "",
      className: "no_ball redeem-merch",
    });
  };



  /**
   * @method toofaniWinTicket
   * @description toofani win ticket model
   */
  const toofaniWinTicket = () => {
    setPopupInfo(claimTicketSuccess(t, language));
  };

  // merch claim win success model
  const toofaniWinMerchaindise = () => {
    setPopupInfo(claimMerchSuccess(t, language));
  };

  // get wallet data from api
  const getWalletData = async () => {
    const requestData = {
      Id: userDetails && userDetails.Id,
    };
    try {
      const response = await getWalletDetailsById(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        savematchCoin(data)
        setWalletDetails(data)
      }
    } catch (error) {
      console.error("Error updating profile name:", error);
      // Handle the error as needed
    }
  }

  /**
   * Save match coin for completing the profile
   */
  const savematchCoin = async (data) => {
    MetaPixel('CompleteProfile');
    let matchCoins = data && data.MatchCoin && data.MatchCoin.Coins;
    console.log(matchCoins, 'data((((((', data)
    try {
      toggleLoader();
      const requestData = {
        Id: userDetails && userDetails.Id,
      };
      const response = await savematchCoinForProfileComplete(requestData);
      if (response && response.status === 200) {
        if (matchCoins >= 5) {
          matchCoinCollectionComplete()
        } else {
          profileCompleted();
        }
      }
    } catch (error) { }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * Fetch user data from the server and update the state with the retrieved details
   */
  const getUserData = async () => {
    if (userDetails) {
      const response = await getUserDetailsById({
        Id: userDetails && userDetails.Id,
      });
      if (response && response.status === 200) {
        let data = response && response.data && response.data.Data;
        const userDataInCache = getUserDetails();
        if (userDataInCache) {
          if (userDataInCache?.AcceptReferred < data?.AcceptReferred) {
            localStorage.setItem('newReferCompleted', true);
          }
          else if (userDataInCache?.AcceptReferred === data?.AcceptReferred) {
            localStorage.setItem('newReferCompleted', false);
          }
        }
        setUserDetails(data);
        setUserData(data);
        setprofileImage(data.ProfileImg);
        let initialData = {
          name: data && data.Name,
          email: data && data.Email,
          gender: data && data.Gender,
          city: (data && data.City),
          state: (data && data.State),
          pinCode: data && data.Pincode,
          mobile: data && data.MobileNo,
          age: data && data.Age,
          ProfileImg: data && data.ProfileImg,
        };
        setInitialValue(initialData);
        if (
          data.ProfilePercent === 100 &&
          !data.IsAlreadyProfileCompleteCoinWon
        ) {
          getWalletData()
          setShowDropdown(false);
        }
      }
    }
  };

  // field level validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t.validation_messages.name_required)
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name),
    Email: Yup.string()
      //.email(t.validation_messages.invalid_email),
      .email(t.validation_messages.email_required)
      .test("is-valid-email", t.validation_messages.invalid_email, (value) => {
        // Custom email validation logic
        if (!value) return true; // Allow empty value
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
    gender: Yup.string().required(t.validation_messages.gender_required),
    city: Yup.string().required(t.validation_messages.city_required),
    state: Yup.string().required(t.validation_messages.state_required),
    pinCode: Yup.string().required(t.validation_messages.pin_required),
    mobile: Yup.string().required(t.validation_messages.mobile_required),
    age: Yup.string().required(t.validation_messages.age_required),
  });

  const profileFieldValidation = {
    Name: Yup.object({
      Name: Yup.string().required(t.validation_messages.name_required)
        .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name),
    }),
    Email: Yup.object({
      Email: Yup.string().required(t.validation_messages.email_required)
        .test('is-valid-email', t.validation_messages.invalid_email, value => {
          // Custom email validation logic
          if (!value) return true; // Allow empty value
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value);
        }),
    }),
    Gender: Yup.object({
      Gender: Yup.string().required(t.validation_messages.gender_required),
    }),
    City: Yup.object({
      City: Yup.string().required(t.validation_messages.city_required),
    }),
    State: Yup.object({
      State: Yup.string().required(t.validation_messages.state_required),
    }),
    StateCity: Yup.object({
      State: Yup.string().required(t.validation_messages.state_required),
      City: Yup.string().required(t.validation_messages.city_required),
    }),
    Pincode: Yup.object({
      Pincode: Yup.string()
        .length(6, t.validation_messages.pin_code_6digit)
        .required(),
    }),
    MobileNo: Yup.object({
      MobileNo: Yup.string()
        .length(10, t.validation_messages.mobile_10digit)
        .required(t.validation_messages.mobile_required),
    }),
    Age: Yup.object({
      Age: Yup.string()
        .test("age-test", t.validation_messages.age_limit, (value) => {
          console.log("value: ", value);
          if (isNaN(Number(value))) {
            return false;
          } else if (Number(value) === 0) {
            return false;
          } else if (Number(value) < 18) {
            return false;
          } else if (Number(value) > 100) {
            return false;
          }
          return true;
        })
        .required(t.validation_messages.age_required),
    }),
    ProfileImg: Yup.object({
      ProfileImg: Yup.string().required(t.validation_messages.profile_required),
    }),
  };


  const handleSubmit = (values) => { };

  /**
   * Display the profile completion popup
   */
  const profileCompleted = () => {
    setPopupInfo(profileCompletedSuccess(t,setShowMyWallet,closePopup));
  };

  /**
   * Handle editing the profile image
   * @param {string} image - The new profile image
   */
  const handleEditProfileImage = async (image) => {
    try {
      toggleLoader();
      const requestData = {
        Id: userDetails && userDetails.Id,
        ProfileImg: image,
      };
      const response = await updateProfile(requestData); // Make the API call to update the profile image
      if (response && response.status === 200) {
        getUserData()
        setShowDropdown(true)
      }
    } catch (error) {
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * Handle editing a field in the user profile
   * @param {string} field - The field to edit
   * @param {boolean} editingState - The current editing state of the field
   * @param {function} setEditingState - Function to update the editing state of the field
   * @param {function} updateFunction - Function to update the profile field
   */
  const handleEditField = async (
    field,
    editingState,
    setEditingState,
    updateFunction
  ) => {
    if (!editingState) {
      setEditingState(true);
    } else {
      const els = document.querySelectorAll(".tu-user-input.edit");
      let fieldVal = "";
      for (let c of els) {
        if (c.name?.toLowerCase() === field?.toLocaleLowerCase()) {
          fieldVal = c.value;
        }
      }

      const hfSessionKey = await getCDSPixelId();
      const requestData = {
        [field]: fieldVal,
        Id: userDetails && userDetails.Id,
        HfSession: hfSessionKey
      };

      // validate field
      try {
        const isValid = profileFieldValidation[field].validateSync(
          {
            [field]: fieldVal,
          },
          { strict: true }
        );
        fieldCDP(`Edit_${field}`, field, fieldVal);
      } catch (err) {
        const errorMsg = err.message;
        setFieldError({ [field]: errorMsg });
        return;
      }

      try {
        toggleLoader();
        const response = await updateFunction(requestData);

        setEditingState(false);
        getUserData();
        setFieldError("");
        setEditingState(false);
      } catch (error) {
        // let errorRes = error.response && error.response.data;
        // let errorarray =
        //   errorRes?.Data && Array.isArray(errorRes.Data) && errorRes.Data.length
        //     ? errorRes.Data
        //     : "";
        // if (errorarray) {
        //   errorarray.map((el) => {
        //     setFieldError({ [el.PropertyName]: el.Message });
        //     return null;
        //   });
        // } else {
        //   //toast.error(errorRes.Message)
        //   setFieldError({ Message: errorRes?.Message });
        // }
        handleErrorMessages(error, setFieldError)
      }
      setTimeout(() => {
        toggleLoader();
      }, 500);
    }
  };

  // manage city and state update in one api call
  const handleEditStateCityField = async () => {
    if (!editingState && !editingCity) {
      setEditingState(true);
      setEditingCity(true);
      return;
    }

    const els = document.querySelectorAll(".tu-user-input.edit");
    let state = "";
    let city = "";
    for (let c of els) {
      if (c.name?.toLowerCase() === "state") {
        state = c.value;
      }
      if (c.name?.toLowerCase() === "city") {
        city = c.value;
      }
    }

    let selectedCity = indianCity.find(el => el.value === city)

    const requestData = {
      state,
      city,
      UserId: userDetails && userDetails.Id,
      Latitude: selectedCity && selectedCity.latitude,
      Longitude: selectedCity && selectedCity.longitude,
    };

    // validate field
    let errors = {};
    try {
      profileFieldValidation["State"].validateSync(
        {
          State: state,
        },
        { strict: true }
      );
    } catch (err) {
      const errorMsg = err.message;
      console.log("errorMsg: ", errorMsg);
      errors.State = errorMsg;
    }
    try {
      profileFieldValidation["City"].validateSync(
        {
          City: city,
        },
        { strict: true }
      );
    } catch (err) {
      const errorMsg = err.message;
      console.log("errorMsg: ", errorMsg);
      errors.City = errorMsg;
    }

    if (Object.keys(errors).length) {
      setFieldError(errors);
      return;
    }

    try {
      toggleLoader();
      console.log('requestData', requestData)
      const response = await updateProfileStateAndCity(requestData);
      getUserData();
      setFieldError("");
      setEditingState(false);
      setEditingCity(false);
      fieldCDP('Edit_State', 'State', state);
      fieldCDP('Edit_City', 'City', city);
    } catch (error) {
      handleErrorMessages(error, setFieldError)
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * Handle editing the name field in the user profile
   */
  const handleEditName = () => {
    handleEditField("Name", editingName, setEditingName, updateProfileName);
  };

  /**
   * Handle editing the gender field in the user profile
   */
  const handleEditGender = () => {
    handleEditField(
      "Gender",
      editingGender,
      setEditingGender,
      updateProfileGender
    );
  };

  /**
   * Handle editing the city field in the user profile
   */
  const handleEditCity = () => {
    handleEditStateCityField();
  };

  /**
   * Handle editing the state field in the user profile
   */
  const handleEditState = () => {
    handleEditStateCityField();
  };

  /**
   * Handle editing the pincode field in the user profile
   */
  const handleEditPinCode = () => {
    handleEditField(
      "Pincode",
      editingPinCode,
      setEditingPinCode,
      updateProfilePinCode
    );
  };

  /**
   * Handle editing the email field in the user profile
   */
  const handleEditEmail = () => {
    handleEditField("Email", editingEmail, setEditingEmail, updateProfileEmail);
  };

  /**
   * Handle editing the age field in the user profile
   */
  const handleEditAge = () => {
    handleEditField("Age", editingAge, setEditingAge, updateProfileAge);
  };

  const closePopup = () => {
    setPopupInfo({
      title: "", // Reset the title
      content: "", // Reset the content
    });
  };

  const handleClose = () => {
    setShowMyWallet(false);
  };

  // handle image change 
  const handleImageSelection = (imageUrl, setFieldValue) => {
    console.log("imageUrl: ", imageUrl);
    setSelectedImage(imageUrl);
    setIsEditingImage(false);
    setFieldValue("ProfileImg", imageUrl); // Update the form field value with the selected image URL
    setprofileImage(imageUrl); // Update the profileImage state with the selected image URL
    setIsTooltipOpen(false); // Close the tooltip
    handleEditProfileImage(imageUrl);
  };

  // mask mobile number
  const getMaskedMobileNumber = (mobileNumber) => {
    const visibleDigits = 4; // Number of visible digits in the masked mobile number
    const visiblePart = mobileNumber.slice(0, visibleDigits);
    const maskedPart = "X".repeat(mobileNumber.length - visibleDigits);
    return visiblePart + maskedPart;
  };

  // validate age input
  const validateAgeInput = (value) => {
    if (forbiddenKeywords.some((keyword) => value?.includes(keyword))) {
      // Forbidden keyword found
      return false;
    }
    if (forbiddenCharactersRegex.test(value)) {
      // Forbidden character found
      return false;
    }

    return true;
  };

  // render image selection tooltip
  const renderTooltip = (setFieldValue) => (
    <Tooltip
      id="image-selection-tooltip"
      className="tooltip-profile-avatar"
      show={isTooltipOpen}
    >
      <div className="avatar-options" ref={tooltipRef}>
        {avatarImages.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Avatar ${index}`}
            className={`avatar-option ${selectedImage === imageUrl ? "selected" : ""
              }`}
            onClick={() => {
              handleImageSelection(imageUrl, setFieldValue);
              setIsTooltipOpen(false); // Close the tooltip
            }}
          />
        ))}
      </div>
    </Tooltip>
  );

  // reset profile states
  const resetEditing = () => {
    setEditingName(false)
    setEditingEmail(false)
    setEditingAge(false)
    setEditingCity(false)
    setEditingState(false)
    setEditingPinCode(false)
    setEditingGender(false)
  }

  // handle dropdown toggle
  const handleDropdownToggle = (isOpen) => {
    triggerAction(false)
    setShowDropdown(isOpen);
    getUserData();
    getWalletDetails();
    if (!isOpen) {
      resetEditing()
    }
  };

  return (
    <div>
      {popup.title && (
        <InfoModal
          title={popup.title} // Set the title for the pop-up
          content={popup.content} // Set the content for the pop-up
          onClose={closePopup} // Close the pop-up
          button={popup.button}
          image={popup.image}
          className={popup.className}
          topHeading={popup.topHeading}
        />
      )}
      <NavDropdown
        title={
          <img
            className="thumbnail-image"
            src={
              profileImage ? profileImage : "assets/images/profile-image.png"
            }
            alt="user pic"
            style={{ height: "37px", width: "37px" }}
          />
        }
        id="basic-nav-dropdown"
        className="tu-profile-dropdown"
        onToggle={handleDropdownToggle}
        show={showDropdown}
      >
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, handleChange, values }) => (
            <Form autoComplete="off">
              <ul className="tu-user-details">
                <li>
                  <div className="tu-input-wrapper">
                    <div className="avtatBox mb-1 w-100">
                      <OverlayTrigger
                        show={isTooltipOpen}
                        onToggle={setIsTooltipOpen}
                        overlay={renderTooltip(setFieldValue)}
                        trigger="click"
                      >
                        <div className="avatar-wrapper">
                          <img
                            className="thumbnail-image"
                            src={
                              profileImage
                                ? profileImage
                                : "assets/images/profile-image.png"
                            }
                            alt="user pic"
                            style={{ height: "50px", width: "50px" }}
                          />
                          {isEditingImage ? (
                            <div className="edit-icon-container">
                              <button
                                type="file"
                                accept="image/*"
                              ></button>
                            </div>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="tu-edit-btn avtar-select ml-auto"
                              >
                                <img
                                  src="assets/images/edit-icon.png"
                                  alt="edit icon"
                                />
                              </button>
                            </>
                          )}
                        </div>
                      </OverlayTrigger>
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <span>{t.avatar}</span>
                      </div> */}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.name}</span>
                    {editingName ? (
                      <>
                        <div>
                          <Field
                            type="text"
                            //placeholder="--"
                            name="name"
                            maxLength={50}
                            className="tu-user-input edit"
                            //onKeyPress={handleKeyPressName}
                            onInput={handleKeyPressName}
                          />
                          {fielderror && fielderror.Name && (
                            <div className="error_message tst">
                              {fielderror.Name}
                            </div>
                          )}
                        </div>
                        <button
                          type="button"
                          className="tu-save-btn"
                          onClick={handleEditName}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={
                            initialValue.name.length > 15
                              ? `${initialValue.name.substring(0, 15)}...`
                              : initialValue.name
                          }
                          className="tu-user-input"
                          disabled
                        />
                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditName}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.email_address}</span>
                    {editingEmail ? (
                      <>
                        <div>
                          <Field
                            type="text"
                            //placeholder="--"
                            name="email"
                            className="tu-user-input edit"
                          />
                          {fielderror && fielderror.Email && (
                            <div className="error_message">
                              {fielderror.Email}
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="tu-save-btn completeprofilebtn"
                          onClick={handleEditEmail}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          //value={initialValue.email}
                          value={initialValue.email.length > 20
                            ? `${initialValue.email.substring(0, 20)}...`
                            : initialValue.email}
                          className="tu-user-input"
                          disabled
                        />
                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditEmail}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.mobile_no}</span>
                    <input
                      type="text"
                      // placeholder="--"
                      name=""
                      value={(initialValue.mobile)}
                      className="tu-user-input"
                      disabled
                    />
                    <div className="d-none"></div>
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.age}</span>
                    {editingAge ? (
                      <>
                        <div>
                          <Field
                            type="text"
                            //placeholder="--"
                            name="age"
                            className="tu-user-input edit"
                            //onKeyPress={handleKeyPressAge}
                            onInput={handleKeyPressAge}
                          />
                          {fielderror && fielderror.Age && (
                            <div className="error_message">
                              {fielderror.Age}
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="tu-save-btn completeprofilebtn"
                          onClick={handleEditAge}
                          disabled={!validateAgeInput()}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={
                            initialValue.age !== 0 ? initialValue.age : "--"
                          }
                          className="tu-user-input"
                          disabled
                        />
                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditAge}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.gender}</span>

                    {editingGender ? (
                      <>
                        <div className="select-wrapper">
                          <div className="drop-select">
                            <Field
                              as="select"
                              name="gender"
                              className="form-control tu-user-input edit"

                            // onKeyPress={
                            //   handleKeyPressRestrictNumberAndSpecialCharacter
                            // }
                            >
                              <option value="">{t.select_gender}</option>
                              <option value="male">{t.M}</option>
                              <option value="female">{t.F}</option>
                              <option value="other">{t.O}</option>
                            </Field>

                            {fielderror && fielderror.Gender && (
                              <div className="error_message select_message">
                                {fielderror.Gender}
                              </div>
                            )}
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="tu-save-btn completeprofilebtn"
                          onClick={handleEditGender}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={
                            initialValue.gender ? getGender(initialValue.gender, t) : "--"
                          }
                          className="tu-user-input"
                          disabled
                        />

                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditGender}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.state}</span>
                    {editingState ? (
                      <>
                        <div className="select-wrapper">
                          <div className="drop-select">
                            <Field
                              as="select"
                              name="state"
                              className="form-control tu-user-input edit"
                              onChange={(e) => {
                                const v = e.target.value;
                                handleChange(e);
                                const stateData = allStateList.find(
                                  (s) => s.name === v
                                );
                                setFieldValue("city", "");
                                if (stateData) {
                                  fetchCities(stateData.isoCode);
                                }
                              }}
                            >
                              <option value="">{t.select_state}</option>
                              {indianState?.map((s, i) => (
                                <option key={`${s.value}_${i}`} value={s.value}>
                                  {s.label}
                                </option>
                              ))}
                            </Field>
                            {fielderror && fielderror.State && (
                              <div className="error_message select_message">
                                {fielderror.State}
                              </div>
                            )}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="tu-save-btn completeprofilebtn"
                          onClick={() => handleEditState()}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={values.state ? values.state : '--'}
                          className="tu-user-input"
                          disabled
                        />
                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditState}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>
                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.city}</span>
                    {editingCity ? (
                      <>
                        <div className="select-wrapper">
                          <div className="drop-select">
                            <Field
                              as="select"
                              name="city"
                              className="form-control tu-user-input edit"
                            >
                              <option value="">{t.select_city}</option>
                              {values.state &&
                                indianCity && filterCity(indianCity)?.map((s, i) => {
                                  let label = s.value
                                  if (s.value === 'Bangalore') {
                                    label = 'Bengaluru'
                                  }
                                  if (s.value === 'Gurgaon') {
                                    label = 'Gurugram'
                                  }
                                  let final_label = label && label.replace(/[,]/g, '')
                                  return (
                                    <option key={`${s.value}_${i}`} value={final_label}>
                                      {final_label}
                                    </option>
                                  )
                                })}
                            </Field>

                            {fielderror && fielderror.City && (
                              <div className="error_message select_message">
                                {fielderror.City}
                              </div>
                            )}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="tu-save-btn completeprofilebtn"
                          onClick={() => handleEditState()}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={values.city ? values.city : '--'}
                          className="tu-user-input"
                          disabled
                        />
                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditCity}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>

                <li>
                  <div className="tu-input-wrapper">
                    <span>{t.pin_code}</span>
                    {editingPinCode ? (
                      <>
                        <div>
                          <Field
                            type="text"
                            // placeholder="--"
                            name="pinCode"
                            className="tu-user-input edit"
                            //onKeyPress={handleKeyPressotp}
                            onInput={handleKeyPressotp}
                          />
                          {fielderror && fielderror.Pincode && (
                            <div className="error_message">
                              {fielderror.Pincode}
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="tu-save-btn completeprofilebtn"
                          onClick={handleEditPinCode}
                        >
                          <BsCheck />
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={initialValue.pinCode}
                          className="tu-user-input"
                          disabled
                        />
                        <button
                          type="button"
                          className="tu-edit-btn"
                          onClick={handleEditPinCode}
                        >
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </li>
                {userData && !userData.IsAlreadyProfileCompleteCoinWon && <li>
                  <p className="small text-white pt-3">
                    {t.complete_profile_save}
                  </p>
                </li>}
              </ul>
            </Form>
          )}
        </Formik>
        <div className="tu-coins-section">
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.my_wins}</p>
            </div>
            <a
              href={void 0}
              className="btn btn-xs btn-primary"
              onClick={() => {
                setShowMyWallet(true);
                setShowDropdown(false);
              }}
            >
              {t.check_now}
            </a>
          </div>
        </div>
        <div className="tu-coins-section">
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.my_victory_coins}</p>
              <p>{userData && userData.VictoryCoin}</p>
            </div>
            {/* //user is win all victory coin and not claimed all ticket  */}
            {ableToClaimSilverCoin && (
              <a
                href={void 0}
                className="btn btn-xs btn-primary"
                onClick={() => {
                  setTicketModel("ticket");
                  setShowClaimTicket(true);
                  setShowDropdown(false);
                }}
              >
                {t.claim_ticket}
              </a>
            )}
          </div>
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.my_match_coins}</p>
              <p>{userData && userData.MatchCoin}</p>
            </div>
            {ableToRedeemMerch && (
              <a
                href={void 0}
                className="btn btn-xs btn-primary"
                onClick={() => {
                  checkClaimedOrNot();
                  setShowDropdown(false);
                  //call save merch api 
                }}
              >
                {t.redeem_merch}
              </a>
            )}
          </div>
        </div>
        <div className="tu-referrals-section">
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.referrals_accepted}</p>
              <p>{userData && userData.AcceptReferred}</p>
            </div>
          </div>
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.referrals_pending}</p>
              <p>{userData && userData.PendingReferrals}</p>
            </div>
          </div>
        </div>
        {/* <div className="tu-coins-section">
          <div className="tu-content-row">
            <a
              href={void 0}
              className="mt-3 btn btn-sm btn-light "
              onClick={() => handleLogout()}
            >
              {t.logout}
            </a>
          </div>
        </div> */}
        {/* Rest of the code */}
      </NavDropdown>
      {
        <MyWallet
          t={t}
          show={showWallet}
          handleClose={handleClose}
          getWalletDetails={getWalletDetails}
          walletDetails={walletDetails}
        />
      }
      {showClaimTicket && (
        <ClaimTicket
          source={ticketModel}
          show={showClaimTicket}
          handleClose={() => {
            setArCtaAction({ show: false, cta_name: '' })
            setShowClaimTicket(false)
            setShowMyWallet(false)
          }}
          handleCross={() => {
            setShowClaimTicket(false)
          }}
          t={t}
          toofaniWinTicket={toofaniWinTicket}
          toofaniWinMerchaindise={toofaniWinMerchaindise}
          redeemMerchModel={redeemMerchModel}
          claimFail={claimFail}
          arCtaAction={arCtaAction}
        />
      )}
    </div>
  );
}

export default ProfileMenu;
