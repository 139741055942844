import React, { useState, useContext } from 'react';
import Slider from 'react-slick';
import { Card } from 'react-bootstrap';
import StumpCamModel from '../model/StumpCamModel';
import { MetaPixel, currentYear } from '../../utils/helper';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { stumCamCDP } from '../../utils/cdsHelper';

const Toofani = () => {

  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [showModel, setShowModel] = useState(false);
  const [camDetails, setDetails] = useState('');

  //crousal setting 
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerMode: true,
          centerPadding: '60px',
        },
      },
    ],
  };

  /**
   * @method renderStumpCam
   * @description render stump vedio section list 
   */
  const renderStumpCam = () => {
      let data = [
        {
          image: 'assets/images/EP-1.jpg',
          title: `${t.episodes.ep1}`,
          vedioUrl:'https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/testVideos/TUFP_Episode_01_R2_Burnt-in+Subs_20230911_HOTSTAR.mp4'
        },
  
        {
          image: 'assets/images/EP-2.jpg',
          title: `${t.episodes.ep2}`,
          vedioUrl:'https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/testVideos/TUFP_Episode_02_Burnt-in+Subtitles_20230915_HOTSTAR.mp4'
        },
  
        {
          image: 'assets/images/EP-3.jpg',
          title: `${t.episodes.ep3}`,
          //vedioUrl:'https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/testVideos/EP_03_YT_MASTER_23092023_HOTSTAR.mp4'
          vedioUrl:`https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/testVideos/EP03YtMasterHotstar.mp4`
        },
  
        {
          image: 'assets/images/EP-4.jpg',
          title: `${t.episodes.ep4}`,
          // vedioUrl:'https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/testVideos/EP_04_MASTER_YT_25092023_HOTSTAR.mp4'
          //vedioUrl:`https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/testVideos/EP04YtMasterHotstar.mp4`
          vedioUrl:`https://elasticbeanstalk-017194-ap-south-1-dev-static.s3.ap-south-1.amazonaws.com/Testing.mp4`
        }
      ];
    // }
    

    return data.map((el, i) => {
      return (
        <Card
          className="text-center"
          key={i}
          onClick={() => {
            setShowModel(true);
            setDetails(el);
            stumCamCDP(el.title);
            MetaPixel('StumpCamVideo');
          }}>
          <Card.Img variant="top" src={el.image} alt="video" />
          <Card.Body>
            <Card.Title className="mb-md-2 tu-playlist-wrapper-videoTitle">{el.title}</Card.Title>
          </Card.Body>
        </Card>
      );
    });
  };

  return (
    <section className="section bg-pattern bg-pattern-5" id="hotstar_playlist">
      <header className="section-header">
        <h2 className="section-title section-title-toofan"> 
        {/* {t.stump_cam} {currentYear} {t.playlist}  */}
        {t.toofan_begins}
        </h2>
        <p className="section-desc section-desc-toofan">
          {/* {t.watch_the_match} */}
          {t.will_india_win_sub}<br/>{t.tell_us_fanpulse}
        </p>
      </header>
      <div className="container-fluid pr-0 px-md-3 pb-md-8 tu-playlist-wrapper">
        <Slider className="tu-slider w-100 mx-auto" {...settings}>
          {renderStumpCam()}
        </Slider>
      </div>
      {showModel && (
        <StumpCamModel
          show={showModel}
          onClose={() => setShowModel(false)}
          camDetails={camDetails}
        />
      )}
    </section>
  );
};

export default Toofani;
