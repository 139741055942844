import indianCityState from '../json/IndianCityState.json'

export const RICTRICTED_STATE = 'Tamil Nadu'
export const ELIGABLE_COUNTRY = 'India'
export const SECRET = process.env.REACT_APP_SECRET;
export const CAPCHA_SECRET = process.env.REACT_APP_RECAPCHA_SECRET_KEY
export const indian_city_state = indianCityState

export const avatarImages = [
  "assets/images/avatar-1.png",
  "assets/images/avatar-2.png",
  "assets/images/avatar-3.png",
  "assets/images/avatar-4.png",
  "assets/images/avatar-5.png",
  "assets/images/avatar-6.png",
  "assets/images/avatar-7.png",
  "assets/images/avatar-8.png"
];

export const dummy_data =  {
    "Id": "AEABV4d2XdUrLrD0Qo9/Dw==",
    "Name": "Test1",
    "Email": "test1@gmail.com",
    "MobileNo": "9911083603",
    "Age": 20,
    "Gender": "Male",
    "City": "Ind",
    "State": "MP",
    "Pincode": 123456,
    "VictoryCoin": 0,
    "MatchCoin": 0,
    "ProfileImg": "test1.png",
    "Points": 5,
    "Referred": 0,
    "AcceptReferred": 0,
    "RefCode": "CS4243",
    "Rank": 1
  }

export const venueList = [
  {label: 'Chennai', value: 'Chennai'},
  {label: 'Delhi', value: 'Delhi'},
  {label: 'Mumbai', value: 'Mumbai'},
  {label: 'Kolkata', value: 'Kolkata'},
  {label: 'Lucknow', value: 'Lucknow'},
  {label: 'Dharamsala', value: 'Dharamsala'},
  {label: 'Pune', value: 'Pune'},
  {label: 'Hyderabad', value: 'Hyderabad'},
  {label: 'Ahmedabad', value: 'Ahmedabad'},
  {label: 'Bengaluru', value: 'Bengaluru'}
]

export const languageList = [
  {label: 'हिन्दी', value: 'hi'},
  {label: 'English', value: 'en'},
  {label: 'ଓଡ଼ିଆ', value: 'or'},
  {label: 'తెలుగు', value: 'te'},
  {label: 'বাংলা', value: 'bn'},
  {label: 'ગુજરાતી', value: 'gu'},
  {label: 'ಕನ್ನಡ', value: 'kn'},
  {label: 'मराठी', value: 'mr'},
]


export const validateName = (value) => {
  let error;
  if (!value) {
    error = 'Name is required';
  } else if (value.length < 3) {
    error = 'Name must be at least 3 characters long';
  } else if (value.length > 10) {
    error = 'Name cannot exceed 10 characters';
    value = value.substring(0, 10) + '...';
  }
  return { error, value };
};



export const forbiddenKeywords = ['on-', '+']; // List of forbidden keywords


export const forbiddenCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;

export const privacy_policy = 'https://elasticbeanstalk-017194-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/TCCC_TU_Privacy_Policy_India+updated+27-08-2023+(clean).pdf'
export const terms_condition = 'https://elasticbeanstalk-017194-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/TUxICC23_TnCs_Final-2.pdf'
export const faq = 'https://elasticbeanstalk-017194-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/TUxICC23_+Promo+FAQs-2.pdf'

// let hostname = window.location.host
// let url_name = hostname + '/tnc.pdf'

// export const terms_condition = url_name
// Assuming the 't' variable is defined and accessible in the current scope


export const ARPlatformUrl = 'https://xtendr.8thwall.app/tu-icc23/';


