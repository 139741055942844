import React from 'react';

export default function Home() {
  return (
    <>
      <div className="container-fluid">
        <h1>Heading h1 64x64</h1>
        <h2>Heading h2</h2>
        <h3>Heading h3</h3>
        <h4>Heading h4</h4>
        <h5>Heading h5</h5>
        <h6>Heading h6</h6>

        <br />
        <br />
        <h1 className="display-1">Display-1</h1>
        <h2 className="display-2">Display-2</h2>
        <h3 className="display-3">Display-3</h3>
        <br />
        <br />

        <p>Paragraph Paragraph Paragraph Paragraph Paragraph Paragraph</p>
        <p className="small">
          Paragraph Small Paragraph Small Paragraph Small Paragraph Small
        </p>

        <br />
        <br />

        <div className="row">
          <div className="col">
            <a href="#tu" className="btn btn-primary">
              Primary Button
            </a>
            <a href="#tu" className="btn btn-secondary">
              Secondary Button
            </a>
            <a href="#tu" className="btn btn-tertiary">
              Tertiary Button
            </a>
            <a href="#tu" className="btn btn-info">
              Info Button
            </a>
            <a href="#tu" className="btn btn-danger">
              Danger Button
            </a>
            <a href="#tu" className="btn btn-warning">
              Warning Button
            </a>
            <a href="#tu" className="btn btn-light">
              Light Button
            </a>
            <a href="#tu" className="btn btn-dark">
              Dark Button
            </a>
            <a href="#tu" className="btn btn-muted disabled">
              Muted Button
            </a>
          </div>
        </div>

        <br />
        <br />

        <div className="row">
          <div className="col">
            <a href="#tu" className="btn btn-sm btn-primary">
              Primary Button
            </a>
            <a href="#tu" className="btn btn-sm btn-secondary">
              Secondary Button
            </a>
            <a href="#tu" className="btn btn-sm btn-tertiary">
              Tertiary Button
            </a>
            <a href="#tu" className="btn btn-sm btn-info">
              Info Button
            </a>
            <a href="#tu" className="btn btn-sm btn-danger">
              Danger Button
            </a>
            <a href="#tu" className="btn btn-sm btn-warning">
              Warning Button
            </a>
            <a href="#tu" className="btn btn-sm btn-light">
              Light Button
            </a>
            <a href="#tu" className="btn btn-sm btn-dark">
              Dark Button
            </a>
            <a href="#tu" className="btn btn-sm btn-muted disabled">
              Muted Button
            </a>
          </div>
        </div>

        <br />
        <br />

        <div className="row">
          <div className="col">
            <a href="#tu" className="btn btn-xs btn-primary">
              Primary Button
            </a>
            <a href="#tu" className="btn btn-xs btn-secondary">
              Secondary Button
            </a>
            <a href="#tu" className="btn btn-xs btn-tertiary">
              Tertiary Button
            </a>
            <a href="#tu" className="btn btn-xs btn-info">
              Info Button
            </a>
            <a href="#tu" className="btn btn-xs btn-danger">
              Danger Button
            </a>
            <a href="#tu" className="btn btn-xs btn-warning">
              Warning Button
            </a>
            <a href="#tu" className="btn btn-xs btn-light">
              Light Button
            </a>
            <a href="#tu" className="btn btn-xs btn-dark">
              Dark Button
            </a>
            <a href="#tu" className="btn btn-xs btn-muted disabled">
              Muted Button
            </a>
          </div>
        </div>

        <br />
        <br />
        <h3>Forms</h3>
        <form>
          <div className="form-group">
            <label for="inputEmail3" className="col-sm-2 col-form-label">
              Email
            </label>
            <div className="col-sm-10">
              <input type="email" className="form-control" id="inputEmail3" />
            </div>
          </div>
          <div className="form-group">
            <label for="inputPassword3" className="col-sm-2 col-form-label">
              Password
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                className="form-control"
                id="inputPassword3"
              />
            </div>
          </div>
          <fieldset className="form-group">
            <legend className="col-form-label col-sm-2 float-sm-left pt-0">
              Radios
            </legend>
            <div className="col-sm-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios1"
                  value="option1"
                  checked
                />
                <label className="form-check-label" for="gridRadios1">
                  First radio
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios2"
                  value="option2"
                />
                <label className="form-check-label" for="gridRadios2">
                  Second radio
                </label>
              </div>
              <div className="form-check disabled">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios3"
                  value="option3"
                  disabled
                />
                <label className="form-check-label" for="gridRadios3">
                  Third disabled radio
                </label>
              </div>
            </div>
          </fieldset>
          <div className="form-group">
            <div className="col-sm-10 offset-sm-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck1"
                />
                <label className="form-check-label" for="gridCheck1">
                  Example checkbox
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-10">
              <button type="submit" className="btn btn-primary">
                Sign in
              </button>
            </div>
          </div>
        </form>
        <br />
        <br />
      </div>
    </>
  );
}
