import AxiosHelper from "../utils/axiosHelper";
import { API } from '../config/apiUrl';
import { apiErrors } from '../config/apiErrorHandler'


/**
 * This method is responsible for sending the OTP by making an API call.
 * It receives the user data as a parameter and returns the API response.
 * If an error occurs, it displays a toast error message using the `apiErrors` function.
 */
export const sendOtp = async (userData) => {
  // try {
  return await AxiosHelper.post(API.getData, userData);
  // return response;
  // } catch (error) {
  //   apiErrors(error)
  // }
};


/**
* @method verifyOtp
* @description verify otp 
*/
export const verifyOtp = async (userData) => {
  return await AxiosHelper.post(API.postMobileVerifyOtp, userData);
};

/**
* @method RegisterUser
* @description register user 
*/
export const RegisterUser = async (userData) => {
  return await AxiosHelper.post(API.registerData, userData);
};

export const updateProfileName = async (userData) => {

  return await AxiosHelper.post(API.updateProfileName, userData);

};

export const updateProfile = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.updateProfile, userData);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};
export const updateProfileEmail = async (userData) => {

  return await AxiosHelper.post(API.updateProfileEmail, userData);


};

export const updateProfileAge = async (userData) => {

  return await AxiosHelper.post(API.updateProfileAge, userData);

};
export const updateProfileGender = async (userData) => {
  return await AxiosHelper.post(API.updateProfileGender, userData);
};

export const updateProfileCity = async (userData) => {

  return await AxiosHelper.post(API.updateProfileCity, userData);

};

export const updateProfileState = async (userData) => {

  return await AxiosHelper.post(API.updateProfileState, userData);

};

export const updateProfileStateAndCity = async (userData) => {

  return await AxiosHelper.post(API.updateProfileStateCity, userData);

};

export const updateProfilePinCode = async (userData) => {
  return await AxiosHelper.post(API.updateProfilePinCode, userData);
};

/**
* @method validateAndRedeemCode
* @description validate code
*/
export const validateAndRedeemCode = async (data) => {
  try {
    const response = await AxiosHelper.post(API.validateUniqueCode, data);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};


/**
* @method getLineGraph
* @description get line graph 
*/
export const validateAndRedeemCodevalue = async (data) => {
  return await await AxiosHelper.post(API.validateUniqueCode, data);
};


export const updateProfileImage = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.updateProfileImage, userData);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};

export const voteNowForFlag = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.voteNowForFlag, userData);
    return response;
  } catch (error) {
    // apiErrors(error)
    //throw new Error('User creation failed.');
  }
};
export const TossNowFlag = async (userData) => {

  const response = await AxiosHelper.post(API.tossNowForFlag, userData);
  return response;

};

export const getUserDetailsById = async (Id) => {
  try {
    const response = await AxiosHelper.post(API.getUserById, Id);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};

export const getWalletDetailsById = async (Id) => {
  try {
    const response = await AxiosHelper.post(API.getWalletById, Id);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};

export const getDetailsByIp = async (Id) => {
  try {
    const response = await AxiosHelper.get(API.getWalletById, Id);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};


export const getDetailsByMap = async (Id) => {
  try {
    const response = await AxiosHelper.get(API.getWalletById);
    return response;
  } catch (error) {
    apiErrors(error)
    //throw new Error('User creation failed.');
  }
};
/**
* @method sendReferAFriendCode
* @description send refer a friend code 
*/
export const sendReferAFriendCode = async (userData) => {
  return await AxiosHelper.post(API.sendReferCode, userData);
};

/**
* @method sendClaimMerch
* @description send claim Merch 
*/
export const sendClaimMerch = async (userData) => {
  return await AxiosHelper.post(API.sendClaimMerch, userData);
};

/**
* @method sendClaimTicket
* @description send claim Ticket 
*/
export const sendClaimTicket = async (userData) => {
  return await AxiosHelper.post(API.sendClaimTicket, userData);
};

/**
* @method verifyReferCode
* @description verify refer code
*/
export const verifyReferCode = async (userData) => {
  return await AxiosHelper.post(API.verifyreferCode, userData);
};

/**
* @method savematchCoinForProfileComplete
* @description save match coin for profile complete
*/
export const savematchCoinForProfileComplete = async (Id) => {
  return await AxiosHelper.post(API.saveMatchCoinProfileComplete, Id);
};

/**
* @method getVotesForWorldCup
* @description get votes for world cup //need to relace with get
*/
export const getVotesForWorldCup = async (data) => {
  return await AxiosHelper.post(API.worldcupVotes, data);
};

/**
* @method getLineGraph
* @description get line graph // need to replace with get
*/
export const getLineGraph = async () => {
  return await AxiosHelper.post(API.linegraphDetails, null);
};

/**
* @method getLineGraph
* @description get line graph 
*/
export const checkmatchClaimedToday = async (data) => {
  return await AxiosHelper.post(API.checkMatchClaim, data);
};

/**
* @method getTicketWinnerCount
* @description get count of ticket winner  // need to replace with get
*/
export const getTicketWinnerCount = async () => {
  return await AxiosHelper.get(API.ticketWinnerCount, null);
};


/**
* @method saveTicketWinner
* @description save ticket ones all ticket win    // need to replace with get
*/
export const saveTicketWinner = async (data) => {
  return await AxiosHelper.post(API.saveTicketWin, data);
};

/**
* @method getMerchList
* @description get merch list
*/
export const getMerchList = async (data) => {
  return await AxiosHelper.post(API.merchList, data);
};

/**
* @method getWinTicketList
* @description get win ticket list
*/
export const getWinTicketList = async (data) => {
  return await AxiosHelper.post(API.winTicketList, data);
};

/**
* @method getSilverCoinList
* @description get all silver coin list
*/
export const getSilverCoinList = async (data) => {
  return await AxiosHelper.post(API.silverCoinList, data);
};

/**
* @method saveMerchWinner
* @description save ticket ones all ticket win    // need to replace with get
*/
export const saveMerchWinner = async (data) => {
  return await AxiosHelper.post(API.saveMerchWinner, data);
};

/**
* @method saveToofaniPulse
* @description save toofani pulse
*/
export const saveToofaniPulse = async (data) => {
  return await AxiosHelper.post(API.saveToofaniPulse, data);
};

/**
* @method logoutUser
* @description logout user
*/
export const logoutUser = async (data) => {
  return await AxiosHelper.post(API.logout, data);
};


/**
* @method uploadImageAPI
* @description upload image 
*/
export const uploadImageAPI = async (data) => {
  return await AxiosHelper.post(API.imageUpload, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};















