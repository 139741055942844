import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { validateAndRedeemCodevalue } from "../../actions/Auth";
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  TwitterPixel,
  getCDSPixelId, getUserDetails
} from '../../utils/helper'
import { LoaderContext } from "../loader/LoaderContext";
import VictoryCoinModelJourney from "../common/VictoryCoinModelJourney";
import { handleInputLimitedLength } from '../../utils/helper'
import { useNavigate, useLocation } from "react-router-dom";
import CommonContext from '../contextApi';
import ReactGA from 'react-ga';

const UniqueCode = ({ setShowLoginModal }) => {

  const { triggerActionAfterLogin, actionType } = useContext(CommonContext);
  const { language } = useContext(LanguageContext);
  const { toggleLoader } = useContext(LoaderContext);
  let user_details = getUserDetails()
  const t = translations[language];
  const [uniqueCodeData, setUniqueCodeData] = useState('')
  const initialValues = { Code: "" };
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;

  const formik = useFormik({

  });

  useEffect(() => {
    if (actionType && actionType.isLoggedIn && actionType.Code) {
      if (actionType.type === 'unique_code_scan') {
        handleSubmit(actionType, formik)
      }
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUniqueCodeData(uniqueCodeData)

  }, [uniqueCodeData])



  //handle unique code submit
  const handleSubmit = async (values, { resetForm }) => {
    console.log('Code', values, user_details);
    const hfSessionKey = await getCDSPixelId();
    if (values.Code && user_details) {
      let reqData = {
        Code: values.Code,
        UserId: user_details && user_details.Id,
        HfSession: hfSessionKey
      }
      toggleLoader();
      try {
        const response = await validateAndRedeemCodevalue(reqData);
        if (response.status === 200) {
          let data = response.data && response.data.Data
          console.log('data', data)
          resetForm()
          setUniqueCodeData({
            coin_type: data,
            error: false
          })
          // ReactGA.event({
          //   category: 'Form',
          //   action: 'submit_success',
          //   label: 'tu_uniquecode'
          // });
          console.log('ga events')
          // window.gtag('event', 'Button Click', {
          //   event_category: 'tu_uniquecode',
          //   event_action: 'tu_uniquecode',
          //   event_label: 'tu_uniquecode'
          // });
          window.dataLayer.push({ event: 'tu_uniquecode' })
          // window.google_tag_manager[process.env.REACT_APP_G_GTM_ID].dataLayer.push({
          //   event: 'tu_uniquecode'
          // });
          // navigate(`${currentPathname}?uniquecode=true`, { replace: true });
        }
      } catch (error) {
        navigate(`${currentPathname}`, { replace: true })
        console.log('error', error)
        let code = error.response && error.response.data && error.response.data.ErrorCode
        resetForm()
        setUniqueCodeData({
          coin_type: '',
          error: true,
          errorCode: code
        })
      }
      setTimeout(() => {
        toggleLoader();
      }, 500); // Add a small delay before hiding the loader (500ms in this example)
    } else {
      navigate(`${currentPathname}`, { replace: true })
      console.log('case2')
      //resetForm()
      setShowLoginModal()
      triggerActionAfterLogin({ type: 'unique_code_scan', isLoggedIn: false, Code: values.Code })
    }
  };

  // render unique code section
  const renderUniqueCodeSection = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        //validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, values }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Field name="inputField">
              {({ field }) => (
                <div className="tu-unique-code-wrapper">
                  <input
                    placeholder={`${t.unique_code_key}`}
                    {...field}
                    type="text"
                    name={'Code'}
                    value={values.Code}
                    onInput={(e) => {
                      //navigate('/?uniquecode=true', { replace: true });
                      handleInputLimitedLength(e, 10)
                    }}
                  />
                  <button className={`btn btn-xs btn-primary uniquecode_submit_btn`} type="submit" id={'uniquecode_submit_btn'}
                    onClick={() => {
                      CounterFloodLight('DC-12665261/thums0/thums01q+unique');
                      MetaPixel('UniqueSubmit');
                      MetaPixel('EnterUniqueCode');
                      TTDPixel('deqqgft');
                      TwitterPixel('tw-ofua3-ofung');
                    }}
                  >
                    {`${t.submit}`}
                  </button>
                </div>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    )
  }

  // if unique code data found than render unique code model journey
  if (uniqueCodeData) {
    return (
      <div>
        <VictoryCoinModelJourney
          data={uniqueCodeData} t={t}
          handleSuccesModelClose={() => {
            setUniqueCodeData('')
          }}
          setShowMywalletEvent={() => {}}
        />
        {renderUniqueCodeSection()}
      </div>
    )
  }

  return (
    <div>
      {renderUniqueCodeSection()}
    </div>
  );
};

export default UniqueCode;