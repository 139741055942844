/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import LanguageContext from '../../utils/LanguageContext';
import { privacy_policy, terms_condition,faq } from '../../config/constant';
import ContactUs from '../model/Contactus';
import LanguageSwitcher from '../common/LanguageSwicher';
import { useNavigate } from "react-router-dom";
import { 
  CounterFloodLight, 
  MetaPixel, 
  TTDPixel, 
  TwitterPixel, 
  getUserDetails } from '../../utils/helper';
import { logoutUser } from '../../actions/Auth'

function PrivacyPolicy({ t, navigateTo }) {

  const navigate = useNavigate();
  let userDetails = getUserDetails();
  const [openContactUs, openContactUsModel] = useState(false)

  const handleLogout = async () => {
    let requestData = {
      Id: userDetails && userDetails.Id
    }
    try {
      await logoutUser(requestData);
      localStorage.clear();
      window.location.assign('/');
    } catch (error) { }
  };

  return (
    <div className='tu-sidebar-privacy-main tu-privacy'>
      {/* <h6>{t.change_language}</h6>
      <LanguageSwitcher /> */}
      <div className='tu-sidebar-list-main'>
        <ul className='pl-0 list-bottom-border'>
          <li>
            <Link 
              to={faq} 
              target={'_blank'} 
              onClick={() => {
                TTDPixel('ttte89g');
                navigateTo();
                CounterFloodLight('DC-12665261/thums0/thums02b+unique');
                MetaPixel('FAQ');
                TwitterPixel('tw-ofua3-ofvbj');
              }}
            >
              {t.faqs}
            </Link>
          </li>
          <li>
            <Link 
              to={privacy_policy} 
              target={'_blank'} 
              onClick={() => {
                TTDPixel('4oojsad');
                navigateTo();
                CounterFloodLight('DC-12665261/thums0/thums02c+unique');
                MetaPixel('PrivacyPolicy');
                TwitterPixel('tw-ofua3-ofvca');
              }}>
                {t.privacy_policy}
              </Link>
          </li>
          <li>
            <Link to={terms_condition} target={'_blank'} onClick={() => {
              TTDPixel('emeu9lr');
              navigateTo(); 
              CounterFloodLight('DC-12665261/thums0/thums02d+unique');
              MetaPixel('T&C');
              TwitterPixel('tw-ofua3-ofvcm');
              }}>{t.terms_condition}</Link>
          </li>
          <li>
            <a href={void (0)} onClick={() => {
              TTDPixel('c18lr3l');
              navigateTo();
              openContactUsModel(true);
              CounterFloodLight('DC-12665261/thums0/thums02a+unique');
              MetaPixel('ContactUs');
              TwitterPixel('tw-ofua3-ofvba');
            }}>{t.contact_us}</a>
          </li>
        </ul>
      </div>
      {userDetails && (
        <a 
          href={void (0)} 
          className="btn btn-light logout-btn logoutbtn" 
          onClick={() => {
            handleLogout();
            CounterFloodLight('DC-12665261/thums0/thums02e+unique');
            MetaPixel('Logout');
            TwitterPixel('tw-ofua3-ofvc7');
          }}
        >
          {t.logout}
        </a>
      )}
      {openContactUs &&
        <ContactUs
          show={openContactUs}
          onClose={() => openContactUsModel(false)}
          t={t}
        />
      }
    </div>
  );
}

export default PrivacyPolicy;
